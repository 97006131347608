import { Pagination } from '../_types'
import { OrderDetail } from '../orders'
import { RouteItem } from '../routes'
import { TicketDetail } from '../tickets'
import { TripItem } from '../trips'
import { UserItem } from '../users'

export type DateEngString = string // 'YYYY-MM-DD'

export enum ActivityLogNameType {
  DEFAULT = 'default',
  HTTP_REQUEST = 'http_request',
}

export enum ActivityLogSubjectType {
  USER = 'user',
  TICKET = 'ticket',
  ORDER = 'order',
  UNIQ_ROUTE = 'uniq_route',
  TRIP = 'trip',
  PAY_DISCOUNT = 'pay_discount',
  CARRIER = 'carrier',
}

export enum ActivityLogCauserType {
  USER = 'user',
  CUSTOMER = 'customer',
}

// @Partial
export interface ReqGetActivityLogs extends Pagination {
  causer_id: UserItem['id']
  causer_type: ActivityLogCauserType
  date_from: DateEngString
  date_to: DateEngString
  event: ActivityLogsEvents['event']
  log_name: ActivityLogNameType
  property: 'email'
  subject_id:
    | UserItem['id']
    | TicketDetail['id']
    | OrderDetail['id']
    | RouteItem['id']
    | TripItem['id']
  subject_type: ActivityLogSubjectType
  without_causer: boolean
}

export interface ActivityLogItem {
  causer: {
    email: UserItem['email']
    firstname: UserItem['first_name']
    lastname: UserItem['last_name']
  } | null
  causer_id: UserItem['id'] | null // хто зробив дію
  causer_type: ActivityLogCauserType | null // тип сутності (тут може бути user або customer, отже вони роблять дію, може не бути, якщо дія системна або немає авторизації)
  // - те саме що і для properties, але тільки для дій
  changes: {
    attributes: unknown
    old: unknown
  }
  created_at: Date
  description: string
  event: ActivityLogsEvents['event']
  event_translated: ActivityLogsEvents['event_translated']
  id: 0
  log_name: ActivityLogNameType // тип логу
  log_name_translation: string // переклад для відображення
  properties: unknown // - якщо це дія, то тут відображаються атрибути які було змінено або створено, та старі дані якщо оновлено. списку завжди різний, тому можна відображати як json
  subject_id: number
  subject_type: ActivityLogSubjectType
  subject_type_translation: string
}

export interface ActivityLogsEvents {
  event: string
  event_translated: string
}

export interface ActivityLogsSubjects {
  subject: ActivityLogSubjectType
  subject_translated: string
}

export interface ActivityLogsCausers {
  id: number
  causer_type: ActivityLogCauserType
  causer_id: UserItem['id']
  causer_translated: string
  causer: {
    email: UserItem['email']
    firstname: UserItem['first_name']
    lastname: UserItem['last_name']
  }
}
