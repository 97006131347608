import { toast } from 'react-hot-toast'
import { SagaIterator } from '@redux-saga/core'
import { call, put } from 'redux-saga/effects'

import { apiTicketsChange, TicketReqChangeData } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { ToggleSubListAsync } from '../../types'

/**
 * Перенесення квитка з саб ліста або в головну відомість
 *
 * @param payload
 */
export function* toggleSubListWorker({ payload }: ToggleSubListAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    const requestData: TicketReqChangeData = {
      in_sublist: payload.request.isMoveSubList ? 1 : 0,
    }

    if (payload.request.place) {
      requestData.place = payload.request.place.place
    }
    // Api call or some async actions
    const { data } = yield call(apiTicketsChange, {
      ticketId: payload.request.place.ticket_id,
      data: requestData,
    })

    if (data) {
      if (payload.isSheet) {
        yield put(
          sheetsActions.toggleSubList({
            place: payload.request.place,
            isMoveSubList: payload.request.isMoveSubList,
            isConflicts: !!payload.isConflicts,
          })
        )
      }

      toast.success(
        payload.request.isMoveSubList
          ? 'Квиток перенесено в підвідомість 📥'
          : 'Квиток перенесено в основну відомість 📤'
      )
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
