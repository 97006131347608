import { useTranslation } from 'react-i18next'
import {
  DiscountOutlined as DiscountIcon,
  KeyboardReturnOutlined as ReturnIcon,
  LocalAtmOutlined as SystemMaintainedIcon,
  MonetizationOnOutlined as TicketPriceIcon,
  PriceChangeOutlined as PriceExtraIcon,
  SavingsOutlined as BonusIcon,
} from '@mui/icons-material'
import { Table, TableBody } from '@mui/material'

import { TicketReturnCondition } from '@klr/api-connectors'
import { formatCurrency, TableDetailRow } from '@klr/shared'

type Props = {
  cashbackPayment: number
  price: number
  price_extra: number
  currencyISO: string
  discount: number
  returnCondition: TicketReturnCondition
}

export const ReturnCondition = ({
  cashbackPayment,
  price,
  price_extra,
  currencyISO,
  discount,
  returnCondition,
}: Props) => {
  const { t } = useTranslation()
  const fields = [
    {
      icon: TicketPriceIcon,
      label: t('Booking.checkout.ticket_price'),
      text: formatCurrency(price, currencyISO),
    },
    {
      icon: BonusIcon,
      label: t('Orders.cashback_payment'),
      text: formatCurrency(cashbackPayment, currencyISO),
    },
    {
      icon: ReturnIcon,
      label: t('Orders.client_return'),
      text: formatCurrency(returnCondition.return_to_client, currencyISO),
    },
    {
      icon: SystemMaintainedIcon,
      label: t('Orders.system_maintained'),
      text: formatCurrency(returnCondition.system_cash, currencyISO),
    },
  ]

  if (discount) {
    fields.push({
      icon: DiscountIcon,
      label: t('Orders.discount_applied'),
      text: `${discount} %`,
    })
  }

  if (price_extra) {
    fields.push({
      icon: PriceExtraIcon,
      label: t('Orders.price_extra'),
      text: formatCurrency(price_extra, currencyISO),
    })
  }

  return (
    <Table size="small">
      <TableBody>
        {fields.map((item, idx) => (
          <TableDetailRow key={idx} title={item.label} description={item.text} icon={item.icon} />
        ))}
      </TableBody>
    </Table>
  )
}
