import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType, ListType } from '../_types'

import {
  StationItem,
  StationReqChange,
  StationReqCreate,
  StationReqFetchList,
} from './station.models'

export const apiStationFetchList = (
  params: StationReqFetchList
): AxiosPromise<ListType<StationItem>> => {
  return axios.get(`/stations`, { params })
}

export const apiStationFetchDetail = (
  stationId: StationItem['id']
): AxiosPromise<DetailType<StationItem>> => {
  return axios.get(`/stations/${stationId}`)
}

export const apiStationCreate = (data: StationReqCreate): AxiosPromise<DetailType<StationItem>> => {
  return axios.post(`/stations`, data)
}

export const apiStationChange = ({
  id,
  data,
}: StationReqChange): AxiosPromise<DetailType<StationItem>> => {
  return axios.put(`/stations/${id}`, data)
}

export const apiStationDelete = (id: StationItem['id']): AxiosPromise => {
  return axios.delete(`/stations/${id}`)
}
