import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { apiSettingsFetchOrderStatuses, OrderStatus } from '@klr/api-connectors'

interface FilterStatusesProps {
  filterPaidFormIds?: number[]
  removeCustomPaidForms?: boolean
}

export const useGetOrderStatuses = ({
  filterPaidFormIds = [],
  removeCustomPaidForms = false,
}: FilterStatusesProps) => {
  const getFilteredData = useCallback(
    (initialData: OrderStatus[]) => {
      if (removeCustomPaidForms) {
        return initialData.slice(4)
      }

      if (filterPaidFormIds.length) {
        return initialData.filter((item) => !filterPaidFormIds.includes(Number(item.id)))
      }

      return initialData
    },
    [filterPaidFormIds, removeCustomPaidForms]
  )

  return useQuery(
    ['orderStatuses'],
    async () => {
      const { data } = await apiSettingsFetchOrderStatuses()

      return data.items
    },
    {
      select: getFilteredData,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )
}
