import { createContext, useEffect, useState } from 'react'
import { merge } from 'lodash'

import type { Settings, SettingsContextValue, SheetSettingsProviderProps } from './types'

const defaultSettings: Settings = {
  isReturnedTickets: false,
  isShowCarousel: true,
  isColorful: false,
  isAutoUpdate: true,
}

const KEY = 'sheets:settings'

export const restoreSettings = (): Settings | null => {
  let settings = null

  try {
    const storedData: string | null = window.localStorage.getItem(KEY)

    if (storedData) {
      settings = JSON.parse(storedData) as Settings | null
    }
  } catch (err) {
    // TODO: вивести помилку
    // If stored data is not a stringify JSON this will fail,
    // that's why we catch the error
  }

  return settings
}

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('sheets:settings', JSON.stringify(settings))
}

const SheetSettingsContext = createContext({} as SettingsContextValue)

export const SheetSettingsProvider = ({ children }: SheetSettingsProviderProps) => {
  const [currentSettings, setCurrentSettings] = useState<Settings>(defaultSettings)

  const handleSaveSettings = (update: Settings) => {
    const mergedSettings = merge({}, currentSettings, update)

    setCurrentSettings(mergedSettings)
    storeSettings(mergedSettings)
  }

  useEffect(() => {
    const restoredSettings = restoreSettings()

    if (restoredSettings) {
      setCurrentSettings(restoredSettings)
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === KEY) {
        const newSettings = JSON.parse(e.newValue || '{}')
        setCurrentSettings(newSettings as Settings)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  return (
    <SheetSettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SheetSettingsContext.Provider>
  )
}

export default SheetSettingsContext
