import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { ListType } from '../_types'

import {
  ActivityLogItem,
  ActivityLogsCausers,
  ActivityLogsEvents,
  ActivityLogsSubjects,
  ReqGetActivityLogs,
} from './activity-logs.models'

export const getActivityLogs = (
  params: Partial<ReqGetActivityLogs>
): AxiosPromise<ListType<ActivityLogItem>> =>
  axios.get('/activity-logs', {
    params,
  })

export const getActivityLogsEvents = (): AxiosPromise<ListType<ActivityLogsEvents>> =>
  axios.get('/activity-logs/events')

export const getActivityLogsSubjects = (): AxiosPromise<ListType<ActivityLogsSubjects>> =>
  axios.get('/activity-logs/subjects')

export const getActivityLogsCausers = (): AxiosPromise<ListType<ActivityLogsCausers>> =>
  axios.get('/activity-logs/causers')
