export type SearchCarrier = {
  id: number
  name: string
}

export const defaultCarrier: SearchCarrier = {
  id: 1,
  name: 'KLR Bus',
}

export const defaultCurrencyId = 1
