import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { NumberParam, useQueryParam } from 'use-query-params'

import { SheetPlace } from '@klr/api-connectors'

import { DetailModalEnum } from '../../../constants/detail-view.enum'
import { uiActions } from '../../ui'

export const useShowDetailTicket = () => {
  const dispatch = useDispatch()
  const [, setQueryOrder] = useQueryParam('ticket', NumberParam)

  const handleShowTicketDetail = useCallback(
    (place: SheetPlace, isSubList = false, isConflicts = false): void => {
      setQueryOrder(place.ticket_id)

      dispatch(
        uiActions.showDetailModal({
          meta: DetailModalEnum.DETAIL_SHEET_TICKET,
          payload: {
            isConflict: isConflicts,
            isSubList,
            ...place,
          },
        })
      )
    },
    [dispatch, setQueryOrder]
  )

  return { handleShowTicketDetail }
}
