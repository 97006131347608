import { getQueryPagination } from '../_byQuery'
import { ConnectedQueryFunctionContext } from '../_shared'
import { ListType, ReqFetchList } from '../_types'

import { apiRoutesFetchList, apiRoutesGroupsFetchList } from './api'
import {
  RouteItem,
  RouteReqFetchListCalendars,
  RouteReqFetchListFilters,
  RouteReqFetchListRequest,
  RouteReqFetchRouteStations,
  RouteReqFetchRouteTariffs,
  RoutesGroupDTO,
} from './models'

export const routeQueryKeys = {
  all: ['routes'] as const,

  list: (params: RouteReqFetchListFilters = {}) => [...routeQueryKeys.all, params] as const,
  detail: (id: RouteItem['id']) => [...routeQueryKeys.all, id] as const,
  blockedSales: (id: RouteItem['id']) => [...routeQueryKeys.detail(id), 'blocked-sales'] as const,
  routeCalendars: (params: RouteReqFetchListCalendars) =>
    [...routeQueryKeys.detail(params.routeId), 'route-calendars', params] as const,
  tariffs: (params: RouteReqFetchRouteTariffs | null) => {
    return [
      ...[params ? routeQueryKeys.detail(params.route_id) : routeQueryKeys.all],
      'route-tariffs',
      params,
    ] as const
  },
  groups: ['routes-groups'] as const,
  groupDetail: (id: RoutesGroupDTO['id']) => [...routeQueryKeys.groups, id] as const,
  groupsList: (params: Omit<ReqFetchList, 'page' | 'limit'> = {}) =>
    [...routeQueryKeys.groups, params] as const,
  loyaltyLevels: (id: RouteItem['id']) => [...routeQueryKeys.all, id, 'loyalty_levels'] as const,
  promotions: (id: RouteItem['id']) => [...routeQueryKeys.detail(id), 'promotions'] as const,
  places: (id: RouteItem['id']) => [...routeQueryKeys.detail(id), 'places'] as const,
  stations: ({ route_id, period_id }: RouteReqFetchRouteStations) =>
    [...routeQueryKeys.detail(route_id), 'stations', period_id] as const,
}

export type RouteQueryKeys = {
  all: readonly ['routes']
  list: readonly [...RouteQueryKeys['all'], Partial<RouteReqFetchListFilters>]
  detail: readonly [...RouteQueryKeys['all'], RouteItem['id']]
  blockedSales: readonly [...RouteQueryKeys['all'], RouteItem['id'], 'blocked-sales']
  routeCalendars: readonly [
    ...RouteQueryKeys['all'],
    RouteItem['id'],
    'route-calendars',
    RouteReqFetchListCalendars,
  ]
  tariffs: readonly [...RouteQueryKeys['all'], 'route-tariffs', RouteReqFetchRouteTariffs | null]
  groups: readonly ['routes-groups']
  groupDetail: readonly [...RouteQueryKeys['groups'], RoutesGroupDTO['id']]
  groupsList: readonly [...RouteQueryKeys['groups'], Partial<Omit<ReqFetchList, 'page' | 'limit'>>]
  loyaltyLevels: readonly [...RouteQueryKeys['all'], RouteItem['id'], 'loyalty_levels']
  promotions: readonly [...RouteQueryKeys['all'], RouteItem['id'], 'promotions']
  places: readonly [...RouteQueryKeys['all'], RouteItem['id'], 'places']
  stations: readonly [...RouteQueryKeys['all'], RouteItem['id'], 'stations', number]
}

export const routeQueryFns = {
  getRouteList: async (
    context: ConnectedQueryFunctionContext<RouteQueryKeys['list']>
  ): Promise<ListType<RouteItem>> => {
    const {
      pageParam = 1,
      queryKey: [, filters, { search }],
    } = context

    const payload: RouteReqFetchListRequest = {
      ...filters,
      ...getQueryPagination({ pageParam }),
    }
    if (search) payload.query = search.trim()

    const { data } = await apiRoutesFetchList({ request: payload })
    return data
  },
  getRoutesGroupsList: async (
    context: ConnectedQueryFunctionContext<RouteQueryKeys['groupsList']>
  ): Promise<ListType<RoutesGroupDTO>> => {
    const {
      pageParam = 1,
      queryKey: [, filters, { search }],
    } = context

    const payload: RouteReqFetchListRequest = {
      ...filters,
      ...getQueryPagination({ pageParam }),
    }

    if (search) payload.query = search.trim()

    const { data } = await apiRoutesGroupsFetchList({ request: payload })
    return data
  },
}
