/**
 * @author Max Maximov
 * @version 1.0.0
 *
 */
import { ReactNode, useState } from 'react'
import { Card, CardContent, CardHeader, Collapse, Divider, Grid, Typography } from '@mui/material'

import { StyledExpandMoreIcon } from '../styles-components'

type Props = {
  centeredTitle?: boolean
  expandedTitle?: ReactNode
  title?: string
  className?: string
  opened?: boolean
  collapseChildren: ReactNode
  children?: ReactNode
}

export const MuiCardCollapse = ({
  centeredTitle = false,
  expandedTitle,
  title = '',
  className = '',
  opened = true,
  collapseChildren,
  children = null,
}: Props) => {
  const [expanded, setExpanded] = useState(opened)

  const handleExpandClick = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <Card className={className}>
      <CardHeader
        title={
          <Grid container justifyContent="space-between" alignItems="center">
            {centeredTitle && <Grid item></Grid>}
            <Grid item>{expandedTitle ? expandedTitle : <Typography>{title}</Typography>}</Grid>
            <Grid item>
              <StyledExpandMoreIcon
                expanded={expanded}
                aria-expanded={expanded}
                aria-label="show less"
              />
            </Grid>
          </Grid>
        }
        onClick={handleExpandClick}
        sx={{ cursor: 'pointer' }}
      />
      <Divider />
      <CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {collapseChildren}
        </Collapse>

        {children}
      </CardContent>
    </Card>
  )
}

MuiCardCollapse.displayName = 'MuiCardCollapse'
