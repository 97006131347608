import { TicketDetail } from '../tickets'

import { SheetReqGetPrice } from './models/sheetPrice'

export const sheetQueryKeys = {
  all: ['sheets'] as const,
  ticketDetail: (id: TicketDetail['id'] | null) =>
    [...sheetQueryKeys.all, 'ticket-detail', id] as const,
  price: (params: SheetReqGetPrice | null) => [...sheetQueryKeys.all, 'price', params] as const,
}
