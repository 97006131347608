import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import { apiTicketsFetchReturnConditions } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { ticketsActions } from '../../index'
import { FetchReturnConditionsAsync } from '../../types'

export function* fetchReturnConditionsWorker(action: FetchReturnConditionsAsync): SagaIterator {
  try {
    yield put(ticketsActions.toggleLoadingCondition(true))
    // Api call or some async actions
    const { data } = yield call(apiTicketsFetchReturnConditions, action.payload)

    if (data) {
      yield put(ticketsActions.saveReturnCondition(data.item))
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(ticketsActions.toggleLoadingCondition(false))
  }
}
