// Допоміжні функції для передачі даних між компонентами

import { PayloadAction } from '@reduxjs/toolkit'

import { SheetPlace, SheetPlaceTransfer } from '@klr/api-connectors'

import { SheetsState } from '../sheetState'

export const transferPlaceReducers = {
  pushTransferTransportPlace: (state: SheetsState, action: PayloadAction<SheetPlaceTransfer>) => {
    state.transferTransportPlace = action.payload
  },
  clearTransferTransport: (state: SheetsState) => {
    state.transferTransportPlace = null
    state.transferTransport.length = 0
  },
  setTransferTransportPlaces: (state: SheetsState, action: PayloadAction<SheetPlace[]>) => {
    state.transferTransportPlaces = action.payload
  },
  clearTransferTransportPlaces: (state: SheetsState) => {
    state.transferTransportPlaces = []
  },
  pushTransferPlace: (state: SheetsState, action: PayloadAction<SheetPlaceTransfer>) => {
    state.transferPlaces.push(action.payload)
  },
  resetTransferPlace: (state: SheetsState) => {
    state.transferPlaces = []
  },
}
