import { isAfter } from 'date-fns'
import { create } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'

import { BookingItem, BookingReqFetchList } from '@klr/api-connectors'
import { createSelectors } from '@klr/shared'
import { defaultCarrier } from '@klr/system-entities'

import { BookingResFetchListStatus } from '../booking.types'

interface BookingSearchStore {
  internalTrips: BookingItem[]
  externalTrips: BookingItem[]
  tripRequest: BookingReqFetchList | null
  status: BookingResFetchListStatus
  initialDate: string
  sortedInternalTrips(): BookingItem[]
}

const getSortedTripList = (tripList: BookingItem[]) => {
  const sortedTrips = tripList.sort((currentTrip, nextTrip) =>
    isAfter(new Date(currentTrip.start_date_time), new Date(nextTrip.start_date_time)) ? 1 : -1
  )

  const defaultCarrierTrips = sortedTrips.filter((item) => item.carrier_id === defaultCarrier.id)
  const anotherTrips = sortedTrips.filter((item) => item.carrier_id !== defaultCarrier.id)

  return [...defaultCarrierTrips, ...anotherTrips]
}

export const useBookingSearchStore = createSelectors(
  create<BookingSearchStore>()(
    devtools(
      subscribeWithSelector((_, get) => ({
        internalTrips: [],
        externalTrips: [],
        tripRequest: null,
        status: null,
        initialDate: '',
        sortedInternalTrips: () => getSortedTripList(get().internalTrips),
      })),
      {
        name: 'bookingStore',
        enabled: process.env.NODE_ENV === 'development',
      }
    )
  )
)

export const setInternalTrips = (payload: BookingItem[]) =>
  useBookingSearchStore.setState(() => ({ internalTrips: payload }))

export const setExternalTrips = (payload: BookingItem[]) =>
  useBookingSearchStore.setState(() => ({ externalTrips: payload }))

export const setTripRequest = (payload: BookingReqFetchList) =>
  useBookingSearchStore.setState(() => ({ tripRequest: payload }))

export const setStatus = (payload: BookingResFetchListStatus) =>
  useBookingSearchStore.setState(() => ({ status: payload }))

export const setInitialDate = (payload: string) =>
  useBookingSearchStore.setState(() => ({ initialDate: payload }))
