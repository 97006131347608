import { PayloadAction } from '@reduxjs/toolkit'

import {
  ListType,
  SheetPlace,
  SheetPlaceEmpty,
  TripTransportItem,
  TripTransportReqChangeStatusPlaces,
} from '@klr/api-connectors'

import { TripsState } from '../types'

export const transportReducers = {
  saveTransportItems: (state: TripsState, action: PayloadAction<ListType<TripTransportItem>>) => {
    state.transports = action.payload.items
  },
  addTransportItem: (state: TripsState, action: PayloadAction<TripTransportItem>) => {
    state.transports.push(action.payload)
  },
  deleteTransportItem: (state: TripsState, action: PayloadAction<number>) => {
    state.transports = state.transports.filter((item) => item.id !== action.payload)
  },
  changeStatusPlaces: (
    state: TripsState,
    action: PayloadAction<TripTransportReqChangeStatusPlaces>
  ) => {
    if (state.transports?.length) {
      const idx = state.transports.findIndex(
        (transport) =>
          transport.id === action.payload.transport_id &&
          transport.trip_id === action.payload.trip_id
      )

      state.transports[idx] = {
        ...state.transports[idx],
        places: state.transports[idx].places.map((place) => {
          let changePlace = place

          action.payload.data.places.forEach((newPlace: SheetPlace | SheetPlaceEmpty) => {
            if (place.place === newPlace.place) {
              changePlace = newPlace
            }
          })

          return changePlace
        }),
      }
    }
  },
  replaceTransport: (
    state: TripsState,
    action: PayloadAction<{
      oldId: number
      item: TripTransportItem
    }>
  ) => {
    if (state.transports.length) {
      const idx = state.transports.findIndex((t) => t.id === action.payload.oldId)

      state.transports[idx] = action.payload.item
    }
  },
  toggleLoadingTransports: (state: TripsState, action: PayloadAction<boolean>) => {
    state.loadingTransports = action.payload
  },
}
