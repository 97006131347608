import type { TypedUseSelectorHook } from 'react-redux'
import { useSelector as useReduxSelector } from 'react-redux'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'

import sagaMiddleware from './middleware/saga'
import createRootReducer from './rootReducer'
import rootSaga from './rootSaga'

export const history = createBrowserHistory()

const middleware = [createRouterMiddleware(history), sagaMiddleware]

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

export const store = configureStore({
  reducer: createRootReducer(history),
  devTools: process.env.NODE_ENV === 'development',
  middleware,
  enhancers: [sentryReduxEnhancer],
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export const useRootSelector: TypedUseSelectorHook<RootState> = useReduxSelector
