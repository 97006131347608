import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType, ListType } from '../_types'
import { OrderStatus } from '../orderStatuses'

import {
  DispatcherDetail,
  DispatcherItem,
  DispatcherReqChangeOrderStatuses,
  DispatcherReqChangeRelatedRoutes,
  DispatcherReqCreate,
  DispatcherReqFetchList,
  DispatcherRoute,
  DispatchersGroupDTO,
  DispatchersGroupReqChange,
  DispatchersGroupReqCreate,
} from './dispatchers.models'

export const apiDispatcherFetchList = (
  params?: DispatcherReqFetchList
): AxiosPromise<ListType<DispatcherItem>> => {
  return axios.get('/dispatchers', { params })
}

export const apiDispatcherFetchDetail = (
  dispatcherId: DispatcherItem['id']
): AxiosPromise<DetailType<DispatcherDetail>> => {
  return axios.get(`/dispatchers/${dispatcherId}`)
}

export const apiDispatcherCreate = (
  data: DispatcherReqCreate
): AxiosPromise<DetailType<DispatcherDetail>> => {
  return axios.post('/dispatchers', data)
}

export const apiDispatcherFetchRelatedRoutes = (
  dispatcherId: DispatcherItem['id']
): AxiosPromise<ListType<DispatcherRoute>> => {
  return axios.get(`/dispatchers/${dispatcherId}/related-routes`)
}

export const apiDispatcherChangeRelatedRoutes = ({
  dispatcherId,
  data,
}: DispatcherReqChangeRelatedRoutes): AxiosPromise<ListType<DispatcherRoute>> => {
  return axios.put(`/dispatchers/${dispatcherId}/related-routes`, data)
}

export const apiDispatcherFetchOrderStatuses = (
  dispatcherId: DispatcherItem['id']
): AxiosPromise<ListType<OrderStatus>> => {
  return axios.get(`/dispatchers/${dispatcherId}/order-statuses`)
}

export const apiDispatcherChangeOrderStatuses = ({
  dispatcherId,
  data,
}: DispatcherReqChangeOrderStatuses): AxiosPromise<ListType<OrderStatus>> => {
  return axios.put(`/dispatchers/${dispatcherId}/order-statuses`, data)
}

export const apiDispatchersGroupsFetchList = (
  params?: DispatcherReqFetchList
): AxiosPromise<ListType<DispatchersGroupDTO>> => {
  return axios.get('/dispatcher-groups', { params })
}

export const apiDispatchersGroupsDetailFetchList = (
  id: DispatchersGroupDTO['id']
): AxiosPromise<DetailType<DispatchersGroupDTO>> => {
  return axios.get(`/dispatcher-groups/${id}`)
}

export const apiDispatchersGroupCreate = (
  data: DispatchersGroupReqCreate
): AxiosPromise<DetailType<DispatchersGroupDTO>> => {
  return axios.post('/dispatcher-groups', data)
}

export const apiDispatchersGroupChange = ({
  id,
  data,
}: DispatchersGroupReqChange): AxiosPromise<DetailType<DispatchersGroupDTO>> => {
  return axios.put(`/dispatcher-groups/${id}`, data)
}
export const apiDispatchersGroupDelete = (id: DispatchersGroupDTO['id']): AxiosPromise => {
  return axios.delete(`/dispatcher-groups/${id}`)
}
