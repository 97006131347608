import IdeaIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import { MenuItem } from '@mui/material'

import { handleOpenFeaturebaseWidget } from '../utils/featurebase-widget.utils'

export const InitFeedbackButton = () => (
  <MenuItem onClick={handleOpenFeaturebaseWidget} sx={{ m: 1 }}>
    <IdeaIcon fontSize="small" />
    Надішліть відгук
  </MenuItem>
)
