import { SheetPlace, StatusTicketEnum } from '@klr/api-connectors'
import { formatCurrency, formatToUkrDateTimeString } from '@klr/shared'

export const generateMessageSendTicket = (place: SheetPlace) => {
  const isPartner = !!place.is_partner || place.status === StatusTicketEnum.RESERVED

  const ticketNumber = isPartner ? '' : `Номер квитка: ${place.ticket_number}`
  const price = isPartner
    ? `\nдо оплати у водія ${formatCurrency(place.price, place.currency_iso)}`
    : ''

  const note = place.information ? `\nПримітка: ${place.information}` : ''

  return (
    `✉️[${formatToUkrDateTimeString(new Date())}]` +
    `\nМісце №${place.place}. ${place.paid_form_name}. ` +
    ticketNumber +
    `\n${place.first_name} ${place.last_name} ${place.phone}` +
    `\n🚌 ${place.city_from} - ${place.city_to} 🚌` +
    note +
    price
  )
}
