import { AxiosPromise } from 'axios'

import axios from '../_axios/axios'
import { ListDataType } from '../_types'

import {
  PlaceCategoryPriceDTO,
  PlaceCategoryPriceReqFetchList,
  PlaceCategoryPriceReqUpdate,
} from './place-category-prices.models'

export const apiPlaceCategoryPricesFetchList = (
  params: PlaceCategoryPriceReqFetchList
): AxiosPromise<ListDataType<PlaceCategoryPriceDTO>> => {
  return axios.get('/place-category-prices', { params })
}

export const apiPlaceCategoryPricesUpdate = (
  data: PlaceCategoryPriceReqUpdate
): AxiosPromise<PlaceCategoryPriceDTO> => {
  return axios.post('/place-category-prices', data)
}
