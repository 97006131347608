import { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

// Detail Components
import { AlertError, Drawer, Fallback, toggleScroll } from '@klr/shared'

import { ticketsActions } from '../../_modules_/tickets'
import { uiActions, uiSelectors } from '../../_modules_/ui'
import { DetailViewEnum } from '../../constants/detail-view.enum'

const components = {
  // Sheets
  [DetailViewEnum.SEARCH_SHEET]: lazy(
    () => import('../../_modules_/sheets/components/DrawerSearchRoute')
  ),
  [DetailViewEnum.MANAGE_BLOCKED_SALES]: lazy(
    () => import('../../_modules_/sheets/components/DrawerDetailBlockedSales')
  ),
  // Carriers
  [DetailViewEnum.ADD_ROUTE_STATION]: lazy(
    () =>
      import('../../_modules_/routes/sections/route-edit/components/ManageStation/DrawerAddStation')
  ),
  [DetailViewEnum.EDIT_ROUTE_STATION]: lazy(
    () =>
      import(
        '../../_modules_/routes/sections/route-edit/components/ManageStation/DrawerEditStation'
      )
  ),
  [DetailViewEnum.COPY_ROUTE]: lazy(
    () => import('../../_modules_/routes/components/Drawer/DrawerCopyRoute')
  ),
  [DetailViewEnum.EDIT_TRIP_STATION]: lazy(
    () =>
      import(
        '../../_modules_/routes/sections/route-edit/components/ManageStation/DrawerEditStation'
      )
  ),
  [DetailViewEnum.EDIT_ROUTE_INFO]: lazy(
    () => import('../../_modules_/routes/sections/route-edit/components/DrawerEditInfo')
  ),
  [DetailViewEnum.EDIT_ROUTE_PROMOTION]: lazy(
    () => import('../../_modules_/routes/sections/route-edit/components/DrawerPromotion')
  ),
  [DetailViewEnum.ADD_ROUTE_PROMOTION]: lazy(
    () => import('../../_modules_/routes/sections/route-edit/components/DrawerPromotion')
  ),
  [DetailViewEnum.MUTATE_CITY]: lazy(
    () => import('../../_modules_/stations/components/DrawerCity')
  ),
  [DetailViewEnum.CREATE_CITY]: lazy(
    () => import('../../_modules_/stations/components/DrawerCity')
  ),
  [DetailViewEnum.MUTATE_PARTNER]: lazy(() => import('../../_modules_/partners/DrawerPartnerForm')),
  [DetailViewEnum.FETCH_PERIOD_RULES]: lazy(
    () => import('../../_modules_/dynamicFare/controllers/DynamicFareCreate/DynamicFareCreateRule')
  ),
}

export const DrawerDetail = () => {
  const dispatch = useDispatch()
  const detailView = useSelector(uiSelectors.getDetailView)
  const isShowDetail = useSelector(uiSelectors.isShowDetail)
  const Component = components[detailView.meta as keyof typeof components] || 'div'

  const _close = () => {
    toggleScroll(false)
    dispatch(uiActions.closeDetailView())
    dispatch(ticketsActions.clearDetail())
  }

  useEffect(() => {
    toggleScroll(isShowDetail)
  }, [isShowDetail])

  return (
    <Drawer open={isShowDetail} onClose={_close}>
      <Sentry.ErrorBoundary fallback={(errorData) => <AlertError {...errorData} />}>
        <Suspense fallback={<Fallback />}>
          <Component />
        </Suspense>
      </Sentry.ErrorBoundary>
    </Drawer>
  )
}
