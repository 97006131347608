import { create } from 'zustand'

import { RouteItem } from '@klr/api-connectors'

interface RoutesStore {
  routes: RouteItem[]
  dispatcherRoutes: RouteItem[]
}

export const useRoutesStore = create<RoutesStore>()((_) => ({
  routes: [],
  dispatcherRoutes: [],
}))

export const setRoutes = (routes: RouteItem[]) =>
  useRoutesStore.setState(() => ({
    routes,
  }))

export const setDispatcherRoutes = (dispatcherRoutes: RouteItem[]) =>
  useRoutesStore.setState(() => ({
    dispatcherRoutes,
  }))
