export enum DetailViewEnum {
  SEARCH_SHEET = 'SEARCH_SHEET',
  // Manage Route
  MANAGE_ROUTE_PERIOD = 'MANAGE_ROUTE_PERIOD',
  ADD_ROUTE_STATION = 'ADD_ROUTE_STATION',
  EDIT_ROUTE_STATION = 'EDIT_ROUTE_STATION',
  EDIT_ROUTE_INFO = 'EDIT_ROUTE_INFO',
  // Route Promotions
  EDIT_ROUTE_PROMOTION = 'EDIT_ROUTE_PROMOTION',
  ADD_ROUTE_PROMOTION = 'ADD_ROUTE_PROMOTION',
  COPY_ROUTE = 'COPY_ROUTE',
  EDIT_TRIP_STATION = 'EDIT_TRIP_STATION',
  // Manege Blocked Sales
  MANAGE_BLOCKED_SALES = 'MANAGE_BLOCKED_SALES',
  // Manege Content Category
  MUTATE_CONTENT_CATEGORY = 'MUTATE_CONTENT_CATEGORY',
  CREATE_CONTENT_CATEGORY = 'CREATE_CONTENT_CATEGORY',
  // Manege City
  MUTATE_CITY = 'MUTATE_CITY',
  CREATE_CITY = 'CREATE_CITY',
  // Report
  DETAIL_ORDER_REPORT = 'DETAIL_ORDER_REPORT',
  // Partner
  MUTATE_PARTNER = 'MUTATE_PARTNER',
  // Analytics
  FETCH_PERIOD_RULES = 'FETCH_PERIOD_RULES',
}

export enum DetailModalEnum {
  DETAIL_SHEET_TICKET = 'DETAIL_SHEET_TICKET',
  SHEET_TRANSFER_TO_ROUTE = 'SHEET_TRANSFER_TO_ROUTE',
  MANAGE_SHEET_BUS = 'MANAGE_SHEET_BUS',
  MANAGE_SHEET_TRIP = 'MANAGE_SHEET_TRIP',
  // Report
  DETAIL_ORDER_REPORT = 'DETAIL_ORDER_REPORT',
  // Templates
  MANAGE_TEMPLATES = 'MANAGE_TEMPLATES',
}
