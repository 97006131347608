import { PayloadAction } from '@reduxjs/toolkit'

import { SheetPlace, SheetPlaceEmpty, TicketReconciliation } from '@klr/api-connectors'

import { isPlaceNotEmpty } from '../helpers'
import { SheetsState } from '../sheetState'

type Options = 'places' | 'sub_sheet'

export const reconciliationTicket = (
  state: SheetsState,
  action: PayloadAction<TicketReconciliation[]>
) => {
  if (state.transports?.length) {
    const idx = state.transports.findIndex(
      (t) => t.id === state.selectTransport?.id && t.trip.id === state.selectTransport.tripId
    )
    const options: Options[] = ['places', 'sub_sheet']

    options.forEach((option) => {
      action.payload.forEach((item) => {
        if (state.transports[idx][option]) {
          state.transports[idx][option] = state.transports[idx][option]?.map(
            (p: SheetPlace | SheetPlaceEmpty) => {
              if (isPlaceNotEmpty(p) && p.ticket_id === item.ticket_id) {
                return {
                  ...p,
                  reconciled_at: item.reconciled_at,
                }
              }

              return p
            }
          ) as (SheetPlace | SheetPlaceEmpty)[] & SheetPlace[]
        }
      })
    })
  }
}
