import { Box, Stack, Tooltip, Typography } from '@mui/material'

import { SheetDefaultMainPrice } from '@klr/api-connectors'

import { SurchargePrice } from './SurchargePrice'

type PriceViewProps = {
  price: SheetDefaultMainPrice | null
  surchargePrice: number
  setEditMode: (val: boolean) => void
}

export const PriceView = ({ price, setEditMode, surchargePrice }: PriceViewProps) => {
  if (!price || !price.price_full) {
    return null
  }

  if (price.price_full === price.price_discount) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Tooltip
          title="Повна ціна"
          aria-label="Повна ціна"
          sx={{ cursor: 'pointer' }}
          onClick={() => setEditMode(true)}
        >
          <Typography color="primary">
            {price.price_full} {price.currency?.title || ''}
          </Typography>
        </Tooltip>
        {surchargePrice > 0 && (
          <SurchargePrice
            currencyTitle={price.currency?.title || ''}
            surchargePrice={surchargePrice}
          />
        )}
      </Stack>
    )
  }

  return (
    <Stack direction="row" spacing={1}>
      <Box>
        <Tooltip title="Ціна зі знижкою" aria-label="Ціна зі знижкою">
          <Typography component="span" color="primary">
            {price.price_discount} {price.currency?.title || ''}
          </Typography>
        </Tooltip>
        <Tooltip title="Повна ціна" aria-label="Повна ціна">
          <Typography
            variant="body2"
            component="span"
            sx={{
              ml: 2,
            }}
          >
            ({price.price_full} {price.currency?.title || ''})
          </Typography>
        </Tooltip>
      </Box>
      {surchargePrice > 0 && (
        <SurchargePrice
          currencyTitle={price.currency?.title || ''}
          surchargePrice={surchargePrice}
        />
      )}
    </Stack>
  )
}
