import { useMemo } from 'react'
import { Stack, Typography, TypographyOwnProps } from '@mui/material'
import { isValid } from 'date-fns'

import { OrderDetail, TicketDetail } from '@klr/api-connectors'
import { formatToTimeString, formatToUkrDateString, parseDateTimeString } from '@klr/shared'

const getDateTimeDeparture = (date: string | null) => {
  if (!date) {
    return null
  }

  const parsedDate = parseDateTimeString(date)

  if (!isValid(parsedDate)) {
    return null
  }

  return {
    date: formatToUkrDateString(parsedDate),
    time: formatToTimeString(parsedDate),
  }
}

const getRouteName = (ticket: TicketDetail) => {
  const { station_from, station_to } = ticket

  if (!station_from.city || !station_to.city) {
    return null
  }

  return `${station_from.city.name} - ${station_to.city.name}`
}

interface OrderInfoProps {
  inGrid?: boolean
  order: OrderDetail
  variant?: TypographyOwnProps['variant']
}

export const OrderItemInfo = ({ inGrid = false, order, variant = 'body1' }: OrderInfoProps) => {
  const { trip_start_datetime, tickets } = order

  const dateTimeDeparture = useMemo(
    () => getDateTimeDeparture(trip_start_datetime),
    [trip_start_datetime]
  )
  const routeName = useMemo(() => getRouteName(tickets[0]), [tickets])

  if (inGrid) {
    return (
      <>
        <Stack direction="row" spacing={1}>
          {dateTimeDeparture && <Typography variant={variant}>{dateTimeDeparture.date}</Typography>}
          <span>•</span>
          {dateTimeDeparture && <Typography variant={variant}>{dateTimeDeparture.time}</Typography>}
        </Stack>
        {routeName && <Typography variant={variant}>{routeName}</Typography>}
      </>
    )
  }

  return (
    <Stack direction="row" spacing={2}>
      {dateTimeDeparture && <Typography variant={variant}>{dateTimeDeparture.date}</Typography>}
      {routeName && <Typography variant={variant}>{routeName}</Typography>}
      {dateTimeDeparture && <Typography variant={variant}>{dateTimeDeparture.time}</Typography>}
    </Stack>
  )
}
