import { TFunction } from 'react-i18next'
import * as Yup from 'yup'

import {
  validateEmail,
  validateNotRequiredStringField,
  validatePhone,
  validateRequiredStringField,
} from '@klr/shared'

import { AvailablePlace } from '../../../sheets/validations'

export const changeTicketSchema = (t: TFunction) =>
  Yup.object().shape({
    place: Yup.object().nullable().notRequired(),
    firstName: validateNotRequiredStringField(t),
    lastName: validateNotRequiredStringField(t),
    paidForm: Yup.object().notRequired(),
    phone: Yup.lazy((value) => {
      if (value !== '') {
        return validatePhone(t)
      }
      return Yup.mixed().notRequired()
    }),
    email: Yup.lazy((value) => {
      if (value !== '') {
        return validateEmail(t)
      }
      return Yup.mixed().notRequired()
    }),
    ticketNumber: validateRequiredStringField(t),
    note: validateNotRequiredStringField(t),
  })

export interface FormFields {
  place: AvailablePlace | null
  firstName: string
  lastName: string
  paidForm: { id: string | null; name: string }
  phone: string
  email: string
  ticketNumber: string
  note: string
}
