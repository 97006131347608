import { X as CloseIcon } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContentPaste as ContentPasteIcon } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'

import { copyToClipboard, FormProvider, RHFTextField, StyledCloseButton } from '@klr/shared'

import {
  SocialNetworksFormFields,
  socialNetworksValidationSchema,
} from '../../../../../../customer-socials.validations'

interface CustomerShortSocialModalFormProps {
  initialLink: string | null
  label: string
  open: boolean
  onClose(): void
  onSave(data: SocialNetworksFormFields): void
}

export const CustomerShortSocialModalForm = ({
  initialLink,
  label,
  open,
  onClose,
  onSave,
}: CustomerShortSocialModalFormProps) => {
  const { t } = useTranslation()

  const methods = useForm<SocialNetworksFormFields>({
    defaultValues: {
      link: initialLink ?? '',
    },
    resolver: yupResolver(socialNetworksValidationSchema(t)),
  })

  const {
    formState: { isDirty },
    handleSubmit,
    watch,
  } = methods

  const socialLink = watch('link')

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {label}
        <Tooltip title={t('Common.close')}>
          <StyledCloseButton aria-label="close" onClick={onClose}>
            <CloseIcon fontSize="large" />
          </StyledCloseButton>
        </Tooltip>
      </DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
        <DialogContent sx={{ pt: 1 }}>
          <RHFTextField
            name="link"
            label="Посилання"
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={!socialLink}
                  onClick={() => copyToClipboard(socialLink ?? '', t('Messages.link_copied'))}
                  size="small"
                >
                  <ContentPasteIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!isDirty} variant="contained" type="submit">
            {t('Common.save')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
