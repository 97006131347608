import { useEffect } from 'react'

import { useAuth } from '@klr/auth'
import { useSettings } from '@klr/shared'

export const InitFeaturebase = () => {
  const { themeMode } = useSettings()
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      // eslint-disable-next-line
      const win = window as any

      if (typeof win.Featurebase !== 'function') {
        win.Featurebase = function () {
          // prefer-rest-params && prettier
          // eslint-disable-next-line
          ;(win.Featurebase.q = win.Featurebase.q || []).push(arguments)
        }
      }
      win.Featurebase('initialize_feedback_widget', {
        organization: 'klrbus',
        theme: themeMode,
        email: user.email,
        metadata: {
          first_name: user.first_name,
          last_name: user.last_name,
          user_id: user.id.toString(),
          roles: user.roles.map((role) => role.name).join(', '),
          appVersion: APP_VERSION,
          device_type: navigator.userAgent,
        },
      })

      win.Featurebase('initialize_changelog_widget', {
        organization: 'klrbus',
        theme: themeMode,
        fullscreenPopup: true,
        usersName: user.first_name,
      })
    }
  }, [themeMode, user])

  return null
}
