import { toast } from 'react-hot-toast'
import { all, call } from 'redux-saga/effects'

// Watchers
import { watchTickets } from '../../_modules_/tickets/saga/watchers'
import { watchTrips } from '../../_modules_/trips/saga/watchers'

function* rootSaga() {
  try {
    yield all([call(watchTickets), call(watchTrips)])
  } catch (error) {
    toast.error('Сталася помилка, перезавантажте сторінку.')
  }
}

export default rootSaga
