import { ReactNode, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { SystemRoleMap } from '@klr/api-connectors'
import { globalPaths } from '@klr/shared'

import { useAuth } from '../context'

interface RoleBasedGuardProps {
  roles: SystemRoleMap[]
  children: ReactNode
}

export const RoleBasedGuard = ({ children, roles }: RoleBasedGuardProps) => {
  const { user } = useAuth()

  const hasAccess = useMemo(() => {
    const userRoles = user?.roles.map((role) => role.key) || []

    if (userRoles.includes(SystemRoleMap.Admin)) return true

    return userRoles.some((role) => roles.includes(role)) || false
  }, [roles, user?.roles])

  if (!hasAccess) {
    return <Navigate to={globalPaths.error404} replace />
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
