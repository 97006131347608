import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { SheetPlaceEmptyVoidFunction, SheetPlaceVoidFunction } from '@klr/api-connectors'

import { MetaCreateTicketEnum } from '../configs'
import { sheetsActions } from '../slice'

export const useShowCreateTicket = () => {
  const dispatch = useDispatch()

  const showCreateTicketCopy: SheetPlaceVoidFunction = useCallback(
    (place) => {
      dispatch(
        sheetsActions.showCreateDrawer({
          meta: MetaCreateTicketEnum.COPY,
          place,
        })
      )
    },
    [dispatch]
  )

  const showCreateTicketEmptyPlace: SheetPlaceEmptyVoidFunction = useCallback(
    (place) => {
      dispatch(
        sheetsActions.showCreateDrawer({
          meta: MetaCreateTicketEnum.EMPTY,
          place,
        })
      )
    },
    [dispatch]
  )

  return { showCreateTicketCopy, showCreateTicketEmptyPlace }
}

export const useShowCreateTicketQuick = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(
      sheetsActions.showCreateDrawer({
        meta: MetaCreateTicketEnum.QUICK,
        place: null,
      })
    )
  }, [dispatch])
}
