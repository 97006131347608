import { IconButton } from '@mui/material'

import { SvgColor } from '@klr/shared'

import { handleOpenFeaturebaseChangelog } from '../utils/featurebase-widget.utils'

export const InitChangelogButton = () => (
  <IconButton onClick={handleOpenFeaturebaseChangelog}>
    <SvgColor src="/assets/icons/megaphone.svg" />
  </IconButton>
)
