import { memo, useCallback, useState } from 'react'
import { Collapse, ListSubheader, Stack } from '@mui/material'

import { NavGroupProps, NavProps } from '../../../shared/types'

import { NavList } from './components/nav-list'

export const NavSectionVertical = memo(({ data, slotProps, ...other }: NavProps) => (
  <Stack component="nav" id="nav-section-vertical" {...other}>
    {data.map((group, index) => (
      <Group
        key={group.subheader || index}
        subheader={group.subheader}
        items={group.items}
        slotProps={slotProps}
      />
    ))}
  </Stack>
))

const Group = ({ subheader, items, slotProps }: NavGroupProps) => {
  const [open, setOpen] = useState(true)

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const renderContent = items.map(
    (list) => list && <NavList key={list?.title} data={list} depth={1} slotProps={slotProps} />
  )

  return (
    <Stack sx={{ px: 2 }}>
      {subheader ? (
        <>
          <ListSubheader
            disableGutters
            disableSticky
            onClick={handleToggle}
            sx={{
              fontSize: 11,
              cursor: 'pointer',
              typography: 'overline',
              display: 'inline-flex',
              color: 'text.disabled',
              mb: '4px',
              p: (theme) => theme.spacing(2, 1, 1, 1.5),
              transition: (theme) =>
                theme.transitions.create(['color'], {
                  duration: theme.transitions.duration.shortest,
                }),
              '&:hover': {
                color: 'text.primary',
              },
              ...slotProps?.subheader,
            }}
          >
            {subheader}
          </ListSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </Stack>
  )
}
