import { useState } from 'react'
import { Close, Save } from '@mui/icons-material'
import { Box, IconButton, Stack } from '@mui/material'

import { SheetDefaultMainPrice } from '@klr/api-connectors'
import { NumberInput } from '@klr/shared'

import { SurchargePrice } from './SurchargePrice'

type PriceEditFormProps = {
  price: SheetDefaultMainPrice | null
  setPrice: (val: SheetDefaultMainPrice) => void
  setEditMode: (val: boolean) => void
  surchargePrice: number
}

export const PriceEditForm = ({
  price,
  setPrice,
  setEditMode,
  surchargePrice,
}: PriceEditFormProps) => {
  const [customPrice, setCustomPrice] = useState(price?.price_full || 0)

  const onSave = () => {
    setPrice({
      price_full: customPrice,
      price_discount: customPrice,
      currency: price?.currency || null,
    })
    setEditMode(false)
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box maxWidth={120}>
        <NumberInput
          size="small"
          value={customPrice || undefined}
          setValue={(val) => setCustomPrice(val ?? 0)}
        />
      </Box>

      <IconButton
        size="small"
        color="success"
        onClick={onSave}
        disabled={customPrice <= 0 || customPrice === price?.price_full}
        disableRipple
      >
        <Save fontSize="small" />
      </IconButton>
      <IconButton size="small" color="error" onClick={() => setEditMode(false)} disableRipple>
        <Close fontSize="small" />
      </IconButton>
      {surchargePrice > 0 && (
        <SurchargePrice
          currencyTitle={price?.currency?.title || ''}
          surchargePrice={surchargePrice}
        />
      )}
    </Stack>
  )
}
