import { PayloadAction } from '@reduxjs/toolkit'
import { sortBy } from 'lodash'

import { SheetPlace } from '@klr/api-connectors'

import { getAvailablePlace } from '../helpers'
import { SheetsState } from '../sheetState'

type Props = {
  place: SheetPlace
  isMoveSubList: boolean
  isConflicts: boolean
}

export const toggleSubList = (state: SheetsState, action: PayloadAction<Props>) => {
  const idx = state.transports.findIndex(
    (t) => t.id === state.selectTransport?.id && t.trip.id === state.selectTransport.tripId
  )

  if (action.payload.isConflicts) {
    state.transports[idx].conflicted =
      state.transports[idx].conflicted?.filter(
        (p) => p.ticket_id !== action.payload.place.ticket_id
      ) || null
  }

  if (action.payload.isMoveSubList) {
    // Потрібно добавити, якщо в sub_sheet стоїть null
    if (!state.transports[idx].sub_sheet) {
      state.transports[idx].sub_sheet = []
    }

    if (!action.payload.isConflicts) {
      state.transports[idx].places = state.transports[idx].places.map((place) =>
        place.place === action.payload.place.place
          ? getAvailablePlace(action.payload.place.place)
          : place
      )
    }

    state.transports[idx] = {
      ...state.transports[idx],
      sub_sheet: sortBy(
        [action.payload.place, ...(state.transports[idx].sub_sheet ?? [])],
        (obj) => obj.place
      ),
    }
  } else {
    if (!action.payload.isConflicts) {
      state.transports[idx].sub_sheet =
        state.transports[idx].sub_sheet?.filter((place: SheetPlace) => {
          return place.ticket_id !== action.payload.place.ticket_id
        }) || null
    }

    state.transports[idx].places = state.transports[idx].places.map((place) =>
      place.place === action.payload.place.place ? action.payload.place : place
    )

    if (state.transferPlaces.length > 0) {
      state.transferPlaces = state.transferPlaces.filter(
        (p) => p.ticket_id !== action.payload.place.ticket_id
      )
    }
  }
}
