import { lazy, Suspense } from 'react'
import { Box, Divider, styled, Tab, Tabs, Typography } from '@mui/material'

import { Fallback, useTabController } from '@klr/shared'

const CustomerOrderList = lazy(() => import('./components/CustomerOrderList'))
const CustomerTripList = lazy(() => import('./components/CustomerTripList'))
const CustomerNotes = lazy(() => import('./components/CustomerNotes'))
const CustomerSocialNetworks = lazy(() => import('./components/CustomerSocialNetworks'))

const tabs = ['Замовлення', 'Поїздки', 'Примітки', 'Соцмережі']

const CustomerTabContent = ({ currentTab }: { currentTab: number }) => {
  switch (currentTab) {
    case 0:
      return <CustomerOrderList />
    case 1:
      return <CustomerTripList />
    case 2:
      return <CustomerNotes />
    case 3:
      return <CustomerSocialNetworks />
    default:
      return null
  }
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  borderRadius: 16,
}))

export const CustomerInfoTabs = () => {
  const { currentTab, handleTabsChange } = useTabController(0)

  return (
    <StyledBox>
      <Tabs
        value={currentTab}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((item, index) => (
          <Tab
            key={index}
            label={<Typography variant="caption">{item}</Typography>}
            value={index}
          />
        ))}
      </Tabs>

      <Divider sx={{ mb: 2 }} />

      <Suspense fallback={<Fallback />}>
        <CustomerTabContent currentTab={currentTab} />
      </Suspense>
    </StyledBox>
  )
}
