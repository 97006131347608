import { StatusTicketEnum } from '@klr/api-connectors'

interface AllowedActionsTicketProps {
  isEarlyBooking: boolean
  orderStatus: StatusTicketEnum
  status: StatusTicketEnum
  canReturnEBTicket: boolean
}

export const getAllowedActionsTickets = ({
  isEarlyBooking,
  orderStatus,
  status,
  canReturnEBTicket,
}: AllowedActionsTicketProps) => {
  return {
    hasPayReserved: StatusTicketEnum.RESERVED === status, // Внести дані про оплату
    hasLiqPay: StatusTicketEnum.RETURN === status && StatusTicketEnum.PAID === orderStatus, // Повернути кошти через LiqPay
    hasReturnedTicket: StatusTicketEnum.RETURN === status, // Квиток повернутий
    hasDeleteTicket:
      (StatusTicketEnum.RESERVED === status ||
        StatusTicketEnum.PAID === status ||
        StatusTicketEnum.CASH === status ||
        StatusTicketEnum.CASH_DISP === status) &&
      (canReturnEBTicket || !isEarlyBooking), // Видалити квиток
    hasReturnTicket:
      (StatusTicketEnum.PAID === status ||
        StatusTicketEnum.CASH === status ||
        StatusTicketEnum.CASH_DISP === status) &&
      (canReturnEBTicket || !isEarlyBooking), // Повернути квиток
    hasPrintTicket: StatusTicketEnum.RETURN !== status && StatusTicketEnum.CHANGED !== status,
    isShowPaymentLink: status !== StatusTicketEnum.PAID && status !== StatusTicketEnum.CASH_DISP, // Згенерувати посилання на оплату по LiqPay
  }
}
