import { useCallback, useEffect, useRef, useState } from 'react'
import { Popover, Stack } from '@mui/material'

import { usePathname } from '@klr/shared'

import { NavListProps, NavSubListProps } from '../../../../../../components/shared/types'
import { useActiveSubLink } from '../../../../../../components/shared/use-active-sub-link'

import { NavItem } from './nav-item'

export const NavList = ({ data, depth, slotProps }: NavListProps) => {
  const navRef = useRef<HTMLDivElement | null>(null)

  const pathname = usePathname()
  const active = useActiveSubLink(data, pathname)

  const [openMenu, setOpenMenu] = useState(false)

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleOpenMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu(true)
    }
  }, [data.children])

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false)
  }, [])

  return (
    <>
      <NavItem
        ref={navRef}
        open={openMenu}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
        //
        title={data.title}
        path={data.path}
        icon={data.icon}
        caption={data.caption}
        //
        depth={depth}
        hasChild={!!data.children}
        //
        active={active}
        className={active ? 'active' : ''}
        sx={depth === 1 ? slotProps?.rootItem : slotProps?.subItem}
      />

      {!!data.children && (
        <Popover
          disableScrollLock
          open={openMenu}
          anchorEl={navRef.current}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          slotProps={{
            paper: {
              onMouseEnter: handleOpenMenu,
              onMouseLeave: handleCloseMenu,
              sx: {
                mt: 0.5,
                minWidth: 160,
                ...(openMenu && {
                  pointerEvents: 'auto',
                }),
              },
            },
          }}
          sx={{
            pointerEvents: 'none',
          }}
        >
          <NavSubList data={data.children} depth={depth} slotProps={slotProps} />
        </Popover>
      )}
    </>
  )
}

const NavSubList = ({ data, depth, slotProps }: NavSubListProps) => (
  <Stack spacing={0.5}>
    {data.map((list) => (
      <NavList key={list.title} data={list} depth={depth + 1} slotProps={slotProps} />
    ))}
  </Stack>
)
