import { useEffect } from 'react'

import { useAuth } from '@klr/auth'

import { useFetchDispatcherRoutes } from '../../_modules_/dispatchers'
import { useFetchRouteList } from '../../_modules_/routes/hooks'
import { DEFAULT_ROUTE_PARAMS } from '../../_modules_/sheets/configs'
import { setDispatcherRoutes, setRoutes } from '../../_modules_/sheets/hooks/store'

export const useInitRoutes = () => {
  const { isDispatcher, user } = useAuth()

  const { data: generalRoutes } = useFetchRouteList(DEFAULT_ROUTE_PARAMS, {
    enabled: !isDispatcher,
  })

  const { data: dispatcherRoutes } = useFetchDispatcherRoutes(
    isDispatcher && !!user ? user.id : null
  )

  useEffect(() => {
    if (isDispatcher) {
      setDispatcherRoutes(dispatcherRoutes)
    } else {
      setRoutes(generalRoutes)
    }
  }, [dispatcherRoutes, generalRoutes, isDispatcher])
}
