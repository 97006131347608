import { toast } from 'react-hot-toast'
import { SagaIterator } from '@redux-saga/core'
import { call, put } from 'redux-saga/effects'

import { apiTicketsReturn } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { uiActions } from '../../../ui'
import { ticketsActions } from '../../index'
import { ReturnAsync } from '../../types'

export function* returnWorker({ payload }: ReturnAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    const { data } = yield call(apiTicketsReturn, payload)

    if (data) {
      toast.success(`Квиток повернутий, сума повернення - ${data.item.price_returned}`)

      yield put(uiActions.closeDetailView())
      yield put(ticketsActions.clearDetail())
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
