import type { SagaIterator } from '@redux-saga/core'
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'

import { types } from '../types'

import {
  changeStationWorker,
  changeStatusPlaces,
  changeTrip,
  createItem,
  createTransportWorker,
  deleteTransport,
  deleteTrip,
  fetchItems,
  fetchStations,
  fetchTransport,
  updateTransportInfoWorker,
} from './workers'

function* watchFetchItems(): SagaIterator {
  yield takeLatest(types.FETCH_ITEMS, fetchItems)
}

function* watchChange(): SagaIterator {
  yield takeEvery(types.CHANGE_ITEM, changeTrip)
}

function* watchCreate(): SagaIterator {
  yield takeEvery(types.CREATE_ITEM, createItem)
}

function* watchDelete(): SagaIterator {
  yield takeEvery(types.DELETE_ITEM, deleteTrip)
}

function* watchChangeStatusPlaces(): SagaIterator {
  yield takeEvery(types.CHANGE_STATUS_PLACES, changeStatusPlaces)
}

function* watchFetchStations(): SagaIterator {
  yield takeLatest(types.FETCH_STATIONS, fetchStations)
}

function* watchFetchTransport(): SagaIterator {
  yield takeLatest(types.FETCH_TRANSPORT_ITEMS, fetchTransport)
}

function* watchUpdateTransportInfo(): SagaIterator {
  yield takeLatest(types.CHANGE_TRANSPORT_INFO, updateTransportInfoWorker)
}

function* watchChangeStation(): SagaIterator {
  yield takeLatest(types.CHANGE_STATIONS, changeStationWorker)
}

function* watchCreateTransport(): SagaIterator {
  yield takeLatest(types.CREATE_TRANSPORT, createTransportWorker)
}

function* watchDeleteTransport(): SagaIterator {
  yield takeLatest(types.DELETE_TRANSPORT, deleteTransport)
}

export function* watchTrips(): SagaIterator {
  yield all([
    call(watchFetchItems),
    call(watchChange),
    call(watchDelete),
    call(watchChangeStatusPlaces),
    call(watchFetchStations),
    call(watchUpdateTransportInfo),
    call(watchChangeStation),
    call(watchCreate),
    call(watchFetchTransport),
    call(watchCreateTransport),
    call(watchDeleteTransport),
  ])
}
