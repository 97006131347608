import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import {
  apiTicketsFetchAvailableSeats,
  apiTicketsFetchDetail,
  apiTicketsFetchSeatsTransferToDate,
  apiTicketsFetchSeatsTransferToTransport,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { ticketsActions } from '../../slice'
import { FetchAvailableSeatsAsync, FetchDetailAsync, FetchSeatsForTransfer } from '../../types'

export function* fetchDetailWorker(action: FetchDetailAsync): SagaIterator {
  try {
    yield put(ticketsActions.toggleLoading(true))
    // Api call or some async actions
    const response = yield call(apiTicketsFetchDetail, action.payload)

    if (response.data) {
      yield put(ticketsActions.saveDetail(response.data.item))
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(ticketsActions.toggleLoading(false))
  }
}

export function* fetchSeatsForTransferWorker({ payload }: FetchSeatsForTransfer): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))
    // Api call or some async actions
    let response

    if (payload.request.date) {
      response = yield call(apiTicketsFetchSeatsTransferToDate, payload.request)
    } else {
      response = yield call(apiTicketsFetchSeatsTransferToTransport, payload.request.ticketId)
    }

    if (response) {
      if (payload.isSheet) {
        yield put(sheetsActions.saveTransferTransport(response.data.items))
      }
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}

export function* fetchAvailableSeatsWorker({ payload }: FetchAvailableSeatsAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    // Api call or some async actions
    const { data } = yield call(apiTicketsFetchAvailableSeats, { ticketId: payload })

    if (data?.items) {
      yield put(ticketsActions.saveAvailablePlaces(data.items))
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
