import { useState } from 'react'

import { MessageDispatchTemplate, NMessageToClient, NSuccessMessage } from '@klr/api-connectors'
import { formattingPhoneNumber } from '@klr/shared'

import { MessageDispatchForm } from '../../MessageDispatchForm/MessageDispatchForm'

type Props = {
  templates: MessageDispatchTemplate[]
  phoneNumber: string
  onMessageInfo?: (notifications: NSuccessMessage[]) => void
}

export const FormSendNotification = ({ templates, phoneNumber, onMessageInfo }: Props) => {
  const [messageTemplate, setMessageTemplate] = useState('')

  const messages: NMessageToClient[] = [
    {
      phone: formattingPhoneNumber(phoneNumber),
      ticket_ids: [],
      message: messageTemplate,
    },
  ]

  const successSubmitCallback = (notifications: NSuccessMessage[] | null) => {
    if (notifications?.length) {
      onMessageInfo?.(notifications)
    }
  }

  return (
    <MessageDispatchForm
      templates={templates}
      messages={messages}
      messageTemplate={messageTemplate}
      setMessageTemplate={setMessageTemplate}
      successSubmitCallback={successSubmitCallback}
    />
  )
}
