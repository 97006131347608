import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, List } from '@mui/material'
import { styled } from '@mui/material/styles'

import { BookingTimetable } from '@klr/api-connectors'
import { parseDateTimeString } from '@klr/shared'

import { ListStationItem } from './ListStationItem'

const StyledRoot = styled('div')({
  display: 'flex',
  position: 'relative',
  marginBottom: 20,
})

const StyledDivider = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 28,
  left: 76.5,
  display: 'block',
  boxSizing: 'border-box',
  border: `1px dashed ${theme.palette.action.active}`,
  bottom: 40,
  margin: 0,
  backgroundColor: 'transparent',
  backgroundPosition: '50%',
  backgroundSize: 6,
  height: 'calc(100% - 100px)',
  '@media (min-width:430px)': {
    height: 'calc(100% - 83px)',
  },
}))

type Props = {
  dataSource: BookingTimetable[]
}

export const ListStations = ({ dataSource }: Props) => {
  const { t } = useTranslation()

  const [isShowAll, setShowAll] = useState(false)

  const lengthArray = dataSource.length
  const lastItem = dataSource[lengthArray - 1]

  return (
    <>
      <StyledRoot>
        <StyledDivider />

        <List
          sx={{
            marginBottom: 0,
            paddingLeft: 0,
            overflow: 'hidden',
          }}
        >
          {dataSource.map((item, idx) => {
            if (idx < 1 || isShowAll || item.transfer_point) {
              return (
                <ListStationItem
                  key={idx}
                  dateTime={parseDateTimeString(item.datetime)}
                  city={item.title}
                  station={item.address}
                  isChange={item.transfer_point}
                  isShowDate={idx === 0 || idx === lengthArray - 1}
                />
              )
            }

            return null
          })}

          {!isShowAll && (
            <ListStationItem
              dateTime={parseDateTimeString(lastItem.datetime)}
              city={lastItem.title}
              station={lastItem.address}
              isChange={false}
              isShowDate
            />
          )}
        </List>
      </StyledRoot>

      <Button
        onClick={() => setShowAll((prevState) => !prevState)}
        data-testid={isShowAll ? 'collapse-waypoints' : 'show-waypoints'}
      >
        {isShowAll
          ? t('Common.collapse')
          : `${t('Booking.trip.show_waypoints')} (${lengthArray - 2})`}
      </Button>
    </>
  )
}
