import type { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType, ListType } from '../_types'

import {
  OrderDetail,
  OrderDetailPDF,
  OrderReqCreate,
  OrderReqFetchByPhone,
  OrderReqReturn,
  OrderReqTicketsPdf,
} from './order.models'

export const apiOrdersFetchByPhone = (
  params: OrderReqFetchByPhone
): AxiosPromise<ListType<OrderDetail>> => {
  return axios.get(`/orders`, { params })
}

export const apiOrdersFetchDetail = (
  orderId: OrderDetail['id']
): AxiosPromise<DetailType<OrderDetail>> => {
  return axios.get(`/orders/${orderId}`)
}

// Створити замовлення
export const apiOrdersCreate = (data: OrderReqCreate): AxiosPromise<DetailType<OrderDetail>> => {
  return axios.post('/orders', data)
}

// Видалити замовлення
export const apiOrdersDelete = (orderId: OrderDetail['id']): AxiosPromise => {
  return axios.delete(`/orders/${orderId}`)
}

// Оновити час резервації для замовлення
export const apiOrdersRefreshReservation = (
  orderId: OrderDetail['id']
): AxiosPromise<DetailType<OrderDetail>> => {
  return axios.put(`/orders/${orderId}/reservation`)
}

// Повернути замовлення за відсотком
export const apiOrdersReturn = ({
  orderId,
  data,
}: OrderReqReturn): AxiosPromise<DetailType<OrderDetail>> => {
  return axios.post(`/orders/${orderId}/return`, data)
}

// Оплатите замовлення
export const apiOrdersPayOrder = (orderId: OrderDetail['id']): AxiosPromise => {
  return axios.post(`/orders/${orderId}/pay`)
}

export const apiOrdersGetPdfFile = (
  orderId: OrderDetail['id']
): AxiosPromise<DetailType<OrderDetailPDF>> => {
  return axios.get(`/orders/${orderId}/pdf`)
}

// Скасувати резервацію
export const apiOrdersCancelReservation = (
  orderId: OrderDetail['id']
): AxiosPromise<DetailType<OrderDetail>> => {
  return axios.put(`/orders/${orderId}/cancel`)
}

export const apiOrdersTicketsGetPdfFile = ({
  orderId,
  data,
}: OrderReqTicketsPdf): AxiosPromise<DetailType<OrderDetailPDF>> => {
  return axios.post(`/orders/${orderId}/tickets/pdf`, data)
}
