import { useCallback, useEffect, useState } from 'react'
import { Collapse } from '@mui/material'

import { usePathname } from '@klr/shared'

import { NavListProps, NavSubListProps } from '../../../../shared/types'
import { useActiveSubLink } from '../../../../shared/use-active-sub-link'

import { NavItem } from './nav-item'

export const NavList = ({ data, depth, slotProps }: NavListProps) => {
  const pathname = usePathname()
  const active = useActiveSubLink(data, pathname)

  const [openMenu, setOpenMenu] = useState(false)

  useEffect(() => {
    setOpenMenu(active)
  }, [active])

  const handleToggleMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu((prev) => !prev)
    }
  }, [data.children])

  return (
    <>
      <NavItem
        open={openMenu}
        onClick={handleToggleMenu}
        //
        title={data.title}
        path={data.path}
        icon={data.icon}
        caption={data.caption}
        //
        depth={depth}
        hasChild={!!data.children}
        //
        active={active}
        className={active ? 'active' : ''}
        sx={{
          ...(depth === 1 ? slotProps?.rootItem : slotProps?.subItem),
        }}
      />

      {!!data.children && (
        <Collapse in={openMenu} unmountOnExit>
          <NavSubList data={data.children} depth={depth} slotProps={slotProps} />
        </Collapse>
      )}
    </>
  )
}

const NavSubList = ({ data, depth, slotProps }: NavSubListProps) => (
  <>
    {data.map((list) => (
      <NavList key={list.title} data={list} depth={depth + 1} slotProps={slotProps} />
    ))}
  </>
)
