import { AxiosPromise } from 'axios'

import { axios } from '../../_axios'
import { DetailType, ListType } from '../../_types'
import { BusResPlaces } from '../../carriers'
import {
  TicketAvailableSeat,
  TicketDetail,
  TicketReqAvailableSeats,
  TicketReqFetchSeatsForTransfer,
  TicketReqTransfer,
  TicketReqTransferMultiple,
  TicketResTransferToTransport,
} from '../models'

// Отримати вільні місця в транспорті
export const apiTicketsFetchAvailableSeats = ({
  ticketId,
  with_place_category,
}: TicketReqAvailableSeats): AxiosPromise<ListType<TicketAvailableSeat>> => {
  return axios.get(`/tickets/${ticketId}/available-seats-current-transport`, {
    params: {
      with_place_category,
    },
  })
}

// Отримати доступні місця для пересадки на іншу дату
export const apiTicketsFetchSeatsTransferToDate = ({
  ticketId,
  date,
}: TicketReqFetchSeatsForTransfer): AxiosPromise<ListType<BusResPlaces>> => {
  return axios({
    url: `/tickets/${ticketId}/available-seats-transfer-to-date`,
    method: 'get',
    params: {
      date,
    },
  })
}

// Отримати доступні місця для пересадки на інший транспорт
export const apiTicketsFetchSeatsTransferToTransport = (id: TicketDetail['id']): AxiosPromise => {
  return axios({
    url: `/tickets/${id}/available-seats-transfer-to-transport`,
    method: 'get',
  })
}

// Перенесення квитка на інший транспорт(в рамках 1 поїздки)
export const apiTicketsTransferToTransport = ({ id, data }: TicketReqTransfer): AxiosPromise => {
  return axios({
    url: `/tickets/${id}/transfer-to-transport`,
    method: 'post',
    data,
  })
}

// Отримати доступні місця для пересадки на інші транспорти (той самий маршрут)
export const apiTicketsFetchAvailableSeatsToTransfer = (
  ticket_ids: TicketDetail['id'][]
): AxiosPromise<ListType<BusResPlaces>> => {
  return axios.post(`/tickets/available-seats-transfer-to-transport/bulk`, { ticket_ids })
}

// Перенесення квитка на іншу дату (той самий маршрут)
export const apiTicketsTransferToDate = ({
  id,
  data,
}: TicketReqTransfer): AxiosPromise<DetailType<TicketDetail>> => {
  return axios({
    url: `/tickets/${id}/transfer-to-date`,
    method: 'post',
    data,
  })
}

// Перенесення квитків на інші транспорти (той самий маршрут)
export const apiTicketsMultipleTransferToTransport = (
  data: TicketReqTransferMultiple[]
): AxiosPromise<ListType<TicketResTransferToTransport>> => {
  return axios.post(`/tickets/transfer-to-transport/bulk`, {
    transfers: data,
  })
}
