import { Add as AddIcon, LabelOutlined as CreateLabelIcon } from '@mui/icons-material'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { apiCustomerTagsFetchList, customerQueryKeys } from '@klr/api-connectors'
import { usePopover } from '@klr/shared'

import { useCustomerUIStore } from '../../../../../../../../store'

import { CustomerTagItem } from './components/CustomerTagItem'
import { TagListPopover } from './components/TagListPopover'

export const CustomerTagList = () => {
  const popover = usePopover()
  const customerId = useCustomerUIStore((state) => state.customerId)

  const { data: customerTagList } = useQuery({
    queryKey: customerQueryKeys.tags(customerId),
    queryFn: async () => {
      const {
        data: { data },
      } = await apiCustomerTagsFetchList(customerId!)

      return data
    },
    enabled: !!customerId,
  })

  if (!customerTagList?.length) {
    return (
      <Box>
        <Button startIcon={<CreateLabelIcon />} variant="outlined" onClick={popover.onOpen}>
          Додати тег
        </Button>
        <TagListPopover customerTagIds={[]} open={popover.open} onClose={popover.onClose} />
      </Box>
    )
  }

  return (
    <Box>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {customerTagList.map((item, index, array) => (
          <Stack key={item.id} direction="row" spacing={1} alignItems="center">
            <CustomerTagItem item={item} onEdit={popover.onOpen} />
            {index === array.length - 1 && (
              <IconButton size="small" onClick={popover.onOpen}>
                <AddIcon fontSize={'small'} />
              </IconButton>
            )}
          </Stack>
        ))}
      </Stack>
      <TagListPopover
        customerTagIds={customerTagList.map((item) => item.id)}
        open={popover.open}
        onClose={popover.onClose}
      />
    </Box>
  )
}
