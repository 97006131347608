import { toast } from 'react-hot-toast'
import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import { apiTripsDeleteTrip } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { uiActions } from '../../../ui'
import { tripsActions } from '../../index'
import { DeleteAsync } from '../../types'

export function* deleteTrip({ payload }: DeleteAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    // Api call or some async actions
    const { status } = yield call(apiTripsDeleteTrip, payload)

    if (status === 204) {
      yield put(tripsActions.deleteItem(payload))

      toast.success('Успішно!')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
