import * as React from 'react'

type TabPanelProps = {
  children?: React.ReactNode
  currentTab: number | string | false
  value: number | string
  className?: string
}

export const TabPanel = ({ children, currentTab, value, className, ...other }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={currentTab !== value}
    id={`scrollable-auto-tabpanel-${value}`}
    aria-labelledby={`scrollable-auto-tab-${value}`}
    className={className}
    {...other}
  >
    {currentTab === value && children}
  </div>
)
