import { toast } from 'react-hot-toast'
import { AxiosResponse } from 'axios'
import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import {
  apiTripTransportCreate,
  apiTripTransportFetchList,
  apiTripTransportsChangeInfo,
  apiTripTransportsDelete,
  TripTransportResFetchDetail,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { tripsActions } from '../../index'
import type {
  ChangeTransportAsync,
  CreateTransportAsync,
  DeleteTransportAsync,
  FetchItemsTransportAsync,
} from '../../types'

export function* fetchTransport(action: FetchItemsTransportAsync): SagaIterator {
  try {
    yield put(tripsActions.toggleLoadingTransports(true))

    // Api call or some async actions
    const { data }: AxiosResponse = yield call(apiTripTransportFetchList, action.payload)

    if (data) {
      yield put(tripsActions.saveTransportItems(data))
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(tripsActions.toggleLoadingTransports(false))
  }
}

/**
 * Зміна інформацію про транспорт (затвердження автобуса на рейс)
 *
 * @param action
 */
export function* updateTransportInfoWorker({ payload }: ChangeTransportAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    // Api call or some async actions
    const { data }: AxiosResponse<TripTransportResFetchDetail> = yield call(
      apiTripTransportsChangeInfo,
      payload.request
    )

    if (data) {
      if (payload.isSheet) {
        yield put(sheetsActions.changeTransportInfo(data.item))
      }

      yield put(uiActions.closeDetailModal())

      toast.success('Успішно!')
    }
  } catch (error) {
    handleBackendError(error, 'Trip')
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}

export function* createTransportWorker(action: CreateTransportAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    // Api call or some async actions
    const { data } = yield call(apiTripTransportCreate, action.payload)

    if (data) {
      yield put(tripsActions.addTransportItem(data.item))

      toast.success('Успішно!')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}

export function* deleteTransport({ payload }: DeleteTransportAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    // Api call or some async actions
    const { status } = yield call(apiTripTransportsDelete, payload)

    if (status === 204) {
      yield put(tripsActions.deleteTransportItem(payload.transport_id))

      toast.success('Успішно!')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
