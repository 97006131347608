import { toast } from 'react-hot-toast'
import type { SagaIterator } from '@redux-saga/core'
import type { AxiosResponse } from 'axios'
import { isAxiosError } from 'axios'
import { call, put } from 'redux-saga/effects'

import { apiTripsCreateItem } from '@klr/api-connectors'
import { ERROR_MAIN } from '@klr/shared'

import { uiActions } from '../../../ui'
import { tripsActions } from '../../index'
import type { CreateAsync } from '../../types'

export function* createItem(action: CreateAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))
    const { data }: AxiosResponse = yield call(apiTripsCreateItem, action.payload)

    if (data) {
      yield put(tripsActions.createItem(data.item))

      toast.success('Успішно!')
    }
  } catch (error) {
    let message = ERROR_MAIN

    if (isAxiosError(error) && error.response?.status === 400) {
      message = 'Не можливо створити рейс!'
    }
    toast.error(message)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
