import { PayloadAction } from '@reduxjs/toolkit'

import { TripSegmentPlace } from '@klr/api-connectors'

import { SheetsState } from '../sheetState'

export const segmentPlacesReducers = {
  saveSegmentPlaces: (state: SheetsState, action: PayloadAction<TripSegmentPlace>) => {
    const index = state.availableSegmentPlaces.findIndex((item) => {
      return (
        item.from_station_id === action.payload.from_station_id &&
        item.to_station_id === action.payload.to_station_id
      )
    })

    if (index === -1) {
      state.availableSegmentPlaces.push(action.payload)
    } else {
      state.availableSegmentPlaces[index] = action.payload
    }
  },

  clearSegmentPlaces: (state: SheetsState) => {
    state.availableSegmentPlaces = []
  },
}
