import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params'

import { apiSheetsFetchSheets, SheetData } from '@klr/api-connectors'
import { formatToDateString } from '@klr/shared'

import { sheetsActions } from '../slice'

import { useSheetSettings } from './context'

const REFRESH_TIME = 10000

export const useQueryFetchSheet = (routeId: number | null) => {
  const dispatch = useDispatch()
  const { settings } = useSheetSettings()
  const [queryDate] = useQueryParam('date', withDefault(StringParam, undefined))
  const [currentBus] = useQueryParam('current-bus', withDefault(NumberParam, undefined))

  const { isLoading, isFetching, data, refetch, dataUpdatedAt } = useQuery<SheetData>(
    ['sheet', queryDate, routeId, settings?.isReturnedTickets],
    async () => {
      const response = await apiSheetsFetchSheets({
        departure_date: queryDate || formatToDateString(new Date()),
        route_id: routeId as number,
        is_returned_tickets: settings?.isReturnedTickets ? 1 : 0,
      })

      const sheetData = response.data.item

      dispatch(
        sheetsActions.saveSheet({
          ...sheetData,
          currentTransportIndex: currentBus,
        })
      )

      return sheetData
    },
    {
      enabled: !!routeId,
      refetchInterval: settings?.isAutoUpdate
        ? REFRESH_TIME + Math.floor(Math.random() * REFRESH_TIME)
        : false,
      refetchOnWindowFocus: settings?.isAutoUpdate ?? true,
      staleTime: 10000,
    }
  )

  return {
    dataUpdatedAt,
    isFetching,
    isLoading,
    refetch,
    data,
  }
}
