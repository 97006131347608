import {
  Calendar as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Clock as ClockIcon,
  CreditCard as CreditCardIcon,
  DollarSign as DollarSignIcon,
  Headphones as HeadphonesIcon,
  Loader as LoaderIcon,
  Repeat as RepeatIcon,
  RotateCcw as RotateCcwIcon,
  UserCheck as UserCheckIcon,
  X as XIcon,
} from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Chip, chipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

import { StatusTicketEnum } from '@klr/api-connectors'

type StyledChipProps = {
  status: 'waiting' | 'info' | 'reject' | 'success'
}

const StyledChip = styled(Chip)<StyledChipProps>(({ theme, status }) => ({
  padding: '3px 6px',

  ...(status === 'success' && {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`,
  }),

  ...(status === 'waiting' && {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`,
  }),

  ...(status === 'reject' && {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.main}`,
  }),

  ...(status === 'info' && {
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  }),

  [`& .${chipClasses.iconSmall}`]: {
    width: 12,
    height: 12,
    color: 'inherit',
  },
}))

interface ChipStatusProps {
  status: StatusTicketEnum
}

export const TicketChipStatus = ({ status }: ChipStatusProps) => {
  const { t } = useTranslation()

  const getStatusesConfig = {
    deferred: {
      label: t('Statuses.deferred'),
      icon: ClockIcon,
      status: 'waiting',
    },
    reserved: {
      label: t('Statuses.reserved'),
      icon: UserCheckIcon,
      status: 'info',
    },
    reserved_pending: {
      label: t('Statuses.reserved_pending'),
      icon: UserCheckIcon,
      status: 'waiting',
    },
    cancel: {
      label: t('Statuses.cancelled'),
      icon: RotateCcwIcon,
      status: 'reject',
    },
    changed: {
      label: t('Statuses.changed'),
      icon: RepeatIcon,
      status: 'waiting',
    },
    return: {
      label: t('Statuses.return'),
      icon: RotateCcwIcon,
      status: 'reject',
    },
    paid: {
      label: t('Statuses.paid'),
      icon: CreditCardIcon,
      status: 'success',
    },
    confirm: {
      label: t('Statuses.confirm'),
      icon: CheckCircleIcon,
      status: 'success',
    },
    cash: {
      label: t('Statuses.cash'),
      icon: DollarSignIcon,
      status: 'success',
    },
    cash_disp: {
      label: t('Statuses.cash_disp'),
      icon: HeadphonesIcon,
      status: 'success',
    },
    cash_reserved: {
      label: t('Statuses.payment_to_driver'),
      icon: DollarSignIcon,
      status: 'success',
    },
    pending: {
      label: t('Statuses.pending'),
      icon: LoaderIcon,
      status: 'waiting',
    },
    payment_failure: {
      label: t('Statuses.payment_failure'),
      icon: XIcon,
      status: 'reject',
    },
    opendate: {
      label: t('Statuses.opendate'),
      icon: CalendarIcon,
      status: 'waiting',
    },
  }

  const value = getStatusesConfig[status as keyof typeof getStatusesConfig]

  if (!value) {
    return null
  }

  const Icon = value.icon

  return (
    <StyledChip
      size="small"
      variant="outlined"
      icon={<Icon />}
      label={value.label}
      status={value.status as StyledChipProps['status']}
    />
  )
}
