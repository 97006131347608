import { PayloadAction } from '@reduxjs/toolkit'

import {
  SheetPlace,
  SheetPlaceEmpty,
  SheetPlaceTransfer,
  SheetTransferTransport,
  SheetTransport,
  TicketDetail,
} from '@klr/api-connectors'

import { getAvailablePlace, isPlaceNotEmpty } from '../helpers'
import { SheetsState } from '../sheetState'

type PropsTransferTransportTicket = {
  place: SheetPlaceTransfer
  ticket: TicketDetail
  newTransportId: number
  oldTransportId: number
  oldTripId: number
  isChangeDate: boolean
}

export const transferTransportReducers = {
  /**
   * Зміна стора при перенсененні квитка на інший транспорт
   *
   * @param state
   * @param action {
   * place - потрібен для вставки в другий транспорт PlaceTransfer (звідки потрібно видалити SubList...)
   * ticket - відповідь серевера потрібен для отримання нового trip і нового місця для пасажира
   * newTransportId - в який транспорт потрібно добавити пасажира
   * oldTransportId - з якого транспорта потрібно видалити пасажира
   * oldTripId - з якого trip потрібно видалити пасажира
   * isChangeDate - чи змінюється дата, а не транспорт
   * }
   */
  transferTransportTicket: (
    state: SheetsState,
    action: PayloadAction<PropsTransferTransportTicket>
  ) => {
    const { isChangeDate, place, ticket, newTransportId, oldTransportId, oldTripId } =
      action.payload

    // отримання индекса для фильтрации масивов откуда уберается место
    const idx = state.transports.findIndex(
      (t: SheetTransport) => t.id === oldTransportId && t.trip.id === oldTripId
    )

    // отримання индекса для добавление в другой транспорт
    const idxTransfer = state.transports.findIndex(
      (t: SheetTransport) => t.id === newTransportId && t.trip.id === ticket.trip?.id
    )

    if (place.isConflict) {
      state.transports[idx].conflicted =
        state.transports[idx].conflicted?.filter((p) => p.ticket_id !== place.ticket_id) || null
    } else if (place.isSubList) {
      state.transports[idx].sub_sheet =
        state.transports[idx].sub_sheet?.filter((p) => p.ticket_id !== place.ticket_id) || null
    } else {
      state.transports[idx].places = state.transports[idx].places.map((p) =>
        isPlaceNotEmpty(p) && p.ticket_id === place.ticket_id ? getAvailablePlace(place.place) : p
      )

      if (typeof state.transports[idx].free_places === 'number') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.transports[idx].free_places--
      }
    }

    if (!isChangeDate) {
      state.transports[idxTransfer].places = state.transports[idxTransfer].places.map(
        (p: SheetPlace | SheetPlaceEmpty) => {
          return p.place === action.payload.ticket.place
            ? {
                ...action.payload.place,
                place: action.payload.ticket.place,
              }
            : p
        }
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.transports[idxTransfer].free_places = state.transports[idxTransfer].free_places++
    }
  },
  /**
   * Збереження можливих транспортів для перенесення квитка
   *
   * @param state
   * @param action
   */
  saveTransferTransport: (state: SheetsState, action: PayloadAction<SheetTransferTransport[]>) => {
    state.transferTransport = action.payload
  },
}
