// #region Global Imports
// LIBS
import { createRouterReducer, ReduxRouterState } from '@lagunovsky/redux-react-router'
import { combineReducers } from '@reduxjs/toolkit'
import { History } from 'history'

import { accountReducer, AccountState } from '@klr/auth'

// #region Entities Modules
import { sheetsReducer } from '../../_modules_/sheets'
import { ticketsReducer } from '../../_modules_/tickets'
import { tripsReducer } from '../../_modules_/trips'
// #region Local Imports
import { uiReducer } from '../../_modules_/ui'
import { UiState } from '../../_modules_/ui/types'

const entitiesReducer = combineReducers({
  sheets: sheetsReducer,
  tickets: ticketsReducer,
  trips: tripsReducer,
})

const rootReducer = (history: History) =>
  combineReducers({
    account: accountReducer,
    entities: entitiesReducer,
    router: createRouterReducer(history),
    ui: uiReducer,
  })

export type EntitiesState = ReturnType<typeof entitiesReducer>
export type RootState = {
  account: AccountState
  entities: EntitiesState
  router: ReduxRouterState
  ui: UiState
}
export default rootReducer
