import React, { ReactNode } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { LicenseInfo } from '@mui/x-data-grid-premium'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfirmProvider } from 'material-ui-confirm'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import './global.css'
import 'nprogress/nprogress.css'

import { ScrollReset } from './components/Core'
import { SettingsProvider } from './contexts/SettingsContext'
import { ThemeLayout } from './contexts/SettingsContext/types'
import { ThemeProvider } from './theme'
import { handleBackendError } from './utils'

LicenseInfo.setLicenseKey(import.meta.env.VITE_LICENSE_KEY_X_GRID)

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.errorMessage) {
        toast.error(query.meta.errorMessage as string)
        return
      }

      handleBackendError(error, query.meta?.module as string | undefined)
    },
    onSuccess: (_, query) => {
      if (query.meta?.successMessage) {
        toast.success(query.meta.successMessage as string)
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
})

interface Props {
  children: ReactNode
  themeLayout: ThemeLayout
}
export const SharedProvider = ({ children, themeLayout }: Props) => {
  const { t } = useTranslation()

  return (
    <HelmetProvider>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider themeLayout={themeLayout}>
            <ThemeProvider>
              <ConfirmProvider
                defaultOptions={{
                  confirmationText: t('Common.confirm'),
                  cancellationText: t('Common.cancel'),
                  title: t('Common.are_you_sure'),
                  confirmationButtonProps: {
                    variant: 'contained',
                    autoFocus: true,
                  },
                }}
              >
                <ScrollReset />
                {children}
                <ReactQueryDevtools initialIsOpen={false} />
              </ConfirmProvider>
            </ThemeProvider>
          </SettingsProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </HelmetProvider>
  )
}
