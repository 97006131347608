import { HTMLAttributes, ReactNode, SyntheticEvent, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Autocomplete, TextField } from '@mui/material'

import { TripStation } from '@klr/api-connectors'
import { replaceQwerty } from '@klr/shared'

type Props = {
  name: string
  label: string
  defaultStation: TripStation | null
  stationsTrip: TripStation[]
  onChangeProps?: (val: TripStation | null) => void
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: TripStation) => ReactNode
}

export const StationAutocomplete = ({
  name,
  label,
  defaultStation,
  stationsTrip,
  onChangeProps,
  renderOption,
}: Props) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <Controller
      name={name}
      defaultValue={defaultStation}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete<TripStation, false, true>
          {...field}
          autoHighlight
          openOnFocus
          disableClearable
          noOptionsText="Не знайдено станцію"
          options={stationsTrip}
          getOptionLabel={(option) => option.city}
          groupBy={(option) => option.country}
          getOptionDisabled={(option) => !!option.is_disabled}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(replaceQwerty(newInputValue))
          }}
          renderOption={renderOption}
          onChange={(_: SyntheticEvent, data: TripStation | null) => {
            field.onChange(data)

            if (onChangeProps) {
              onChangeProps(data)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required
              fullWidth
              inputRef={ref}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      )}
    />
  )
}
