import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { PhoneCall } from 'react-feather'
import { useSessionStorage, useToggle } from 'react-use'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { Box, Button, IconButton, Popover, SvgIcon, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

import { NSuccessMessage, SheetBinotelMessage, SheetBinotelMessageCall } from '@klr/api-connectors'
import { Fallback } from '@klr/shared'

import { environments } from '../environments'

import { ContentPopover } from './atoms/ContentPopover'

interface StyledIconProps {
  isOnConnect: boolean
  havePhones: boolean
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  [`& .MuiPopover-paper`]: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
}))

const StyledSvgIcon = styled(SvgIcon, {
  shouldForwardProp: (prop) => prop !== 'isOnConnect' && prop !== 'havePhones',
})<StyledIconProps>(({ theme, isOnConnect, havePhones }) => {
  if (!isOnConnect) {
    return {
      color: theme.palette.warning.light,
    }
  }

  if (havePhones) {
    return {
      color: theme.palette.success.light,
    }
  }

  return null
})

const socketUrl = environments.binotelSocketUrl

interface CallListProps {
  onMessageInfo?(notifications: NSuccessMessage[]): void
  onShowSearchCustomer?(payload: string): void
  onShowSearchPassenger(payload: string): void
}

export const CallList = memo(
  ({ onMessageInfo, onShowSearchCustomer, onShowSearchPassenger }: CallListProps) => {
    const ref = useRef<HTMLButtonElement>(null)
    const [isOpen, toggle] = useToggle(false)

    const [phones, setPhones] = useState<{
      [key: string]: SheetBinotelMessageCall
    } | null>(null)

    const [isOnConnect, setConnect] = useSessionStorage('ip-atc-connect_isShow', false)

    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
      socketUrl || null,
      {},
      isOnConnect
    )

    const _setData = useCallback((msg: SheetBinotelMessage) => {
      if (msg.eventName !== 'callStop') {
        setPhones((prevState) => ({
          ...prevState,
          [msg.generalCallID]: {
            eventName: msg.eventName,
            generalCallID: msg.generalCallID,
            externalNumber: msg.externalNumber,
            customerName: msg.customerName,
            internalNumber: msg.eventName === 'callAnswer' ? msg.internalNumber : null,
            disposition: null,
            updateAt: msg.eventName === 'callAnswer' ? msg.answeredAt : msg.startTime,
          },
        }))
      } else {
        setPhones((prevState) =>
          prevState && msg.generalCallID in prevState
            ? {
                ...prevState,
                [msg.generalCallID]: {
                  ...prevState[msg.generalCallID],
                  eventName: msg.eventName,
                  disposition: msg.disposition,
                  updateAt: msg.stopTime,
                },
              }
            : prevState
        )
      }
    }, [])

    useEffect(() => {
      if (lastJsonMessage) {
        if (lastJsonMessage?.status === 'Connected to Binotel WebSocket. Please, authorise!') {
          sendJsonMessage({
            task: 'authLikeService',
            key: environments.binotelKey,
            secret: environments.binotelSecret,
          })
        }

        if (lastJsonMessage?.generalCallID) {
          _setData(lastJsonMessage)
        }
      }
    }, [_setData, lastJsonMessage, sendJsonMessage])

    return (
      <>
        <Tooltip title="Телефонія">
          <IconButton onClick={toggle} ref={ref} size="large">
            <StyledSvgIcon fontSize="small" isOnConnect={isOnConnect} havePhones={!!phones}>
              <PhoneCall />
            </StyledSvgIcon>
          </IconButton>
        </Tooltip>
        <StyledPopover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          anchorEl={ref.current}
          onClose={toggle}
          open={isOpen}
        >
          {!isOnConnect ? (
            <Box sx={{ p: 2 }}>
              <Button fullWidth variant="outlined" onClick={() => setConnect(true)}>
                Підключити АТС
              </Button>
            </Box>
          ) : readyState === ReadyState.CONNECTING ? (
            <Fallback />
          ) : (
            <ContentPopover
              phones={phones}
              onMessageInfo={onMessageInfo}
              onShowSearchCustomer={onShowSearchCustomer}
              onShowSearchPassenger={onShowSearchPassenger}
            />
          )}
        </StyledPopover>
      </>
    )
  }
)
