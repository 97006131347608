import { useTranslation } from 'react-i18next'
import { Box, Grid, ListItemIcon, ListItemText, Stack, SvgIcon, Tooltip } from '@mui/material'

import { OrderDetail, TicketDetail } from '@klr/api-connectors'
import { Dot } from '@klr/shared'

import { StyledIcon, StyledListItemButton } from '../orders.styles'
import { StatusIconDataType } from '../orders-statuses'
import { getStatusColor } from '../tickets.utils'

import { OrderItemInfo } from './OrderItemInfo'

interface OrderItemProps {
  order: OrderDetail
  statusIconData: StatusIconDataType | undefined
  onClickHandler(id: OrderDetail['id']): void
}

const OrderStatusIcon = ({ title, icon: Icon, color }: StatusIconDataType) => (
  <ListItemIcon>
    <Tooltip title={title}>
      <SvgIcon color={color}>
        <Icon />
      </SvgIcon>
    </Tooltip>
  </ListItemIcon>
)

const TicketStatusList = ({ ticketList }: { ticketList: TicketDetail[] }) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" justifyContent="flex-end" sx={{ flexShrink: 0 }}>
      {ticketList.map((ticket) => {
        const { color, title } = getStatusColor(ticket.status)

        return (
          <Tooltip title={t(`Statuses.${title}`)} key={ticket.id}>
            <SvgIcon color={color} sx={{ width: 18, height: 18 }}>
              <Dot />
            </SvgIcon>
          </Tooltip>
        )
      })}
    </Stack>
  )
}

export const OrderItem = ({ order, statusIconData, onClickHandler }: OrderItemProps) => {
  const { t } = useTranslation()

  return (
    <StyledListItemButton key={order.id} onClick={() => onClickHandler(order.id)}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          {statusIconData && <Box py={0.5}>{<OrderStatusIcon {...statusIconData} />}</Box>}
        </Grid>

        <Grid item xs={7}>
          <ListItemText
            inset={!statusIconData}
            primary={<OrderItemInfo order={order} inGrid variant="body2" />}
          />
        </Grid>

        {order.tickets.length > 1 && (
          <Tooltip title={t('Orders.ticket', { count: order.tickets.length })}>
            <StyledIcon>{order.tickets.length}</StyledIcon>
          </Tooltip>
        )}

        <Grid item xs={3}>
          <TicketStatusList ticketList={order.tickets} />
        </Grid>
      </Grid>
    </StyledListItemButton>
  )
}
