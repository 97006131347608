import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isValid } from 'date-fns'

import { apiTicketsPaidReserve, ordersQueryKeys, TicketDetail } from '@klr/api-connectors'
import { formatToFullDateTimeString, handleBackendError } from '@klr/shared'

interface UseActionPaidReserveParams {
  ticketId: TicketDetail['id']
}

interface Variables {
  paidAt: Date
}

export function useActionPaidReserve({ ticketId }: UseActionPaidReserveParams) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ paidAt }: Variables) => {
      const isValidTime = isValid(paidAt)

      if (!isValidTime) {
        throw new Error('Invalid paidAt date')
      }

      const { data } = await apiTicketsPaidReserve({
        ticketId,
        paidAt: formatToFullDateTimeString(paidAt),
      })

      return data.item
    },
    mutationKey: ['tickets', 'paid-reserve'],
    onError: (error) => {
      handleBackendError(error)
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(ordersQueryKeys.detail(res.order_id)).then(() => {
        toast.success(t('Statuses.success'))
      })
    },
  })
}
