import CallEndIcon from '@mui/icons-material/CallEnd'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import { Tooltip } from '@mui/material'

import { SheetBinotelMessageCall } from '@klr/api-connectors'

interface IconStatusCallProp {
  eventName: SheetBinotelMessageCall['eventName']
  disposition: SheetBinotelMessageCall['disposition']
}

export const IconStatusCall = ({ eventName, disposition }: IconStatusCallProp) => {
  const isNoAnswer = disposition === 'NOANSWER'
  const isCancel = disposition === 'CANCEL'

  switch (eventName) {
    case 'callStart':
      return (
        <Tooltip title="Очікує відповіді">
          <PhoneCallbackIcon fontSize="small" color="info" />
        </Tooltip>
      )
    case 'callAnswer':
      return (
        <Tooltip title="Йде розмова">
          <PhoneInTalkIcon fontSize="small" color="warning" />
        </Tooltip>
      )
    case 'callStop':
      return (
        <Tooltip title={isNoAnswer ? 'Відхилено' : isCancel ? 'Скасовано' : 'Завершено'}>
          <CallEndIcon fontSize="small" color={isNoAnswer || isCancel ? 'error' : 'inherit'} />
        </Tooltip>
      )
    default:
      return null
  }
}
