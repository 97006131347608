const characterReplacer: { [key: string]: string } = {
  q: 'й',
  w: 'ц',
  e: 'у',
  r: 'к',
  t: 'е',
  y: 'н',
  u: 'г',
  i: 'ш',
  o: 'щ',
  p: 'з',
  '[': 'х',
  ']': 'ї',
  a: 'ф',
  s: 'і',
  d: 'в',
  f: 'а',
  g: 'п',
  h: 'р',
  j: 'о',
  k: 'л',
  l: 'д',
  ';': 'ж',
  "'": 'є',
  z: 'я',
  x: 'ч',
  c: 'с',
  v: 'м',
  b: 'и',
  n: 'т',
  m: 'ь',
  ',': 'б',
  '.': 'ю',
  '/': '.',
}

const replaceCharacter = (character: string): string => {
  const lowerCasedChar = character.toLowerCase()

  if (characterReplacer[lowerCasedChar] !== undefined) {
    return character === lowerCasedChar
      ? characterReplacer[lowerCasedChar]
      : characterReplacer[lowerCasedChar].toUpperCase()
  }
  return character
}

export const replaceQwerty = (inputStr: string): string => {
  return Array.from(inputStr).map(replaceCharacter).join('')
}
