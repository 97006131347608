import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { SheetPlace } from '@klr/api-connectors'

import { sheetsActions } from '../slice'

export type THandleTransferToDate = (
  place: SheetPlace,
  isSubList: boolean,
  isConflict: boolean
) => void

export const useTransferToDate = () => {
  const dispatch = useDispatch()

  const handleTransferToDate: THandleTransferToDate = useCallback(
    (place, isSubList = false, isConflict = false): void => {
      dispatch(
        sheetsActions.pushTransferTransportPlace({
          ...place,
          isSubList,
          isConflict,
          isTransferBus: false,
          isTransferDate: true,
        })
      )
    },
    [dispatch]
  )

  return { handleTransferToDate }
}
