import { toast } from 'react-hot-toast'
import { SagaIterator } from '@redux-saga/core'
import { isAxiosError } from 'axios'
import { call, put } from 'redux-saga/effects'

import { apiTicketsTransferToDate, apiTicketsTransferToTransport } from '@klr/api-connectors'
import { ERROR_MAIN } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { TransferAsync } from '../../types'

/**
 * Перенесення квитка на іншу дату або в інший транспорт
 *
 * @param payload
 */
export function* transferWorker({ payload }: TransferAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    let response

    if (payload.request.data.date) {
      response = yield call(apiTicketsTransferToDate, payload.request)
    } else {
      response = yield call(apiTicketsTransferToTransport, payload.request)
    }

    if (response?.data) {
      if (payload.isSheet) {
        yield put(
          sheetsActions.transferTransportTicket({
            place: payload.place,
            ticket: response.data.item,
            newTransportId: payload.request.data.transport_id,
            oldTransportId: payload.oldTransportId,
            oldTripId: payload.oldTripId,
            isChangeDate: !!payload.request.data.date,
          })
        )

        yield put(sheetsActions.clearTransferTransport())
      }

      toast.success(
        payload.request.data.date
          ? 'Квиток перенесено на другу дату'
          : 'Квиток перенесено в другий автобус'
      )
    }
  } catch (error) {
    let message = ERROR_MAIN

    if (isAxiosError(error) && error.response?.status === 400) {
      message = 'Неможливо пересадити пасажира'
    }

    toast.error(message)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
