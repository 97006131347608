import { TFunction } from 'react-i18next'
import * as Yup from 'yup'

import { EMAIL_REG_EXP, PHONE_UNIFIED_REG_EXP } from '../constants'

const MIN_PASSWORD_LENGTH = 8
const MAX_LENGTH = 255

// test coverage
export const validateEmail = (t: TFunction) =>
  Yup.string()
    .trim()
    .max(MAX_LENGTH)
    .required(t('Validations.email_req_message'))
    .matches(EMAIL_REG_EXP, t('Validations.invalid_email'))

export const validatePhone = (t: TFunction) =>
  Yup.string().trim().matches(PHONE_UNIFIED_REG_EXP, t('Validations.invalid_phone'))

export const validateRequiredStringField = (
  t: TFunction,
  max = MAX_LENGTH,
  messageKey = 'Validations.req_field'
) =>
  Yup.string()
    .trim()
    .max(max, t('Validations.max_count_of_chars', { count: max }))
    .required(t(messageKey))

export const validateRequiredUrl = (t: TFunction) =>
  Yup.string()
    .trim()
    .required(t('Validations.req_field'))
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, t('Validations.incorrect_url'))

export const validatePassword = (t: TFunction, min = MIN_PASSWORD_LENGTH, max = MAX_LENGTH) => {
  return Yup.string()
    .trim()
    .min(min, t('Validations.min_count_of_chars', { count: min }))
    .max(max, t('Validations.max_count_of_chars', { count: max }))
    .required(t('Validations.req_field'))
}

export const validatePasswordConfirm = (t: TFunction) => {
  return Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], t('Validations.passwords_not_match'))
    .required(t('Validations.req_field'))
}

// no coverage
export const validateNotRequiredStringField = (t: TFunction, max: number = MAX_LENGTH) =>
  Yup.string()
    .trim()
    .max(max, t('Validations.max_count_of_chars', { count: max }))
    .notRequired()

export const validatePercent = (t: TFunction, min = 0, max = 100) =>
  Yup.number()
    .min(min, t('Validations.min_number', { count: min }))
    .max(max, t('Validations.max_number', { count: max }))
