import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import orderBy from 'lodash/orderBy'

import {
  ListType,
  TripDetail,
  TripItem,
  TripReqChange,
  TripReqChangeStation,
  TripReqCreate,
  TripReqFetchItems,
  TripStation,
  TripTransportReqChangeInfo,
  TripTransportReqChangeStatusPlaces,
  TripTransportReqCreate,
  TripTransportReqDetail,
  TripTransportReqItems,
} from '@klr/api-connectors'
import { parseUkrDateString } from '@klr/shared'

import { transportReducers } from './reducers'
import { TripsActionTypes, TripsState, types } from './types'

const initialState: TripsState = {
  items: [],
  count: 0,
  loading: false,
  stations: [],
  selectedItem: null,
  transports: [],
  countTransports: 0,
  loadingTransports: false,
  loadingTabContent: false,
}

const slice = createSlice({
  name: 'TRIPS',
  initialState,
  reducers: {
    saveItems: (state: TripsState, { payload }: PayloadAction<ListType<TripItem>>) => {
      state.items = orderBy(payload.items, (item) => parseUkrDateString(item.date_start), ['desc'])
      state.count = payload.count
    },
    changeItem: (state: TripsState, { payload }: PayloadAction<TripItem>) => {
      state.items = orderBy(
        state.items.map((item) => {
          if (item.id === payload.id) {
            return payload
          } else {
            return item
          }
        }),
        (item) => parseUkrDateString(item.date_start),
        ['desc']
      )
    },
    selectTrip: (state: TripsState, { payload: trip }: PayloadAction<TripItem>) => {
      if (trip) {
        state.selectedItem = trip
      }
    },
    createItem: (state: TripsState, action: PayloadAction<TripDetail>) => {
      if (action.payload) {
        state.items = [...state.items, action.payload]
      }
    },
    deleteItem: (state: TripsState, { payload }: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== payload)
    },
    toggleLoading: (state: TripsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    toggleLoadingTabContent: (state: TripsState, action: PayloadAction<boolean>) => {
      state.loadingTabContent = action.payload
    },

    saveStations: (state: TripsState, action: PayloadAction<TripStation[]>) => {
      if (action.payload) {
        state.stations = action.payload
      }
    },
    changeStation: (state: TripsState, action: PayloadAction<TripStation[]>) => {
      if (action.payload) {
        state.stations = action.payload
      }
    },
    resetTransportItems: (state: TripsState) => {
      state.transports = []
      state.countTransports = 0
    },
    resetStations: (state: TripsState) => {
      state.stations = []
    },
    ...transportReducers,
  },
})

export default slice.reducer

// Action Creators Async
export const tripsActions = slice.actions

export const tripsActionsAsync = {
  fetchItemsAsync: (payload: TripReqFetchItems): TripsActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
  createAsync: (payload: TripReqCreate): TripsActionTypes => ({
    type: types.CREATE_ITEM,
    payload,
  }),
  changeAsync: (payload: { isSheet?: boolean; request: TripReqChange }): TripsActionTypes => ({
    type: types.CHANGE_ITEM,
    payload,
  }),
  deleteAsync: (payload: number): TripsActionTypes => ({
    type: types.DELETE_ITEM,
    payload,
  }),
  // Stations
  changeTripStationAsync: (payload: {
    isSheet: boolean
    request: TripReqChangeStation
  }): TripsActionTypes => ({
    type: types.CHANGE_STATIONS,
    payload,
  }),
  // Transport
  fetchTransportItemsAsync: (payload: TripTransportReqItems): TripsActionTypes => ({
    type: types.FETCH_TRANSPORT_ITEMS,
    payload,
  }),
  /**
   * Оновити інформацію по транспорту для рейсу
   *
   * @param payload {
       isSheet: - чи зміни відбуваються в відомості
       tripId: number
       transportId: number
       data: {
          drivers?: інформація по водію
          telegram_id?: можна вписати кастомний телеграм для окремого рейсу
          is_confirmed?: чи видана відомість водію
        }
      }
   */
  changeTransportInfoAsync: (payload: {
    isSheet?: boolean
    request: TripTransportReqChangeInfo
  }): TripsActionTypes => ({
    type: types.CHANGE_TRANSPORT_INFO,
    payload,
  }),
  createTransportAsync: (payload: TripTransportReqCreate): TripsActionTypes => ({
    type: types.CREATE_TRANSPORT,
    payload,
  }),
  deleteTransportAsync: (payload: TripTransportReqDetail): TripsActionTypes => ({
    type: types.DELETE_TRANSPORT,
    payload,
  }),
  changeStatusPlacesAsync: (payload: {
    isSheet?: boolean
    request: TripTransportReqChangeStatusPlaces
  }): TripsActionTypes => ({
    type: types.CHANGE_STATUS_PLACES,
    payload,
  }),
}
