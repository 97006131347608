import React from 'react'

import { useQueryFetchSheet } from '../../hooks'

import type { DataContextValue, SheetDataProviderProps } from './types'

const SheetDataContext = React.createContext<DataContextValue>({
  isLoading: false,
  isFetching: false,
  data: null,
  dataUpdatedAt: 0,
})

export const SheetDataProvider = ({ routeId, children }: SheetDataProviderProps) => {
  const { isLoading, isFetching, data, refetch, dataUpdatedAt } = useQueryFetchSheet(routeId)

  return (
    <SheetDataContext.Provider
      value={{
        isLoading,
        isFetching,
        data: data || null,
        refetch,
        dataUpdatedAt,
      }}
    >
      {children}
    </SheetDataContext.Provider>
  )
}

export default SheetDataContext
