import { PayloadAction } from '@reduxjs/toolkit'

import { TripStation } from '@klr/api-connectors'

import { SheetsState } from '../sheetState'

export const stationReducers = {
  saveStations: (state: SheetsState, action: PayloadAction<TripStation[]>) => {
    if (action.payload) {
      state.stations = action.payload
    }
  },
}
