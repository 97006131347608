import {
  ContentCopyOutlined as CopyIcon,
  DeleteOutlineOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

interface CustomerShortSocialContextMenuProps {
  anchorEl: HTMLElement | null
  hasLink: boolean
  onClose(): void
  onCopy(): void
  onDelete(): void
  onEdit(): void
}

export const CustomerShortSocialContextMenu = ({
  anchorEl,
  hasLink,
  onClose,
  onCopy,
  onDelete,
  onEdit,
}: CustomerShortSocialContextMenuProps) => {
  const handleOnClick = (handler: VoidFunction) => () => {
    handler()
    onClose()
  }

  const menuItems = [
    {
      id: 1,
      icon: EditIcon,
      label: 'Редагувати',
      handler: handleOnClick(onEdit),
      visible: true,
    },
    {
      id: 2,
      icon: CopyIcon,
      label: 'Копіювати',
      handler: handleOnClick(onCopy),
      visible: hasLink,
    },
    {
      id: 3,
      icon: DeleteIcon,
      label: 'Очистити',
      handler: handleOnClick(onDelete),
      visible: hasLink,
    },
  ]

  return (
    <Menu
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    >
      {menuItems.map(({ id, icon: MenuIcon, label, handler, visible }) => {
        if (visible) {
          return (
            <MenuItem key={id} onClick={handler}>
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </MenuItem>
          )
        }

        return null
      })}
    </Menu>
  )
}
