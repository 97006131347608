import { useState } from 'react'
import { Grid, Stack } from '@mui/material'

import { Scrollbar } from '@klr/shared'

import { useCustomerUIStore } from '../../../../store'

import { CustomerDetail } from './components/CustomerDetail'
import { CustomerForm } from './components/CustomerForm'
import { CustomerInfoTabs } from './components/CustomerInfoTabs'
import { CustomerShortOrderList } from './components/CustomerShortOrderList'
import { useInitializeCustomerData } from './useInitializeCustomerData'

interface CustomerCardControllerProps {
  expanded: boolean
}

interface CustomerItemProps {
  isEdit: boolean
  onOpen(): void
  onClose(): void
}

const CustomerItem = ({ isEdit, onOpen, onClose }: CustomerItemProps) => {
  if (isEdit) {
    return <CustomerForm onCloseForm={onClose} />
  }

  return <CustomerDetail onOpenForm={onOpen} />
}

export const CustomerCardContent = ({ expanded }: CustomerCardControllerProps) => {
  const customerId = useCustomerUIStore((state) => state.customerId)

  const [editForm, setEditForm] = useState(!customerId)

  useInitializeCustomerData()

  if (expanded) {
    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4} xl={3}>
          <CustomerItem
            isEdit={editForm}
            onOpen={() => setEditForm(true)}
            onClose={() => setEditForm(false)}
          />
        </Grid>
        <Grid item xs={12} md={8} xl={9}>
          <CustomerInfoTabs />
        </Grid>
      </Grid>
    )
  }

  return (
    <Scrollbar>
      <Stack spacing={2}>
        <CustomerItem
          isEdit={editForm}
          onOpen={() => setEditForm(true)}
          onClose={() => setEditForm(false)}
        />

        <CustomerShortOrderList />
      </Stack>
    </Scrollbar>
  )
}
