import { PlaceCategoryPriceReqFetchList } from './place-category-prices.models'

export const placeCategoryPricesQueryKeys = {
  all: ['placeCategoryPrices'] as const,
  list: (params: PlaceCategoryPriceReqFetchList) =>
    [...placeCategoryPricesQueryKeys.all, params] as const,
}

export type PlaceCategoryPricesQueryKeys = {
  all: readonly ['placeCategoryPrices']
  list: readonly [...PlaceCategoryPricesQueryKeys['all'], PlaceCategoryPriceReqFetchList]
}
