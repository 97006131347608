import { PayloadAction } from '@reduxjs/toolkit'

import { SheetPlace, SheetTransport, TicketDetail } from '@klr/api-connectors'

import { generateSheetPlace } from '../helpers'
import { SheetsState } from '../sheetState'

export const createTicket = (state: SheetsState, { payload }: PayloadAction<TicketDetail>) => {
  const place: SheetPlace = generateSheetPlace(payload)

  if (payload.children) {
    payload.children.forEach((child) => {
      state.transports = (state.transports as SheetTransport[]).map((t) => {
        if (t.id === child.transport_id && t.trip.id === child.trip?.id) {
          return {
            ...t,
            free_places: t.free_places ? t.free_places-- : 0,
            count_of_places: t.count_of_places++,
            places: t.places.map((p) => {
              if (p.place === child.place) {
                return {
                  ...place,
                  ticket_id: child.id,
                }
              }

              return p
            }),
          }
        }
        return t
      })
    })
  } else {
    state.transports = (state.transports as SheetTransport[]).map((t) => {
      if (t.id === payload.transport_id) {
        return {
          ...t,
          places: t.places.map((p) => (p.place === payload.place ? place : p)),
        }
      }

      return t
    })
  }
}
