import { toast } from 'react-hot-toast'
import { SagaIterator } from '@redux-saga/core'
import { call, put } from 'redux-saga/effects'

import { apiTicketsCustomReturnPercent } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { uiActions } from '../../../ui'
import { CustomReturnPercentAsync } from '../../types'

export function* customReturnPercentWorker({ payload }: CustomReturnPercentAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    const response = yield call(apiTicketsCustomReturnPercent, payload)

    if (response.status === 200) {
      toast.success(`Відсоток повернення встановленно`)
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
