import { TFunction } from 'react-i18next'
import * as Yup from 'yup'

import { validateNotRequiredStringField, validatePhone } from '@klr/shared'

export const CustomerDetailSchema = (t: TFunction) =>
  Yup.object().shape({
    phone: validatePhone(t),
    add_phone: Yup.lazy((value) => {
      if (value) {
        return validatePhone(t)
      }

      return Yup.string().notRequired()
    }),
    description: validateNotRequiredStringField(t),
  })

export type CustomerDetailFormFields = Yup.InferType<ReturnType<typeof CustomerDetailSchema>>
