import { toast } from 'react-hot-toast'
import type { SagaIterator } from '@redux-saga/core'
import type { AxiosResponse } from 'axios'
import { call, put } from 'redux-saga/effects'

import {
  apiTripTransportChangeStatusPlaces,
  TripTransportResFetchDetail,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { tripsActions } from '../../index'
import type { ChangeStatusPlacesAsync } from '../../types'

export function* changeStatusPlaces({ payload }: ChangeStatusPlacesAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))
    // Api call or some async actions
    const { data }: AxiosResponse<TripTransportResFetchDetail> = yield call(
      apiTripTransportChangeStatusPlaces,
      payload.request
    )

    if (data) {
      if (payload.isSheet) {
        yield put(sheetsActions.changeStatusPlaces(payload.request))
      }
      yield put(tripsActions.changeStatusPlaces(payload.request))

      toast.success('Успішно 🎉')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
