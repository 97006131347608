import { Stack } from '@mui/material'

import { useCustomerStore } from '../../../../../../../../store'

import { CustomerShortSocialItem } from './components/CustomerShortSocialItem'

export const CustomerShortSocialNetworks = () => {
  const socialListSnippet = useCustomerStore((state) => state.getSocialListSnippet())

  return (
    <Stack direction="row" spacing={2}>
      {socialListSnippet.map(({ icon, id, link, type }) => (
        <CustomerShortSocialItem
          key={type}
          socialIcon={icon}
          socialId={id}
          socialLink={link}
          socialType={type}
        />
      ))}
    </Stack>
  )
}
