import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import { apiTicketsPrintPdf } from '@klr/api-connectors'
import { downloadPDF, handleBackendError } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { PrintPdfTicketAsync } from '../../types'

export function* printPdfTicket({ payload }: PrintPdfTicketAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))
    // Api call or some async actions
    const { data } = yield call(apiTicketsPrintPdf, {
      ticketId: payload.ticketId,
      params: { use_main_pdf: payload.isMainPdf },
    })

    if (data) {
      yield call(downloadPDF, data.item.pdf, `Ticket_KLR_Bus_${payload.ticketId}`)

      if (payload.isSheet) {
        yield put(
          sheetsActions.changePrintAt({
            ticketId: payload.ticketId,
            printAt: data.item.printed_at,
          })
        )
      }
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
