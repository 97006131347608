import { useQuery } from '@tanstack/react-query'
import sortBy from 'lodash/sortBy'

import {
  apiDispatcherFetchRelatedRoutes,
  DispatcherItem,
  dispatcherQueryKeys,
} from '@klr/api-connectors'

export const useFetchDispatcherRoutes = (dispatcherId: DispatcherItem['id'] | null) => {
  return useQuery({
    queryKey: dispatcherQueryKeys.relatedRoutes(dispatcherId!),
    queryFn: async () => {
      const response = await apiDispatcherFetchRelatedRoutes(dispatcherId!)

      return response.data.items
    },
    enabled: !!dispatcherId,
    initialData: [],
    select: (data) =>
      sortBy(
        data.filter((item) => item.is_active),
        (item) => (item.number ? item.number : null)
      ),
  })
}
