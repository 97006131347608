import { memo } from 'react'
import { Stack } from '@mui/material'

import { NavGroupProps, NavProps } from '../../../../../components/shared/types'

import { NavList } from './components/nav-list'

const Group = ({ items, slotProps }: NavGroupProps) => (
  <>
    {items.map(
      (list) => list && <NavList key={list.title} data={list} depth={1} slotProps={slotProps} />
    )}
  </>
)

export const NavSectionMini = memo(({ data, slotProps, ...other }: NavProps) => (
  <Stack component="nav" id="nav-section-mini" spacing="4px" {...other}>
    {data.map((group, index) => (
      <Group key={group.subheader || index} items={group.items} slotProps={slotProps} />
    ))}
  </Stack>
))
