import { useMemo } from 'react'
import { matchPath } from 'react-router-dom'

import { NavItemBaseProps } from './types'

export const useActiveSubLink = (data: NavItemBaseProps, pathname: string) => {
  return useMemo(() => {
    if (data.children) {
      return data.children.some((item) => !!matchPath(item.path, pathname))
    }

    return !!matchPath(data.path, pathname)
  }, [data.children, data.path, pathname])
}
