import { useState } from 'react'
import { CornerDownLeft as ReturnIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Alert, Divider, Grid, Stack, Table, TableBody, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'

import {
  apiTicketsLiqPayReturn,
  TicketLiqPayInfo,
  TicketReturnCondition,
} from '@klr/api-connectors'
import { AppModules, formatCurrency, handleBackendError, TableDetailRow } from '@klr/shared'

import { ReturnCondition } from './ReturnCondition'

type Props = {
  isLoadingHandler: boolean
  price: number
  price_extra: number
  discount: number
  cashbackPayment: number
  handleReturn: VoidFunction
  currencyISO: string
  hasReturnTicket: boolean
  hasReturnedTicket: boolean
  hasLiqpay: boolean
  ticketId: number
  liqpayInfo?: TicketLiqPayInfo
  returnCondition: TicketReturnCondition | null
}

export const TicketPriceInfo = ({
  cashbackPayment,
  price,
  price_extra,
  isLoadingHandler,
  handleReturn,
  currencyISO,
  hasReturnTicket,
  hasReturnedTicket,
  hasLiqpay,
  ticketId,
  liqpayInfo,
  discount,
  returnCondition,
}: Props) => {
  const { t } = useTranslation()

  const [liqpayReturn, setLiqpayReturn] = useState<TicketLiqPayInfo | null>(liqpayInfo || null)

  const { mutate, isLoading: isSendingRequest } = useMutation(apiTicketsLiqPayReturn, {
    onSuccess: (response) => {
      setLiqpayReturn(response.data.item)
    },
    onError: (error) => handleBackendError(error, AppModules.Tickets),
  })

  const returnedPrice = price < 0 ? -price : price

  const handleLiqpayReturn = () => {
    mutate(ticketId)
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {hasLiqpay && !liqpayReturn?.status && (
        <Grid item xs={12}>
          <Alert severity="info">
            <Typography variant="body2">{t('Messages.liqpay_warning')}</Typography>
          </Alert>
        </Grid>
      )}

      <Grid item md={6}>
        {!hasReturnedTicket && returnCondition && (
          <ReturnCondition
            cashbackPayment={cashbackPayment}
            price={price}
            price_extra={price_extra}
            currencyISO={currencyISO}
            discount={discount}
            returnCondition={returnCondition}
          />
        )}

        {hasReturnedTicket && (
          <Table size="small">
            <TableBody>
              <TableDetailRow
                title={t('Orders.returned')}
                description={`${returnedPrice} ${currencyISO}`}
              />
            </TableBody>
          </Table>
        )}
      </Grid>

      <Grid item md={6}>
        {hasReturnTicket && (
          <LoadingButton
            loading={isLoadingHandler}
            variant="outlined"
            onClick={() => handleReturn()}
            startIcon={<ReturnIcon size={16} />}
            fullWidth
          >
            {t('Messages.return_according_to_the_rules')}
          </LoadingButton>
        )}

        {hasLiqpay && !liqpayReturn?.status && (
          <LoadingButton
            loading={isSendingRequest}
            variant="outlined"
            onClick={handleLiqpayReturn}
            startIcon={<ReturnIcon size={16} />}
            fullWidth
          >
            {t('Orders.return_ticket_price')}
          </LoadingButton>
        )}

        {hasLiqpay && liqpayReturn?.status && (
          <Stack spacing={1.5}>
            <Typography variant="body2">{liqpayReturn.description}</Typography>
            <Typography variant="body2">
              {`${t('Orders.return_sum')}: ${formatCurrency(
                liqpayReturn.returned_price,
                currencyISO
              )}`}
            </Typography>
            <Typography variant="body2">{`${t('Orders.return_date')}: ${
              liqpayReturn.updated_at
            }`}</Typography>
          </Stack>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}
