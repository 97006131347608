import {
  TripItem,
  TripReqChange,
  TripReqChangeStation,
  TripReqCreate,
  TripReqFetchItems,
  TripStation,
  TripTransportItem,
  TripTransportReqChangeInfo,
  TripTransportReqChangeStatusPlaces,
  TripTransportReqCreate,
  TripTransportReqDetail,
  TripTransportReqItems,
} from '@klr/api-connectors'

export enum types {
  // Async
  FETCH_ITEMS = 'TRIPS/FETCH_ITEMS',
  CREATE_ITEM = 'TRIPS/CREATE_ITEM',
  CHANGE_ITEM = 'TRIPS/CHANGE_ITEM',
  DELETE_ITEM = 'TRIPS/DELETE_ITEM',
  // Stations
  FETCH_STATIONS = 'TRIPS/FETCH_STATIONS',
  CHANGE_STATIONS = 'TRIPS/CHANGE_STATIONS',
  // Transport
  FETCH_TRANSPORT_DETAIL = 'TRIPS/FETCH_TRANSPORT_DETAIL',
  FETCH_TRANSPORT_ITEMS = 'TRIPS/FETCH_TRANSPORT_ITEMS',
  CHANGE_TRANSPORT_INFO = 'TRIPS/CHANGE_TRANSPORT_INFO',
  CREATE_TRANSPORT = 'TRIPS/CREATE_TRANSPORT',
  DELETE_TRANSPORT = 'TRIPS/DELETE_TRANSPORT',
  CHANGE_STATUS_PLACES = 'TRIPS/CHANGE_STATUS_PLACES',
}

// STATE

export type TripsState = {
  items: TripItem[]
  count: number
  loading: boolean
  stations: TripStation[]
  selectedItem: TripItem | null
  transports: TripTransportItem[]
  countTransports: number
  loadingTransports: boolean
  loadingTabContent: boolean
}

// ASYNC

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS
  payload: TripReqFetchItems
}

export type CreateAsync = {
  type: typeof types.CREATE_ITEM
  payload: TripReqCreate
}

export type ChangeAsync = {
  type: typeof types.CHANGE_ITEM
  payload: {
    request: TripReqChange
    isSheet?: boolean
  }
}

export type DeleteAsync = {
  type: typeof types.DELETE_ITEM
  payload: number
}

/**
 * Stations
 */
export type FetchStationsAsync = {
  type: typeof types.FETCH_STATIONS
  payload: number
}

export type ChangeStationsAsync = {
  type: typeof types.CHANGE_STATIONS
  payload: {
    isSheet: boolean
    request: TripReqChangeStation
  }
}

/**
 * Transport
 */
export type FetchItemsTransportAsync = {
  type: typeof types.FETCH_TRANSPORT_ITEMS
  payload: TripTransportReqItems
}

export type FetchDetailTransportAsync = {
  type: typeof types.FETCH_TRANSPORT_DETAIL
  payload: TripTransportReqDetail
}

export type ChangeTransportAsync = {
  type: typeof types.CHANGE_TRANSPORT_INFO
  payload: {
    isSheet?: boolean
    request: TripTransportReqChangeInfo
  }
}

export type CreateTransportAsync = {
  type: typeof types.CREATE_TRANSPORT
  payload: TripTransportReqCreate
}

export type DeleteTransportAsync = {
  type: typeof types.DELETE_TRANSPORT
  payload: TripTransportReqDetail
}

export type ChangeStatusPlacesAsync = {
  type: typeof types.CHANGE_STATUS_PLACES
  payload: {
    isSheet?: boolean
    request: TripTransportReqChangeStatusPlaces
  }
}

export type TripsActionTypes =
  | FetchItemsAsync
  | CreateAsync
  | ChangeAsync
  | DeleteAsync
  | FetchStationsAsync
  | FetchItemsTransportAsync
  | FetchDetailTransportAsync
  | ChangeStationsAsync
  | ChangeTransportAsync
  | CreateTransportAsync
  | DeleteTransportAsync
  | ChangeStatusPlacesAsync
