enum StatusTicketEnum {
  DEFERRED = 'deferred',
  RESERVED = 'reserved',
  RESERVED_PENDING = 'reserved_pending',
  CANCEL = 'cancel',
  CHANGED = 'changed',
  RETURN = 'return',
  PAID = 'paid',
  CONFIRM = 'confirm',
  CASH = 'cash',
  CASH_DISP = 'cash_disp',
  CASH_RESERVED = 'cash_reserved',
  PENDING = 'pending',
  PAYMENT_FAILURE = 'payment_failure',
  OPENDATE = 'opendate',
  SOLD = 'sold',
}

// TODO: move this function to the Ticket lib
export const hasConfirmTicket = (status: StatusTicketEnum, canReserve: boolean) => {
  // Check if the ticket can be reserved and is either cash or sold,
  // or if the ticket is already reserved.
  switch (status) {
    case StatusTicketEnum.RESERVED:
      return true
    case StatusTicketEnum.CASH:
    case StatusTicketEnum.SOLD:
      return canReserve
    default:
      return false
  }
}
