import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff'
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material'

export const OnlineStatus = () => {
  const { t } = useTranslation()

  const [isOnline, setIsOnline] = useState(true)

  const goOnline = useCallback(() => {
    setIsOnline(true)

    toast.success(t('Internet.connection_restored'))
  }, [t])

  const goOffline = useCallback(() => {
    setIsOnline(false)

    toast.error(t('Internet.no_connection'))
  }, [t])

  useEffect(() => {
    window.addEventListener('online', goOnline)
    window.addEventListener('offline', goOffline)

    return () => {
      window.removeEventListener('online', goOnline)
      window.removeEventListener('offline', goOffline)
    }
  }, [goOffline, goOnline])

  if (isOnline) return null

  return (
    <Box ml={1}>
      <Tooltip
        title={t('Internet.not_connected_to_the_internet_please_reconnect')}
        placement="bottom"
      >
        <IconButton size="large">
          <SvgIcon color="error">
            <SignalWifiOffIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
