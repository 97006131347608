import { SyntheticEvent, useCallback, useState } from 'react'

export function useTabController<TabValue>(defaultValue: TabValue) {
  const [currentTab, setCurrentTab] = useState<TabValue>(defaultValue)

  const handleTabsChange = useCallback((_: SyntheticEvent, value: TabValue): void => {
    setCurrentTab(value)
  }, [])

  return {
    currentTab,
    handleTabsChange,
  }
}
