import { AxiosPromise } from 'axios'

import { axios } from '../../_axios'
import { DetailType, ListType } from '../../_types'
import {
  CustomerDTO,
  CustomerReqSocialsCreate,
  CustomerReqSocialsDelete,
  CustomerReqSocialsUpdate,
  CustomerSocialNetwork,
} from '../models'

export const apiCustomerSocialsFetchList = (
  id: CustomerDTO['id']
): AxiosPromise<ListType<CustomerSocialNetwork>> => {
  return axios.get(`/customers/${id}/social-networks`)
}

export const apiCustomerSocialsCreate = ({
  id,
  data,
}: CustomerReqSocialsCreate): AxiosPromise<DetailType<CustomerSocialNetwork>> => {
  return axios.post(`/customers/${id}/social-networks`, data)
}

export const apiCustomerSocialsUpdate = ({
  id,
  data: { social_id, ...data },
}: CustomerReqSocialsUpdate): AxiosPromise<DetailType<CustomerSocialNetwork>> => {
  return axios.put(`/customers/${id}/social-networks/${social_id}`, data)
}

export const apiCustomerSocialsDelete = ({
  id,
  social_id,
}: CustomerReqSocialsDelete): AxiosPromise => {
  return axios.delete(`/customers/${id}/social-networks/${social_id}`)
}
