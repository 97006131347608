export const bytesToSize = (bytes: number, decimals = 2): string => {
  if (!Number.isFinite(bytes) || bytes < 0) {
    throw new Error(`bytesToSize() expects a positive number, but got ${bytes}`)
  }

  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const convertedSize = (bytes / Math.pow(k, i)).toFixed(dm)

  return `${convertedSize} ${sizes[i]}`
}
