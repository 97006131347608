import { toast } from 'react-hot-toast'
import { SagaIterator } from '@redux-saga/core'
import { call, put } from 'redux-saga/effects'

import { apiTicketsChange } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { i18n } from '../../../../core/i18n'
import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { ticketsActions } from '../../slice'
import { ChangeAsync } from '../../types'

export function* changeWorker({ payload }: ChangeAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))
    // Api call or some async actions
    const { data } = yield call(apiTicketsChange, payload.request)

    if (data) {
      if (payload.isSheet) {
        yield put(sheetsActions.changeTicket(data.item))
      }

      toast.success(i18n.t('Messages.ticket_successfully_updated'))

      yield put(uiActions.closeDetailModal())
      yield put(ticketsActions.clearDetail())
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
