import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { SheetPlace, SheetPlaceVoidPdfFunction } from '@klr/api-connectors'

import { ticketsActionsAsync } from '../../tickets'

export const useMutateFetchPrintPdf = () => {
  const dispatch = useDispatch()

  const handleFetchPrintPdf: SheetPlaceVoidPdfFunction = useCallback(
    (place: SheetPlace, isMainPdf?: boolean) => {
      dispatch(
        ticketsActionsAsync.printPdfTicketAsync({
          ticketId: place.ticket_id,
          isSheet: true,
          isMainPdf,
        })
      )
    },
    [dispatch]
  )

  return { handleFetchPrintPdf }
}
