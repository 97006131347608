import { toast } from 'react-hot-toast'
import type { SagaIterator } from '@redux-saga/core'
import type { AxiosResponse } from 'axios'
import { call, put } from 'redux-saga/effects'

import { apiTripsChange, DetailType, TripItem } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { tripsActions } from '../../index'
import type { ChangeAsync } from '../../types'

export function* changeTrip({ payload }: ChangeAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))
    // Api call or some async actions
    const { data }: AxiosResponse<DetailType<TripItem>> = yield call(
      apiTripsChange,
      payload.request
    )

    if (data) {
      if (payload.isSheet) {
        yield put(sheetsActions.changeTrips(data.item))
      } else {
        yield put(tripsActions.changeItem(data.item))
      }

      yield put(uiActions.closeDetailModal())

      toast.success('Успішно!')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
