import { StatusPlaceEnum } from '@klr/api-connectors'

export const routePlacesStatusActions = [
  {
    value: StatusPlaceEnum.AVAILABLE,
    label: 'Доступні',
    description: 'Місця доступні для продажу',
  },
  {
    value: StatusPlaceEnum.NOT_AVAILABLE,
    label: 'Недоступні',
    description: 'Недоступні місця',
  },
]
