import { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SheetPlace, SheetSelectTransport } from '@klr/api-connectors'

import { ticketsActionsAsync } from '../../tickets'
import { sheetsActions, sheetsSelectors } from '../index'

export const useMutateToggleSubSheet = () => {
  const dispatch = useDispatch()
  const selectTransport: SheetSelectTransport | null = useSelector(sheetsSelectors.selectTransport)
  const getAvailablePlaces = useSelector(sheetsSelectors.getAvailablePlaces)

  const availablePlaces: number[] = useMemo(() => {
    if (!selectTransport) return []

    return getAvailablePlaces.get(`${selectTransport.id}_${selectTransport.tripId}`)
  }, [getAvailablePlaces, selectTransport])

  const chosePlace = useRef<number[]>([])

  /**
   * Логіка перенесення пасажирів між основною відомістю і підвідомістю
   *
   * @param place - повний об. місця в відомості
   * @param isMoveSubList - чи потрібно переносити в підвідомість
   * @param isConflicts - чи місце з таблиці з конфліктами
   */
  const handleToggleSubSheet = useCallback(
    (place: SheetPlace, isMoveSubList = false, isConflicts = false): void => {
      const data = {
        isConflicts,
        isSheet: true,
        request: {
          place,
          isMoveSubList,
        },
      }

      if (!isMoveSubList) {
        // Перевірка чи є вільне місце для в основній відомості
        const isAllowMovement = availablePlaces
          .filter((p) => !chosePlace.current.includes(p))
          .some((p) => p === place.place)

        if (isAllowMovement) {
          chosePlace.current = [...chosePlace.current, place.place]

          dispatch(ticketsActionsAsync.toggleSubListAsync(data))
        } else {
          dispatch(
            sheetsActions.pushTransferPlace({
              ...place,
              isConflict: isConflicts,
            })
          )
        }
      } else {
        dispatch(ticketsActionsAsync.toggleSubListAsync(data))
      }
    },
    [availablePlaces, dispatch]
  )

  return { handleToggleSubSheet }
}
