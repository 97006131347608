import { useCallback } from 'react'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import {
  ActivityLogItem,
  activityLogsQueryKeys,
  ActivityLogSubjectType,
  getActivityLogs,
  TicketDetail,
} from '@klr/api-connectors'
import { formatToFullDateTimeString } from '@klr/shared'

interface ActivitiesProps {
  ticketId: TicketDetail['id']
}

const UNKNOWN_USER = 'Невідомий користувач'
const UNKNOWN_INTERPRETER = 'Marketplace'

// temporary decision
const logStatus: Record<ActivityLogItem['event'], 'success' | 'error'> = {
  created: 'success',
  updated: 'error',
}

const Activities = ({ ticketId }: ActivitiesProps) => {
  const filterRequest = {
    subject_type: ActivityLogSubjectType.TICKET,
    subject_id: ticketId,
  }

  const { data: logList, isLoading } = useQuery({
    queryKey: activityLogsQueryKeys.list(filterRequest),
    queryFn: async () => {
      const response = await getActivityLogs(filterRequest)

      return response.data.items
    },
    enabled: !!ticketId,
    select: (data) =>
      data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()),
  })

  const getLogDescription = useCallback((log: ActivityLogItem) => {
    const firstname =
      log.causer?.firstname === UNKNOWN_USER ? UNKNOWN_INTERPRETER : log.causer?.firstname
    const userName = `${firstname ?? ''} ${log.causer?.lastname ?? ''}`
    const createdAt = formatToFullDateTimeString(new Date(log.created_at))

    return `${userName} · ${log.description} · ${createdAt}`
  }, [])

  if (isLoading || !logList) return null

  if (!logList.length) {
    return <Typography>Активностей не знайдено</Typography>
  }

  return (
    <Stack>
      <Typography variant="h4">Активності</Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {logList.map((log, index, array) => (
          <TimelineItem key={log.id}>
            <TimelineSeparator>
              <TimelineDot variant="outlined" color={logStatus[log.event] ?? 'inherit'} />
              {index !== array.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>{getLogDescription(log)}</TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Stack>
  )
}

export default Activities
