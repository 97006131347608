import { memo } from 'react'
import { Box, Stack } from '@mui/material'

import { hideScroll, Logo, NAV, NavToggleButton } from '@klr/shared'

import { useNavConfig } from '../../../components/config-navigation'

import { NavSectionMini } from './components/NavSectionMini'

export const NavMini = memo(() => {
  const navData = useNavConfig()

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2, width: 60, height: 25 }} isShort />

        <NavSectionMini data={navData} />
      </Stack>
    </Box>
  )
})
