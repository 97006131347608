export interface EnvironmentTypes {
  baseURL: string
  env: 'devLocal' | 'development' | 'preview' | 'stage' | 'sandbox' | 'production'
  marketplaceURL: 'https://stage.klr.com.ua' | 'https://klr.com.ua'
}

export const environments: EnvironmentTypes = {
  baseURL: import.meta.env.VITE_API_URL,
  env: import.meta.env.VITE_ENVIRONMENT,
  marketplaceURL:
    process.env.NODE_ENV === 'development'
      ? 'https://stage.klr.com.ua'
      : import.meta.env.VITE_ENVIRONMENT === 'stage'
        ? 'https://stage.klr.com.ua'
        : 'https://klr.com.ua',
}
