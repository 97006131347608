import { useMemo, useState } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material'
import { isValid } from 'date-fns'

import { NSuccessMessage, OrderDetail, StatusTicketEnum, TicketDetail } from '@klr/api-connectors'
import { useAuth } from '@klr/auth'
import { ModalSendNotifications } from '@klr/message-dispatch'
import { Activities } from '@klr/order-ticket-toolkit'
import {
  FieldConfig,
  formatToDateString,
  formatToUkrShortDateTimeString,
  parseDateTimeString,
  parseUkrDateString,
  TableDetailInfo,
} from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { ChipStatus, FormChangeDetail } from '../index'

import { ActionsTicket } from './atoms/ActionsTicket'
import { getStatusByPartnerAttribute } from './helpers'

type Props = {
  tripStartDateTime: OrderDetail['trip_start_datetime']
  tripEndDateTime: OrderDetail['trip_end_datetime']
  showSendMessage: boolean
  dataSource: TicketDetail
  orderStatus: StatusTicketEnum
  showSendNotification: boolean
  isLoading: boolean
  fromList: boolean
}

export const TicketDetailView = ({
  dataSource,
  showSendMessage,
  tripEndDateTime,
  tripStartDateTime,
  orderStatus,
  showSendNotification,
  isLoading,
  fromList,
}: Props) => {
  const dispatch = useDispatch()
  const { isAgent, isDispatcher } = useAuth()
  const [isShowModalNotification, setIsShowModalNotification] = useState(false)

  const toggleShow = () => {
    setIsShowModalNotification(!isShowModalNotification)
  }

  const isCancel = useMemo(() => {
    const candidates = [
      StatusTicketEnum.CANCEL,
      StatusTicketEnum.RESERVED_PENDING,
      StatusTicketEnum.CONFIRM,
      StatusTicketEnum.PENDING,
      StatusTicketEnum.PAYMENT_FAILURE,
      StatusTicketEnum.OPENDATE,
    ]

    return candidates.includes(dataSource.status)
  }, [dataSource.status])

  const status = getStatusByPartnerAttribute(dataSource.status, dataSource.can_reserve)

  const dateTimeInterval = useMemo(() => {
    const startDate = parseDateTimeString(tripStartDateTime ?? '')
    const endDate = parseDateTimeString(tripEndDateTime ?? '')

    const formattedStartDateTime = isValid(startDate)
      ? formatToUkrShortDateTimeString(startDate)
      : ''
    const formattedEndDateTime = isValid(endDate) ? formatToUkrShortDateTimeString(endDate) : ''

    return `${formattedStartDateTime} - ${formattedEndDateTime}`
  }, [tripEndDateTime, tripStartDateTime])

  const routeName =
    (dataSource.trip.route.number ? `${dataSource.trip.route.number} ` : '') +
    dataSource.trip.route.name

  // TODO: refactor this, because it's not use case,
  //  when ticket doesn't have trip and other data
  const fieldsDataInfo: FieldConfig[] = [
    {
      label: 'Статус квитка',
      customRender: (
        <>
          {dataSource.confirmed ? (
            <span style={{ marginRight: 16 }}>
              <ChipStatus status={StatusTicketEnum.CONFIRM} />
            </span>
          ) : null}

          <ChipStatus status={status} />
        </>
      ),
    },
    {
      label: 'Дата',
      text: dateTimeInterval,
    },
    dataSource.station_from?.city?.name && dataSource.station_to?.city?.name
      ? {
          label: 'Місто',
          text: `${dataSource.station_from.city.name} - ${dataSource.station_to.city.name}`,
        }
      : null,
    dataSource.station_from?.address && dataSource.station_to?.address
      ? {
          label: 'Адреса',
          text: `${dataSource.station_from.address} - ${dataSource.station_to.address}`,
        }
      : null,
    { label: 'Метод оплати', text: dataSource.display_paid_form_as },
    routeName
      ? {
          label: 'Маршрут',
          customRender:
            isAgent && !isDispatcher ? (
              <span>{routeName}</span>
            ) : (
              <Button
                component={RouterLink}
                endIcon={
                  <SvgIcon fontSize="small">
                    <LinkIcon />
                  </SvgIcon>
                }
                target="_blank"
                to={`/sheet/${dataSource.trip.route.id}/?date=${formatToDateString(
                  parseUkrDateString(dataSource.trip.date_start)
                )}`}
              >
                {routeName}
              </Button>
            ),
        }
      : null,
    dataSource.trip?.route?.carrier_name
      ? {
          label: 'Перевізник',
          text: dataSource.trip.route.carrier_name,
        }
      : null,
    dataSource.trip?.route?.carrier_baggage
      ? {
          label: 'Багаж',
          text: dataSource.trip.route.carrier_baggage,
        }
      : null,
  ].filter(Boolean)

  return (
    <>
      <Grid item sm={12}>
        <Box mb={3}>
          <TableDetailInfo fieldsArray={fieldsDataInfo} divider="dashed" />
        </Box>
      </Grid>
      {!isCancel ? (
        <Grid item sm={12}>
          <Box mb={3}>
            <ActionsTicket
              showSendMessage={showSendMessage}
              isLoading={isLoading}
              dataSource={dataSource}
              orderStatus={orderStatus}
              fromList={fromList}
            />
          </Box>
        </Grid>
      ) : null}
      <Grid item sm={12}>
        <Box>
          <Typography variant="h4">Деталі пасажира</Typography>
          <FormChangeDetail
            isSheet={false}
            isAllowEdit={!isCancel}
            isLoading={isLoading}
            ticketId={dataSource.id}
            orderId={dataSource.order_id}
            defaultFirstName={dataSource.first_name}
            defaultLastName={dataSource.last_name}
            defaultPhone={dataSource.phone}
            defaultEmail={dataSource.email}
            defaultTicketNumber={dataSource.ticket_number}
            defaultNote={dataSource.information}
            isFixedPlaceNumber={!!dataSource.is_free_place}
          />
        </Box>
      </Grid>

      {showSendNotification && (
        <Grid item sm={12}>
          <Button color="primary" variant="outlined" onClick={() => toggleShow()}>
            Відправити СМС
          </Button>
          <ModalSendNotifications
            handleCloseModal={toggleShow}
            isShowModal={isShowModalNotification}
            titleModal="Відправка повідомлення"
            phoneNumber={dataSource?.phone}
            onMessageInfo={(notifications: NSuccessMessage[]) =>
              dispatch(
                sheetsActions.addMessageInfo({
                  notifications,
                })
              )
            }
          />
        </Grid>
      )}

      <Grid item sm={12}>
        <Box mt={3}>
          <Activities ticketId={dataSource.id} />
        </Box>
      </Grid>
    </>
  )
}
