import React from 'react'
import { InfiniteData } from '@tanstack/react-query'

import { ListType } from '@klr/api-connectors'

// This import can't be shortened,
// because it's will lead to errors for cyclic dependencies
// noinspection ES6PreferShortImport

interface UseControlScrollPayload<Option> {
  data: InfiniteData<ListType<Option>> | undefined
  limit: number
  hasNextPage: boolean | undefined
  fetchNextPage: VoidFunction
  isFetchingNextPage: boolean
}

export function useControlScroll<Option>(payload: UseControlScrollPayload<Option>) {
  const { data, limit, hasNextPage, fetchNextPage, isFetchingNextPage } = payload

  const listBoxRef = React.useRef<HTMLUListElement>(null)
  const scrollPositionRef = React.useRef(0)

  React.useEffect(() => {
    if (listBoxRef.current) listBoxRef.current.scrollTop = scrollPositionRef.current
  }, [data])

  const handleListBoxScroll: React.UIEventHandler<HTMLUListElement> = React.useCallback(
    (event) => {
      const listBoxNode = event.currentTarget

      // Calculate the scroll threshold based on the total height of the listBox and the limit of items to display
      const scrollThreshold = (listBoxNode.scrollHeight / limit) * 2

      // Check if the user is scrolling down the listBox
      const isScrollingDown =
        listBoxNode.scrollHeight - listBoxNode.clientHeight - listBoxNode.scrollTop <=
        scrollThreshold

      // If the user is scrolling down, there are more items to fetch,
      // and we're not currently fetching more items, then fetch the next page of items
      if (isScrollingDown && hasNextPage && !isFetchingNextPage) {
        scrollPositionRef.current = listBoxNode.scrollTop
        fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage, limit]
  )

  return {
    ref: listBoxRef,
    onScroll: handleListBoxScroll,
  }
}
