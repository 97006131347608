import { StatusGroupEnum, StatusTicketEnum } from './status.enum'

// duplicate to prevent cyclic imports
type BasicOptions<T extends string = string> = {
  value: T
  label: string
}

export type StatusTicketReport = Exclude<
  StatusTicketEnum,
  'cash_reserved' | 'sold' | 'opendate' | 'confirm' | 'reserved_pending'
>

export const STATUSES: BasicOptions<StatusTicketEnum>[] = [
  { value: StatusTicketEnum.DEFERRED, label: 'Statuses.deferred' },
  { value: StatusTicketEnum.RESERVED, label: 'Statuses.reserved' },
  // { value: StatusTicketEnum.RESERVED_PENDING, label:  t('Statuses.reserved_pending' },
  { value: StatusTicketEnum.CANCEL, label: 'Statuses.cancel' },
  { value: StatusTicketEnum.CHANGED, label: 'Statuses.changed' },
  { value: StatusTicketEnum.RETURN, label: 'Statuses.return' },
  { value: StatusTicketEnum.PAID, label: 'Statuses.paid' },
  // { value: StatusTicketEnum.CONFIRM, label: "Statuses.confirm" },
  { value: StatusTicketEnum.CASH, label: 'Statuses.cash' },
  { value: StatusTicketEnum.CASH_DISP, label: 'Statuses.cash_disp' },
  { value: StatusTicketEnum.PENDING, label: 'Statuses.pending' },
  {
    value: StatusTicketEnum.PAYMENT_FAILURE,
    label: 'Statuses.payment_failure',
  },
  // { value: StatusTicketEnum.OPENDATE, label: "Statuses.opendate" },
]

export const STATUSES_GROUP: BasicOptions[] = [
  { value: StatusGroupEnum.ALL, label: 'Statuses.all' },
  { value: StatusGroupEnum.RETURNED, label: 'Statuses.returned' },
  { value: StatusGroupEnum.NOT_RETURNED, label: 'Statuses.not_returned' },
]

export const STATUSES_TRANSLATION: Record<StatusTicketReport, string> = {
  [StatusTicketEnum.DEFERRED]: 'Statuses.deferred',
  [StatusTicketEnum.RESERVED]: 'Statuses.reserved',
  [StatusTicketEnum.CANCEL]: 'Statuses.cancel',
  [StatusTicketEnum.CHANGED]: 'Statuses.changed',
  [StatusTicketEnum.RETURN]: 'Statuses.return',
  [StatusTicketEnum.PAID]: 'Statuses.paid',
  [StatusTicketEnum.CASH]: 'Statuses.cash',
  [StatusTicketEnum.CASH_DISP]: 'Statuses.cash_disp',
  [StatusTicketEnum.PENDING]: 'Statuses.pending',
  [StatusTicketEnum.PAYMENT_FAILURE]: 'Statuses.payment_failure',
}
