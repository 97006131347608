import { PayloadAction } from '@reduxjs/toolkit'

import { SheetPlace } from '@klr/api-connectors'

import { SheetsState } from '../sheetState'

/**
 * Редюсер для отримання модальним вікном об. квитків для відправки СМС.
 */
export const messagesReducers = {
  savePlaceForMessages: (state: SheetsState, action: PayloadAction<SheetPlace[]>) => {
    state.placeForMessages = action.payload
  },
  clearPlaceForMessages: (state: SheetsState) => {
    state.placeForMessages = []
  },
}
