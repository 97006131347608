import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  apiCustomerCreate,
  apiCustomerUpdate,
  CustomerDTO,
  customerQueryKeys,
  CustomerReqCreate,
} from '@klr/api-connectors'
import { filterNonEmptyProperties, handleBackendError } from '@klr/shared'

import { setMainInfo } from '../../../../../../store'

import { CustomerDetailFormFields } from './customer.validations'

interface CustomerFormsProps {
  customerId: CustomerDTO['id'] | null
  successCallback(): void
}

export const useCustomerForms = ({ customerId, successCallback }: CustomerFormsProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { mutateAsync: mutateCreateAsync, isLoading: isCreating } = useMutation({
    mutationKey: [...customerQueryKeys.detail(customerId), 'create-detail'],
    mutationFn: apiCustomerCreate,
    onSuccess: (response) => {
      queryClient.setQueryData(customerQueryKeys.detail(customerId), response.data.item)
      setMainInfo(response.data.item)
      toast.success(t('Messages.customer_created'))
    },
    onError: (error) => handleBackendError(error),
  })

  const { mutateAsync: mutateUpdateAsync, isLoading: isUpdating } = useMutation({
    mutationKey: [...customerQueryKeys.detail(customerId), 'update-detail'],
    mutationFn: apiCustomerUpdate,
    onSuccess: (response) => {
      queryClient.setQueryData(customerQueryKeys.detail(customerId), response.data.item)
      setMainInfo(response.data.item)
      toast.success(t('Messages.customer_updated'))
    },
    onError: (error) => handleBackendError(error),
  })

  const onSubmit = (data: CustomerDetailFormFields) => {
    const request = filterNonEmptyProperties<CustomerDetailFormFields>(data) as CustomerReqCreate

    if (customerId) {
      mutateUpdateAsync({
        id: customerId,
        data: request,
      }).then(successCallback)
    } else {
      mutateCreateAsync(request).then(successCallback)
    }
  }

  return {
    isLoading: isCreating || isUpdating,
    onSubmit,
  }
}
