import { useEffect, useState } from 'react'

import { ObjectWithId } from '@klr/shared'

type UseTransferListProps<T> = {
  settingData: T[]
  userData: T[]
  setUserData: (value: T[]) => void
}

export function useTransferList<T extends ObjectWithId>({
  settingData,
  userData,
  setUserData,
}: UseTransferListProps<T>) {
  const [notSelected, setNotSelected] = useState<T[]>([])
  const [checked, setChecked] = useState<T[]>([])

  useEffect(() => {
    if (settingData) {
      const value = settingData.filter(
        (item) => userData.filter((userCurrent) => userCurrent.id === item.id).length === 0
      )
      setNotSelected(value)
    }
  }, [settingData, userData])

  const leftChecked = checked.filter((checkItem) => notSelected.includes(checkItem))
  const rightChecked = checked.filter((checkItem) => userData.includes(checkItem))

  const numberOfChecked = (items: T[]) =>
    checked.filter((checkItem) => items.includes(checkItem)).length

  const handleToggleAll = (items: T[]) => () => {
    if (numberOfChecked(items) === items.length) {
      const value = checked.filter((checkItem) => !items.includes(checkItem))
      setChecked(value)
    } else {
      const selectedValue = items.filter((item) => !checked.includes(item))
      const value = [...checked, ...selectedValue]

      setChecked(value)
    }
  }

  const handleToggle = (value: T) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleCheckedRight = () => {
    const userValue = [...userData, ...leftChecked]
    const checkValue = checked.filter(
      (checkItem) =>
        leftChecked.filter((leftCheckItem) => leftCheckItem.id === checkItem.id).length === 0
    )
    setChecked(checkValue)
    setUserData(userValue)
  }

  const handleCheckedLeft = () => {
    const userValue = userData.filter((userItem) => !rightChecked.includes(userItem))
    const checkValue = checked.filter(
      (checkItem) => rightChecked.filter((rightItem) => rightItem.id === checkItem.id).length === 0
    )

    setUserData(userValue)
    setChecked(checkValue)
  }

  return {
    notSelected,
    leftChecked,
    rightChecked,
    checked,
    numberOfChecked,
    handleToggleAll,
    handleToggle,
    handleCheckedRight,
    handleCheckedLeft,
  }
}
