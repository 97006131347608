import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { apiTicketsConfirm, TicketReqChange } from '@klr/api-connectors'

export const useMutateConfirmTicket = () => {
  return useMutation(
    ({ ticketId, data }: TicketReqChange) =>
      apiTicketsConfirm({
        ticketId,
        data,
      }),
    {
      onSuccess: () => {
        toast.success('Квиток підтверджено')
      },
    }
  )
}
