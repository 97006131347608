import { SyntheticEvent, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete, Box, Grid, Skeleton, TextField } from '@mui/material'

import { OrderStatus } from '@klr/api-connectors'
import { useGetOrderStatuses } from '@klr/system-entities'

import { ChipsFormPaid } from '../index'

type Props = {
  name: string
  // в цей проп може приходити тільки id або вже об'єкт з id та name або null
  defaultOrderStatus?: OrderStatus | null
  size?: 'small' | 'medium'
}

export const AutocompleteFormPaid = ({
  name,
  defaultOrderStatus = null,
  size = 'medium',
}: Props) => {
  const { data: orderStatuses, isLoading } = useGetOrderStatuses({})

  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext()

  useEffect(() => {
    // отримання дефолтного значення для RHF тоді коли в нас є тільки id, а name порожній рядок
    if (orderStatuses?.length && defaultOrderStatus && !defaultOrderStatus.name) {
      const candidateOrderStatus = orderStatuses.find(
        (item: OrderStatus) => item.id === defaultOrderStatus.id
      )

      if (candidateOrderStatus) {
        setValue(name, candidateOrderStatus)
        return
      }
    }

    // defaultOrderStatus is not allowed here in useEffect dependency because it causes a bug with unchanged value in autocomplete
    // eslint-disable-next-line
  }, [name, orderStatuses, setValue])

  const setChipValue = (val: OrderStatus) => {
    setValue(name, val, { shouldDirty: true })

    if (errors) {
      clearErrors(name)
    }
  }

  if (!orderStatuses?.length) {
    return <Skeleton variant="rectangular" width="100%" height={52} />
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              openOnFocus
              disableClearable
              options={orderStatuses}
              loading={isLoading}
              // дефолтне значення при mount компонента може бути тільки з id
              getOptionLabel={(option: OrderStatus) => option?.name || ''}
              isOptionEqualToValue={(option: OrderStatus, value: OrderStatus) =>
                option.id === value.id
              }
              onChange={(_: SyntheticEvent, data: OrderStatus) => {
                field.onChange(data)
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Форма оплати"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  required
                  fullWidth
                  size={size}
                />
              )}
            />
          )}
          name={name}
          control={control}
        />
      </Grid>
      <Grid item>
        <ChipsFormPaid handleChange={setChipValue} />
      </Grid>
    </Grid>
  )
}
