import { Grid } from '@mui/material'

import { NSuccessMessage } from '@klr/api-connectors'
import { DialogFullScreen } from '@klr/shared'

import { useFetchTemplates } from '../../hooks/useFetchTemplates'
import { MessageDispatchSkeleton } from '../MessageDispatchSkeleton'

import { FormSendNotification } from './atoms'

type Props = {
  titleModal: string
  isShowModal: boolean
  handleCloseModal: () => void
  phoneNumber: string
  onMessageInfo?: (notifications: NSuccessMessage[]) => void
}

export const ModalSendNotifications = ({
  isShowModal,
  titleModal,
  handleCloseModal,
  phoneNumber,
  onMessageInfo,
}: Props) => {
  const { data: templates, isLoading } = useFetchTemplates({ enabled: isShowModal })

  return (
    <DialogFullScreen
      title={titleModal}
      isShowModal={isShowModal}
      handleCloseModal={() => handleCloseModal()}
    >
      <Grid container spacing={2} justifyContent="center" p={4}>
        <Grid item md={4}>
          {isLoading ? (
            <MessageDispatchSkeleton />
          ) : (
            <FormSendNotification
              templates={templates ?? []}
              phoneNumber={phoneNumber}
              onMessageInfo={onMessageInfo}
            />
          )}
        </Grid>
      </Grid>
    </DialogFullScreen>
  )
}
