import { sortBy } from 'lodash'

import { Floor, Seat, SeatCategory, SheetPlace, SheetPlaceEmpty } from '@klr/api-connectors'
import { getPlaceInScheme, isSeat } from '@klr/schemes'

import { AvailablePlace, PlaceCollapse } from '../validations'

import { isPlaceNotEmpty } from './index'

type DisabledMode = 'true' | 'false' | 'inverse'

export function getAvailableSeats(
  scheme: Floor[],
  transportPlaces: (SheetPlace | SheetPlaceEmpty)[]
) {
  const availableTransportPlaces = transportPlaces
    .filter((item) => !isPlaceNotEmpty(item))
    .map((item) => item.place)

  const availableSeats = scheme.reduce<AvailablePlace[]>((acc, floor) => {
    floor.forEach((row) => {
      row.forEach((item) => {
        if (isSeat(item) && availableTransportPlaces.includes(item.place)) {
          acc.push({
            place: item.place,
            category: item.category,
            disabled: false,
          })
        }
      })
    })

    return acc
  }, [])

  return sortBy(availableSeats, 'place')
}

export function transformSchemeByAvailableSeats(scheme: Floor[], availableSeats: number[]) {
  return scheme.map((floor) => {
    return floor.map((row) => {
      return row.map((item) => {
        if (isSeat(item)) {
          return {
            ...item,
            available: availableSeats.includes(item.place),
          }
        }

        return item
      })
    })
  })
}

export function transformSchemeBySelectedPlace(
  scheme: Floor[] | null,
  newPlace: number,
  oldPlace?: number
) {
  if (!scheme) return null

  return scheme.map((floor) => {
    return floor.map((row) => {
      return row.map((item) => {
        if (isSeat(item)) {
          if (item.place === newPlace) {
            return {
              ...item,
              available: !item.available,
              selected: !item.selected,
            }
          }

          if (newPlace !== oldPlace && item.place === oldPlace) {
            return {
              ...item,
              available: !item.available,
              selected: false,
            }
          }
        }

        return item
      })
    })
  })
}

export function transformPassengersByRemoving(initialData: PlaceCollapse[], seat: Seat) {
  if (seat.selected && initialData.length > 1) {
    const filteredPassengers = initialData.filter((item) => item.place !== seat.place)

    return filteredPassengers.length === 1
      ? filteredPassengers.map((item) => ({ ...item, collapse: true }))
      : filteredPassengers
  }

  return initialData
}

export function addCollapsedPassenger(passengers: PlaceCollapse[], place: number) {
  return [
    ...passengers.map((item) => ({ ...item, collapse: false })),
    {
      place,
      collapse: true,
    },
  ]
}

export function getCandidateSeatAndRestFromAvailable(places: AvailablePlace[]) {
  if (places.length < 2) {
    return places
  }

  const standardSeatIndex = places.findIndex((item) => item.category === SeatCategory.STANDARD)

  if (standardSeatIndex !== -1) {
    return [
      places[standardSeatIndex],
      ...[places.slice(0, standardSeatIndex), places.slice(standardSeatIndex + 1)].flat(),
    ]
  }

  return places
}

function getDisabledAvailableSeatValue(state: boolean, mode: DisabledMode) {
  switch (mode) {
    case 'true':
      return true
    case 'false':
      return false
    case 'inverse':
      return !state
  }
}

export function transformAvailableSeatsByDisabling(
  availableSeats: AvailablePlace[],
  place: number,
  mode: DisabledMode
) {
  return availableSeats.map((item) => {
    if (item.place === place) {
      return {
        ...item,
        disabled: getDisabledAvailableSeatValue(item.disabled, mode),
      }
    }

    return item
  })
}

export function getPlaceCategory(placeData: SheetPlace | SheetPlaceEmpty, scheme: Floor[] | null) {
  if (!scheme || !isPlaceNotEmpty(placeData)) return null

  return getPlaceInScheme(scheme, placeData.place)?.category ?? null
}
