import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { SheetPlace } from '@klr/api-connectors'

import { sheetsActions } from '../slice'

export const useTransferToTransport = () => {
  const dispatch = useDispatch()

  const handleTransferToTransport = useCallback(
    (place: SheetPlace, isSubList = false, isConflict = false): void => {
      dispatch(
        sheetsActions.pushTransferTransportPlace({
          ...place,
          isSubList,
          isConflict,
          isTransferBus: true,
          isTransferDate: false,
        })
      )
    },
    [dispatch]
  )

  const handleTransferToTransportMultiple = useCallback(
    (places: SheetPlace[]): void => {
      dispatch(sheetsActions.setTransferTransportPlaces(places))
    },
    [dispatch]
  )

  return { handleTransferToTransport, handleTransferToTransportMultiple }
}
