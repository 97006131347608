export interface EnvironmentTypes {
  binotelKey: string
  binotelSecret: string
  binotelSocketUrl: string
}

export const environments: EnvironmentTypes = {
  binotelKey: import.meta.env.VITE_BINOTEL_KEY ?? '',
  binotelSecret: import.meta.env.VITE_BINOTEL_SECRET ?? '',
  binotelSocketUrl: import.meta.env.VITE_BINOTEL_SOCKET_URL ?? 'wss://ws.binotel.com:9002',
}
