import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import {
  apiSheetsGetSheetPrice,
  SheetDefaultMainPrice,
  sheetQueryKeys,
  SheetReqGetPrice,
} from '@klr/api-connectors'
import { AppModules } from '@klr/shared'

type HookProps = {
  stationFromId: number
  stationToId: number
  promotionId: number
  currencyId: number
  mainTripId: number | null
  defaultFullPrice?: number
}

export const useFetchPrice = ({
  stationFromId,
  stationToId,
  promotionId,
  currencyId,
  mainTripId,
  defaultFullPrice = 0,
}: HookProps) => {
  const [price, setPrice] = useState<SheetDefaultMainPrice | null>({
    currency: null,
    price_discount: defaultFullPrice,
    price_full: defaultFullPrice,
  })

  const [reqData, setReqData] = useState<SheetReqGetPrice | null>(null)

  const { isFetching: isLoading, refetch } = useQuery(
    sheetQueryKeys.price(reqData),
    async () => {
      const response = await apiSheetsGetSheetPrice(reqData!)

      return response.data.item
    },
    {
      enabled: false,
      meta: {
        module: AppModules.Booking,
      },
    }
  )

  const fetchPrice = useCallback(
    (promotionValue?: string) => {
      let request: SheetReqGetPrice

      if (stationFromId && stationToId && mainTripId) {
        request = {
          trip_id: mainTripId,
          station_from_id: stationFromId,
          station_to_id: stationToId,
        }

        if (currencyId) {
          request.currency_id = currencyId
        }

        if (promotionId) {
          request.promotion_id = promotionId
        }

        if (promotionValue) {
          request.promotion_value = promotionValue
        }

        setReqData(request)
      }
    },
    [currencyId, mainTripId, promotionId, stationFromId, stationToId]
  )

  useEffect(() => {
    if (reqData) {
      refetch()
        .then(({ data }) => {
          setPrice(data?.main ?? null)
        })
        .catch(() => {
          setPrice(null)
        })
    }
  }, [refetch, reqData])

  return {
    price,
    setPrice,
    isLoading,
    fetchPrice,
  }
}
