import { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Drawer, Fallback, toggleScroll } from '@klr/shared'

import { ticketsActions } from '../../../tickets'
import { sheetsActions, sheetsSelectors } from '../../index'

const SectionForms = lazy(() => import('./SectionForms'))
const PlaceConstructor = lazy(() => import('./components/PlaceConstructor'))

export const DrawerCreateTickets = () => {
  const dispatch = useDispatch()
  const dataView = useSelector(sheetsSelectors.drawerCreatePayload)

  const isShowDrawer = useSelector(sheetsSelectors.isShowCreateDrawer)
  const selectTransport = useSelector(sheetsSelectors.selectTransport)
  const placeConstructor = selectTransport?.placeConstructor

  useEffect(() => {
    toggleScroll(isShowDrawer)
  }, [isShowDrawer])

  const _closeDrawer = () => {
    dispatch(ticketsActions.clearDetail())
    dispatch(sheetsActions.closeCreateDrawer())
  }

  if (!dataView) return null

  return (
    <Drawer open={isShowDrawer}>
      <Suspense fallback={<Fallback />}>
        {isShowDrawer &&
          (placeConstructor ? (
            <PlaceConstructor dataView={dataView} onCloseDrawer={_closeDrawer} />
          ) : (
            <SectionForms closeDrawer={_closeDrawer} />
          ))}
      </Suspense>
    </Drawer>
  )
}
