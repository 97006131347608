/**
 * Файл який направляє на початкову сторінку в залежності яке роль
 */
import { Navigate } from 'react-router-dom'

import { useAuth } from '@klr/auth'
import { bookingPaths } from '@klr/booking'
import { globalPaths } from '@klr/shared'

import { paths } from './paths'

const RouteManager = () => {
  const { logout, isAdmin, isDispatcher, isCarrier, isAccountant } = useAuth()

  if (isDispatcher) {
    return <Navigate to={bookingPaths.bookingSearch} replace />
  }

  if (isCarrier) {
    return <Navigate to={paths.routesList} replace />
  }

  if (isAdmin) {
    return <Navigate to={bookingPaths.bookingSearch} replace />
  }

  if (isAccountant) {
    return <Navigate to={paths.reportsCarriers} replace />
  }

  logout()

  return <Navigate to={globalPaths.auth.login} replace />
}

export default RouteManager
