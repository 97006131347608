import React from 'react'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  DataGridPremium,
  GridRowModel,
  GridToolbar,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'
import { QueryKey } from '@tanstack/query-core'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { getNextPageQueryParam, ListType, QueryKeyWithSearch } from '@klr/api-connectors'

import { HeaderConnectedDataGrid } from './partials/HeaderConnectedDataGrid'
import { ConnectedDataGridProps } from './ConnectedDataGrid.types'

export const ConnectedDataGrid = <
  Option extends GridValidRowModel,
  TQueryKey extends QueryKey = QueryKey,
>(
  props: ConnectedDataGridProps<Option, TQueryKey>
) => {
  const {
    queryFn,
    queryKey,
    queryOptions,
    hasSearch = true,
    querySearchParam = 'q',
    title,
    searchTextFieldProps,
    actionButtons,
    loading = false,
    customSlots,
    ...dataGridProps
  } = props

  const [querySearch, setQuerySearch] = useQueryParam(
    querySearchParam,
    withDefault(StringParam, '')
  )

  const infiniteQuery = useInfiniteQuery<
    ListType<Option>,
    AxiosError,
    ListType<Option>,
    QueryKeyWithSearch<TQueryKey>
  >([...queryKey, { search: querySearch ?? '' }], queryFn, {
    ...queryOptions,
    getNextPageParam: getNextPageQueryParam,
  })

  const rows: GridRowModel<Option>[] = React.useMemo(
    () => infiniteQuery.data?.pages.flatMap((page) => page.items) || [],
    [infiniteQuery.data]
  )
  const fetchNextPage = () => {
    if (infiniteQuery.hasNextPage) {
      infiniteQuery.fetchNextPage()
    }
  }

  const hasHeader = !!title || hasSearch || !!actionButtons

  return (
    <StyledDataGridWrapper spacing={hasHeader ? 2 : 0}>
      <HeaderConnectedDataGrid
        hasHeader={hasHeader}
        title={title}
        hasSearch={hasSearch}
        actionButtons={actionButtons}
        searchTextFieldProps={searchTextFieldProps}
        querySearch={querySearch}
        onChange={setQuerySearch}
      />

      {customSlots?.totalData}

      <DataGridPremium
        disableAggregation
        disableDensitySelector
        disableColumnFilter
        disableRowSelectionOnClick
        disableRowGrouping
        {...dataGridProps}
        rows={rows}
        rowCount={infiniteQuery.data?.pages[0].count || 0}
        loading={infiniteQuery.isLoading || infiniteQuery.isFetchingNextPage || loading}
        onRowsScrollEnd={fetchNextPage}
        slots={{
          toolbar: GridToolbar,
          ...dataGridProps.slots,
        }}
      />
    </StyledDataGridWrapper>
  )
}

export const StyledDataGridWrapper = styled(Stack)(() => ({
  flex: 1,
  minHeight: 0,
  height: '100%',
}))
