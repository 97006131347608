import { useCallback } from 'react'

import { AccountCurrent, apiAuthFetchAccount, SystemRoleMap } from '@klr/api-connectors'
import { AuthConsumer, AuthProvider } from '@klr/auth'

import { InitFeaturebase } from './features/featurebase'
import Router from './router'

const App = () => {
  console.log('Welcome to the KLR SPA Workspace!')

  const callbackLogin = useCallback((user: AccountCurrent) => {
    return user.roles.every((role) =>
      [
        SystemRoleMap.Accountant,
        SystemRoleMap.Admin,
        SystemRoleMap.Carrier,
        SystemRoleMap.Dispatcher,
      ].includes(role.key)
    )
  }, [])

  return (
    <AuthProvider apiFetchAccount={apiAuthFetchAccount} callbackLogin={callbackLogin}>
      <AuthConsumer>
        <InitFeaturebase />
        <Router />
      </AuthConsumer>
    </AuthProvider>
  )
}

export default App
