import type { RootState } from '../../core/store/rootReducer'

// Selector

export const isLoadingHandlers = (state: RootState) => state.ui.loadingHandlers
export const isLoadingList = (state: RootState) => state.ui.loadingList
export const isLoadingDetail = (state: RootState) => state.ui.loadingDetail

export const isShowDetail = (state: RootState) => state.ui.isShowDetail
export const getDetailView = (state: RootState) => state.ui.detailView
export const isShowModal = (state: RootState) => state.ui.isShowModal
export const getDetailModal = (state: RootState) => state.ui.detailModal
