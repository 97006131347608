import { SeatCategory } from '../_shared/seat-category.enum'
import { ReqFetchList } from '../_types'

export enum SeatType {
  SEAT = 'seat',
  SEAT_REVERSE = 'seat_reverse',
  WC = 'wc',
  STAIRS = 'stairs',
  EXIT = 'exit',
  EMPTY = 'empty',
  TABLE = 'table',
}

export type BusPlace = {
  type: Omit<SeatType, SeatType.SEAT | SeatType.SEAT_REVERSE>
}

export type Seat = {
  place: number
  type: SeatType.SEAT | SeatType.SEAT_REVERSE
  category: SeatCategory
  price_for_category: number
  available: boolean
  selected: boolean
}

export type BusCell = Seat | BusPlace

export type RowOfSeats = BusCell[]

export type Floor = RowOfSeats[]

export interface PremiumPrice {
  premium_seat: number
  premium_comfort: number
  premium_extra_distance: number
  premium_panorama: number
  premium_table: number
  standard_seat: number
}

export interface SchemeDTO {
  id: number
  description: string
  has_premium_seats: boolean
  premium_price?: Partial<PremiumPrice>
  is_deleted?: boolean
  deleted_at?: string
}

export interface SchemeDetailDTO extends SchemeDTO {
  scheme: Floor[]
}

// requests
export interface SchemeReqFetchItems extends ReqFetchList {
  with_trashed?: boolean
}

export type SchemeReqCreate = Omit<SchemeDetailDTO, 'id' | 'has_premium_seats'>

export interface SchemeReqChange {
  id: SchemeDTO['id']
  data: SchemeReqCreate
}
