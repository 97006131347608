import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined as EditIcon } from '@mui/icons-material'
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { useCustomerStore } from '../../../../../../store'
import { StyledCustomerCard } from '../../customer-card.styles'

import { CustomerShortSocialNetworks } from './components/CustomerShortSocialNetworks'
import { CustomerTagList } from './components/CustomerTagList'

interface CustomerDetailProps {
  onOpenForm(): void
}

export const CustomerDetail = ({ onOpenForm }: CustomerDetailProps) => {
  const { t } = useTranslation()

  const customerData = useCustomerStore((state) => state.getMainInfoSnippet())

  return (
    <StyledCustomerCard>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 2 }}>
        <Typography variant="h6">{t('Common.main_info')}</Typography>
        <Stack direction="row" spacing={1}>
          <Tooltip title={t('Common.edit')}>
            <IconButton aria-label="edit" onClick={onOpenForm}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <List>
        {customerData.map((item, index) => (
          <Fragment key={index}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body2" color="text.secondary">
                    {t(`Customers.${item.label}`)}
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2">
                    {item.label === 'gender' && item.value !== '- -'
                      ? t(`Customers.${item.value}`)
                      : item.value}
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </Fragment>
        ))}
      </List>

      <Stack spacing={1} sx={{ p: 2 }}>
        <Typography variant="h6">Теги</Typography>
        <CustomerTagList />
      </Stack>

      <Stack spacing={1} sx={{ p: 2 }}>
        <Typography variant="h6">Соцмережі</Typography>
        <CustomerShortSocialNetworks />
      </Stack>
    </StyledCustomerCard>
  )
}
