import { QueryFunctionContext } from '@tanstack/react-query'

import { ByQuery, getQueryPagination } from '../_byQuery'
import { ConnectedQueryFunctionContext } from '../_shared'
import { ListDataPaginationType, ListType, ReqFetchList } from '../_types'

import { apiCustomerFetchTrips, apiCustomersByQuery, getCustomerReferralList } from './api'
import {
  CustomerDTO,
  CustomerNotesReqList,
  CustomerReferralDTO,
  CustomerReqTrips,
  CustomerTrip,
  GetCustomerReferralRequest,
} from './models'

export const customerReferralQueryKeys = {
  all: ['customer-referral'] as const,
  list: (date: string) => [...customerReferralQueryKeys.all, date] as const,
  total: (date: string) => [...customerReferralQueryKeys.all, date] as const,
}

export const customerQueryKeys = {
  all: ['customers'] as const,
  list: (filters: ByQuery.Request<CustomerDTO>) => [...customerQueryKeys.all, filters] as const,
  detail: (id: CustomerDTO['id'] | null) => [...customerQueryKeys.all, id] as const,
  notes: ({ id, params }: CustomerNotesReqList) =>
    [...customerQueryKeys.detail(id), 'notes', params] as const,
  orders: (id: CustomerDTO['id'] | null) => [...customerQueryKeys.detail(id), 'orders'] as const,
  socials: (id: CustomerDTO['id'] | null) => [...customerQueryKeys.detail(id), 'socials'] as const,
  tags: (id: CustomerDTO['id'] | null) => [...customerQueryKeys.detail(id), 'tags'] as const,
  trips: (request: CustomerReqTrips) =>
    [...customerQueryKeys.detail(request.id), 'trips', request.params] as const,
  loyaltyLevels: ['loyalty-levels'] as const,
}

export type CustomerReferralQueryKeys = {
  all: (typeof customerReferralQueryKeys)['all']
  list: readonly [...CustomerReferralQueryKeys['all'], string]
}
export type CustomerQueryKeys = {
  all: readonly ['customers']
  list: readonly [...CustomerQueryKeys['all'], ByQuery.Request<CustomerDTO>]
  detail: readonly [...CustomerQueryKeys['all'], CustomerDTO['id'] | null]
  notes: readonly [...CustomerQueryKeys['detail'], 'notes', CustomerNotesReqList['params']]
  orders: readonly [...CustomerQueryKeys['detail'], 'orders']
  socials: readonly [...CustomerQueryKeys['detail'], 'socials']
  tags: readonly [...CustomerQueryKeys['detail'], 'tags']
  trips: readonly [...CustomerQueryKeys['detail'], 'trips', ReqFetchList]
  loyaltyLevels: readonly ['loyalty-levels']
}

export const customerQueryFns = {
  getCustomerReferralList: async (
    context: ConnectedQueryFunctionContext<CustomerReferralQueryKeys['list']>
  ): Promise<ListType<CustomerReferralDTO>> => {
    const {
      queryKey: [, date],
      pageParam = 1,
    } = context

    const payload: GetCustomerReferralRequest = {
      date,
      ...getQueryPagination({ pageParam }),
    }

    const { data } = await getCustomerReferralList(payload)

    return data
  },
  getCustomerList: async (
    context: QueryFunctionContext<CustomerQueryKeys['list']>
  ): Promise<ListType<CustomerDTO>> => {
    const {
      pageParam = 1,
      queryKey: [, filters],
    } = context

    const payload: ByQuery.Request<CustomerDTO> = {
      ...filters,
      ...getQueryPagination({ pageParam }),
    }

    const { data } = await apiCustomersByQuery(payload)
    return data
  },
  getCustomerTrips: async (
    context: ConnectedQueryFunctionContext<CustomerQueryKeys['trips']>
  ): Promise<ListDataPaginationType<CustomerTrip>> => {
    const {
      queryKey: [, id, , params],
      pageParam = 1,
    } = context

    const payload: CustomerReqTrips = {
      id: id as number,
      params: {
        ...params,
        ...getQueryPagination({ pageParam }),
      },
    }

    const { data } = await apiCustomerFetchTrips(payload)
    return data
  },
}
