import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { DetailType } from '../_types'

import {
  PlaceType,
  SheetData,
  SheetPlaceStatus,
  SheetReqFetchSheet,
  SheetReqGetPrice,
  SheetReqResolveLostTickets,
  SheetResGetPrice,
  TripPlaceRequest,
} from './models'

/**
 * Отримати таблиці відомості
 *
 * @param params {
 * departure_date: string
 * route_id: number
 * is_returned_tickets?: 1 | 0
 * }
 */
export const apiSheetsFetchSheets = (
  params: SheetReqFetchSheet
): AxiosPromise<DetailType<SheetData>> => {
  return axios.get('/sheets', { params })
}

/**
 * Отримати ціну для поїздки
 *
 * @param params
 */
export const apiSheetsGetSheetPrice = (
  params: SheetReqGetPrice
): AxiosPromise<DetailType<SheetResGetPrice>> => {
  return axios.get('/sheets/price', { params })
}

/**
 * Назначення транспорту 'загубленим' квиткам
 *
 * @param data
 */
export const apiSheetsResolveLostTickets = (data: SheetReqResolveLostTickets): AxiosPromise => {
  return axios.post('/sheets/resolve-lost-tickets', data)
}

export const apiSheetFetchSegmentTripPlaces = (
  params: TripPlaceRequest
): AxiosPromise<PlaceType<SheetPlaceStatus>> => {
  return axios.get(`/trips/${params.trip_id}/segment-free-places`, { params })
}
