import { Tooltip, Typography } from '@mui/material'

const surchargeTitle = 'Націнка за місце'

interface SurchargePriceProps {
  surchargePrice: number
  currencyTitle: string
}

export const SurchargePrice = ({ currencyTitle, surchargePrice }: SurchargePriceProps) => (
  <Tooltip title={surchargeTitle} aria-label={surchargeTitle}>
    <Typography variant="body2" component="span">
      (+{surchargePrice} {currencyTitle})
    </Typography>
  </Tooltip>
)
