import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  apiCustomerSocialsDelete,
  customerQueryKeys,
  CustomerSocialNetwork,
} from '@klr/api-connectors'
import { handleBackendError, useConfirm } from '@klr/shared'

import { useCustomerUIStore } from '../../../../store'

export const useDeleteSocialNetwork = (successCallback?: VoidFunction) => {
  const customerId = useCustomerUIStore((state) => state.customerId)
  const confirm = useConfirm()
  const queryClient = useQueryClient()

  const { mutate, mutateAsync } = useMutation({
    mutationKey: [...customerQueryKeys.socials(customerId), 'delete'],
    mutationFn: apiCustomerSocialsDelete,
    onSuccess: () => {
      queryClient.invalidateQueries(customerQueryKeys.socials(customerId)).then(() => {
        toast.success('Посилання успішно видалено')
      })
    },
    onError: (error) => handleBackendError(error),
  })

  return (socialId: CustomerSocialNetwork['id'] | null) => {
    if (customerId && socialId) {
      confirm({
        title: 'Ви впевнені, що хочете видалити це посилання?',
      }).then((success) => {
        if (success) {
          const request = {
            id: customerId,
            social_id: socialId,
          }

          if (successCallback) {
            mutateAsync(request).then(successCallback)
          } else {
            mutate(request)
          }
        }
      })
    }
  }
}
