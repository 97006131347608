import { useTranslation } from 'react-i18next'
import { Button, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { apiCustomersByQuery, ByQuery, CustomerDTO, customerQueryKeys } from '@klr/api-connectors'
import { copyToClipboard, Fallback } from '@klr/shared'

import { clearCustomerData, setCustomerCardId, toggleCustomerCardDrawer } from '../../../store'

interface ClientListProps {
  onClose(): void
  phoneNumber: string
}

export const CustomerList = ({ onClose, phoneNumber }: ClientListProps) => {
  const { t } = useTranslation()

  const filterRequest: ByQuery.Request<CustomerDTO> = {
    filters: [
      {
        field: 'phone',
        value: phoneNumber,
        operator: ByQuery.Operators.CONTAINS,
      },
    ],
  }

  const { data: customerList, isFetching } = useQuery({
    queryKey: customerQueryKeys.list(filterRequest),
    queryFn: async () => {
      const response = await apiCustomersByQuery(filterRequest)

      return response.data.items
    },
    enabled: !!phoneNumber,
  })

  const handleOpenCustomerDrawer = (customerId: CustomerDTO['id'] | null) => {
    onClose()
    setCustomerCardId(customerId)
    toggleCustomerCardDrawer(true)

    if (!customerId) {
      clearCustomerData()
    }
  }

  if (!phoneNumber) {
    return null
  }

  if (isFetching) {
    return <Fallback />
  }

  if (customerList && !customerList?.length) {
    return (
      <Stack spacing={1} alignItems="flex-start">
        <Typography>{t('Messages.no_customers_found')}</Typography>
        <Typography variant="body2">{t('Customers.create_explanation_text')}</Typography>
        <Button
          onClick={() => {
            handleOpenCustomerDrawer(null)
            copyToClipboard(phoneNumber, t('Messages.phone_number_copied'))
          }}
        >
          {t('Customers.create_customer')}
        </Button>
      </Stack>
    )
  }

  return (
    <List>
      {customerList?.map((item) => (
        <ListItemButton key={item.id} onClick={() => handleOpenCustomerDrawer(item.id)}>
          <ListItemText
            primary={<Typography>{item.phone}</Typography>}
            secondary={
              <Typography color="text.disabled">{`${item.first_name ?? ''} ${item.last_name ?? ''}`}</Typography>
            }
          />
        </ListItemButton>
      ))}
    </List>
  )
}
