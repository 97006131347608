import { X as CloseIcon } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'

import { uiActions } from '../../_modules_/ui'

type Props = {
  title: string
  closeDrawer?: VoidFunction
}

export const HeaderDrawer = ({ closeDrawer, title }: Props) => {
  const dispatch = useDispatch()

  const _handleClose = () => {
    if (closeDrawer) {
      closeDrawer()
    } else {
      dispatch(uiActions.closeDetailView())
    }
  }

  return (
    <Grid sx={{ mb: 2 }} container justifyContent="space-between" alignItems="center">
      <Grid item md={11}>
        <Typography variant="h4">{title}</Typography>
      </Grid>
      <Grid item md={1}>
        <Tooltip title="Закрити">
          <IconButton aria-label="close" onClick={_handleClose} size="large">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
