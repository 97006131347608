import { PayloadAction } from '@reduxjs/toolkit'

import {
  SheetPlace,
  SheetPlaceEmpty,
  SheetTransport,
  StatusPlaceEnum,
  TicketDetail,
  TicketResConfirm,
  TripTransportReqChangeStatusPlaces,
} from '@klr/api-connectors'

import { isPlaceNotEmpty } from '../helpers'
import { SheetsState } from '../sheetState'

type Options = 'places' | 'sub_sheet'

const options: Options[] = ['places', 'sub_sheet']

const getNewPlace = (oldPlace: SheetPlace, newPlace: TicketDetail): SheetPlace => ({
  ...oldPlace,
  place: newPlace.place,
  ticket_number: newPlace.ticket_number,
  paid_form_id: newPlace.paid_form,
  paid_form_name: newPlace.display_paid_form_as,
  first_name: newPlace.first_name,
  last_name: newPlace.last_name,
  phone: newPlace.phone,
  email: newPlace.email,
  information: newPlace.information ?? '',
})

export const changeTicketsReducers = {
  changeTicket: (state: SheetsState, { payload }: PayloadAction<TicketDetail>) => {
    if (state.transports?.length) {
      const idx = state.transports.findIndex(
        (t: SheetTransport) => t.id === payload.transport_id && t.trip.id === payload.trip?.id
      )

      const newIdx = state.transports[idx].places.findIndex(
        (p: SheetPlace | SheetPlaceEmpty) => p.place === payload.place
      )

      options.forEach((option) => {
        if (state.transports[idx][option as Options]) {
          state.transports[idx][option as Options]?.forEach(
            (p: SheetPlace | SheetPlaceEmpty, idxPlace: number) => {
              if (isPlaceNotEmpty(p) && p.ticket_id === payload.id) {
                if (p.place !== payload.place) {
                  state.transports[idx][option as 'places'][idxPlace] = {
                    place: p.place,
                    status: StatusPlaceEnum.AVAILABLE,
                  }

                  state.transports[idx][option as 'places'][newIdx] = getNewPlace(p, payload)
                } else {
                  state.transports[idx][option as 'places'][idxPlace] = getNewPlace(p, payload)
                }
              }
            }
          )
        }
      })

      // Пошук дочірнього квитка, для зміни його в сторі
      // Потрібно його шукати по всіх автобусах
      if (payload.adjacent_ticket_ids?.length) {
        payload.adjacent_ticket_ids.forEach((ticketId) => {
          options.forEach((option) => {
            state.transports.forEach((transport: SheetTransport) => {
              if (transport[option as Options]) {
                transport[option as Options] = transport[option as Options]?.map((place) => {
                  if (isPlaceNotEmpty(place) && place.ticket_id === ticketId) {
                    return getNewPlace(place, payload)
                  }

                  return place
                }) as SheetPlace[]
              }
            })
          })
        })
      }
    }
  },
  changePrintAt: (
    state: SheetsState,
    action: PayloadAction<{ ticketId: number; printAt: string }>
  ) => {
    const idx = state.transports.findIndex(
      (t) => t.id === state.selectTransport?.id && t.trip.id === state.selectTransport.tripId
    )

    options.forEach((option) => {
      if (state.transports[idx][option as Options]) {
        state.transports[idx][option as Options] = state.transports[idx][option as Options]?.map(
          (p) =>
            isPlaceNotEmpty(p) && p.ticket_id === action.payload.ticketId
              ? { ...p, printed_at: action.payload.printAt }
              : p
        ) as SheetPlace[]
      }
    })
  },
  changeStatusPlaces: (
    state: SheetsState,
    action: PayloadAction<TripTransportReqChangeStatusPlaces>
  ) => {
    if (state.transports?.length) {
      const idx = state.transports.findIndex(
        (t) => t.id === action.payload.transport_id && t.trip.id === action.payload.trip_id
      )

      state.transports[idx].places = state.transports[idx].places.map(
        (place: SheetPlace | SheetPlaceEmpty) => {
          let changePlace = place

          action.payload.data.places.forEach((newPlace: SheetPlaceEmpty) => {
            if (place.place === newPlace.place) {
              changePlace = newPlace
            }
          })

          return changePlace
        }
      )
    }
  },
  changeConfirmParameter: (state: SheetsState, { payload }: PayloadAction<TicketResConfirm>) => {
    if (state.transports?.length) {
      state.transports.forEach((transport: SheetTransport) => {
        transport.places.forEach((item: SheetPlace | SheetPlaceEmpty, idx: number) => {
          if (isPlaceNotEmpty(item) && item.ticket_id === payload.ticket_id) {
            transport.places[idx] = {
              ...item,
              confirmed: payload.confirmed,
            }
          }
        })

        transport.sub_sheet?.forEach((item: SheetPlace, idx: number) => {
          if (item.ticket_id === payload.ticket_id) {
            if (transport.sub_sheet) {
              transport.sub_sheet[idx].confirmed = payload.confirmed
            }
          }
        })

        transport.conflicted?.forEach((item: SheetPlace, idx: number) => {
          if (item.ticket_id === payload.ticket_id) {
            if (transport.conflicted) {
              transport.conflicted[idx].confirmed = payload.confirmed
            }
          }
        })
      })
    }
  },
}
