import { toast } from 'react-hot-toast'
import { SagaIterator } from '@redux-saga/core'
import { AxiosResponse } from 'axios'
import { call, put, select } from 'redux-saga/effects'

import {
  apiTripsChangeStations,
  apiTripsFetchTripStations,
  SheetMainTrip,
  TripResTripStations,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { sheetsActions, sheetsSelectors } from '../../../sheets'
import { uiActions } from '../../../ui'
import { tripsActions } from '../../index'
import { ChangeStationsAsync } from '../../types'

export function* changeStationWorker({ payload }: ChangeStationsAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    // Api call or some async actions
    const { data } = yield call(apiTripsChangeStations, payload.request)

    if (data) {
      // При изменение станций нужно обновить станции что в sheet для покупки билетов
      if (payload.isSheet) {
        const mainInfo: SheetMainTrip = yield select(sheetsSelectors.getMainInfo)

        const responseStations: AxiosResponse<TripResTripStations> = yield call(
          apiTripsFetchTripStations,
          mainInfo.id
        )

        if (responseStations) {
          yield put(sheetsActions.saveStations(responseStations.data.items))
        }
      }

      yield put(tripsActions.changeStation(data.items))
      yield put(uiActions.closeDetailView())

      toast.success('Успішно!')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
