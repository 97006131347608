export const enum RqKeysEnum {
  // Manage Routes
  ROUTE_PLACES = 'ROUTE_PLACES',
  // Manage Trips
  TRIP_STATIONS = 'TRIP_STATIONS',
  // Manage Orders
  ORDER_DETAIL = 'ORDER_DETAIL',
  // Manage Tickets
  TICKET_DETAIL = 'TICKET_DETAIL',
  TICKET_PRICE = 'TICKET_PRICE',
  // Manage Locations
  CITIES = 'CITIES',
  // Manage Carriers
  BUSES = 'BUSES',
  // Manage Schemes
  SCHEMES = 'SCHEMES',
}

export const enum RqKeysEnumAction {
  FETCH = 'fetch',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}
