import React from 'react'

import {
  useMutateDispatchToDriver,
  useMutateDispatchToMessage,
  useMutateFetchPrintPdf,
  useMutateToggleBlockPlace,
  useMutateToggleSubSheet,
  useShowCreateTicket,
  useShowDetailTicket,
  useTransferToDate,
  useTransferToTransport,
} from '../../hooks'

import type { HandlersContextValue, SheetSettingsProviderProps } from './types'

const SheetHandlersContext = React.createContext<Partial<HandlersContextValue>>({})

export const SheetHandlersProvider = ({
  currentTransport,
  children,
}: SheetSettingsProviderProps) => {
  const { handleDispatchToDriver } = useMutateDispatchToDriver({
    transportTripId: currentTransport.trip.id,
    transportId: currentTransport.id,
  })
  const { handleDispatchToMessage } = useMutateDispatchToMessage()
  const { handleFetchPrintPdf } = useMutateFetchPrintPdf()
  const { handleToggleBlockPlace } = useMutateToggleBlockPlace({
    transportTripId: currentTransport.trip.id,
    transportId: currentTransport.id,
  })
  const { handleToggleSubSheet } = useMutateToggleSubSheet()
  const { showCreateTicketCopy, showCreateTicketEmptyPlace } = useShowCreateTicket()
  const { handleShowTicketDetail } = useShowDetailTicket()
  const { handleTransferToDate } = useTransferToDate()
  const { handleTransferToTransport, handleTransferToTransportMultiple } = useTransferToTransport()

  return (
    <SheetHandlersContext.Provider
      value={{
        handleToggleSubSheet,
        handleFetchPrintPdf,
        handleDispatchToDriver,
        handleToggleBlockPlace,
        showCreateTicketCopy,
        showCreateTicketEmptyPlace,
        handleShowTicketDetail,
        handleTransferToTransport,
        handleTransferToTransportMultiple,
        // Detail
        handleTransferToDate,

        // Array
        handleDispatchToMessage,
      }}
    >
      {children}
    </SheetHandlersContext.Provider>
  )
}

export default SheetHandlersContext
