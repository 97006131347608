import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  ClearOutlined as DeleteIcon,
  PersonSearchOutlined as PersonSearchIcon,
} from '@mui/icons-material'
import { Button, ButtonGroup, IconButton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material'

import { CustomerDTO, NSuccessMessage } from '@klr/api-connectors'
import { CallList } from '@klr/binotel'
import {
  clearCustomerData,
  DrawerSearchCustomer,
  setCustomerCardId,
  setSearchCustomerPhoneNumber,
  toggleCustomerCardDrawer,
  toggleSearchCustomerDrawer,
  useCustomerStore,
} from '@klr/customers'
import { ClientIcon } from '@klr/shared'

import { sheetsActions } from '../../../../../_modules_/sheets'
import { toggleSearchPassengerDrawer } from '../../../../../_modules_/sheets/store'

interface CustomerPanelProps {
  onShowSearchPassenger: (payload: string) => void
}

export const CustomerPanel = ({ onShowSearchPassenger }: CustomerPanelProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const customer = useCustomerStore((state) => state.mainInfo)

  const handleCallListMessageInfo = useCallback(
    (notifications: NSuccessMessage[]) =>
      dispatch(
        sheetsActions.addMessageInfo({
          notifications,
        })
      ),
    [dispatch]
  )

  const showSearchCustomer = (payload: string) => {
    setSearchCustomerPhoneNumber(payload)
    toggleSearchCustomerDrawer(true)
    toggleSearchPassengerDrawer(false)
  }

  const handleOpenCustomerDrawer = (payload: CustomerDTO['id'] | null) => {
    setCustomerCardId(payload)
    toggleCustomerCardDrawer(true)
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <CallList
          onShowSearchPassenger={onShowSearchPassenger}
          onShowSearchCustomer={showSearchCustomer}
          onMessageInfo={handleCallListMessageInfo}
        />

        {customer ? (
          <ButtonGroup variant="outlined" color="info" size="small">
            <Tooltip title={t('Customers.search')}>
              <Button onClick={() => showSearchCustomer('')}>
                <SvgIcon>
                  <PersonSearchIcon />
                </SvgIcon>
              </Button>
            </Tooltip>

            <Button
              onClick={() => handleOpenCustomerDrawer(customer.id)}
              startIcon={<ClientIcon />}
            >
              <Stack>
                <Typography component="span" variant="body2">
                  {customer.phone}
                </Typography>
                <Typography
                  component="span"
                  variant="caption"
                >{`${customer.first_name ?? ''} ${customer.last_name ?? ''}`}</Typography>
              </Stack>
            </Button>
            <Button onClick={clearCustomerData}>
              <SvgIcon>
                <DeleteIcon />
              </SvgIcon>
            </Button>
          </ButtonGroup>
        ) : (
          <Tooltip title={t('Customers.search')}>
            <IconButton color="inherit" onClick={() => showSearchCustomer('')} size="large">
              <SvgIcon>
                <PersonSearchIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <DrawerSearchCustomer />
    </>
  )
}
