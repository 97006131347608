import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { DetailDrawer, DetailModal, DetailTicketReportDrawer, UiState } from './types'

const defaultDetailDrawer: DetailDrawer = {
  meta: null,
  payload: null,
  cancelActions: [],
}

const defaultDetailModal: DetailModal = {
  meta: null,
  payload: null,
  cancelActions: [],
}

const initialState: UiState = {
  loadingList: false,
  loadingExternalList: false,
  loadingDetail: false,
  loadingHandlers: false,
  isShowDetail: false,
  detailView: defaultDetailDrawer,
  isShowModal: false,
  detailModal: defaultDetailModal,
}

const slice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    showDetailView: (state: UiState, action: PayloadAction<DetailDrawer>) => {
      state.isShowDetail = true
      state.detailView.meta = action.payload.meta

      if (action.payload.payload) {
        state.detailView.payload = action.payload.payload
      }
    },
    showDetailOrderView: (state: UiState, action: PayloadAction<DetailTicketReportDrawer>) => {
      state.isShowModal = true
      state.detailModal.meta = action.payload.meta

      if (action.payload.payload) {
        state.detailModal.payload = action.payload.payload
      }
    },
    closeDetailView: (state: UiState) => {
      state.isShowDetail = false

      state.detailView = defaultDetailDrawer
    },
    showDetailModal: (state: UiState, action: PayloadAction<DetailModal>) => {
      state.isShowModal = true
      state.detailModal.meta = action.payload.meta

      if (action.payload.payload) {
        state.detailModal.payload = action.payload.payload
      }
    },
    closeDetailModal: (state: UiState) => {
      state.isShowModal = false

      state.detailModal = defaultDetailModal
    },
    toggleLoadingList: (state: UiState, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload
    },
    toggleLoadingExternalList: (state: UiState, action: PayloadAction<boolean>) => {
      state.loadingExternalList = action.payload
    },
    toggleLoadingDetail: (state: UiState, action: PayloadAction<boolean>) => {
      state.loadingDetail = action.payload
    },
    toggleLoadingHandlers: (state: UiState, action: PayloadAction<boolean>) => {
      state.loadingHandlers = action.payload
    },
    startFetching: (state: UiState) => {
      state.loadingList = true
    },
    stopFetching: (state: UiState) => {
      state.loadingList = false
    },
  },
})

export default slice.reducer
export const uiActions = slice.actions
