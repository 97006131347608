import { ChangeEvent, Fragment, useMemo, useState } from 'react'
import { Clipboard as ClipboardIcon, Filter as FilterIcon } from 'react-feather'
import { useSessionStorage } from 'react-use'
import {
  AccountBox as AccountBoxIcon,
  PermPhoneMsgOutlined as CustomerIcon,
  Sms as SmsIcon,
} from '@mui/icons-material'
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import orderBy from 'lodash/orderBy'

import { NSuccessMessage, SheetBinotelMessageCall } from '@klr/api-connectors'
import { ModalSendNotifications } from '@klr/message-dispatch'
import { copyToClipboard, getTrimmedPhoneNumber } from '@klr/shared'

import { IconStatusCall } from './IconStatusCall'

const StyledRoot = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  maxHeight: 500,
  overflow: 'auto',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}))

const StyledList = styled(List)(() => ({
  position: 'relative',
  height: '100%',
}))

interface ContentPopoverProps {
  phones: Record<string, SheetBinotelMessageCall> | null
  onMessageInfo?(notifications: NSuccessMessage[]): void
  onShowSearchCustomer?(payload: string): void
  onShowSearchPassenger(payload: string): void
}

export const ContentPopover = ({
  phones,
  onMessageInfo,
  onShowSearchCustomer,
  onShowSearchPassenger,
}: ContentPopoverProps) => {
  const theme = useTheme()

  const [isFilterInternal, setFilterInternal] = useState(false)
  const [phoneForMessage, setPhoneForMessage] = useState('')
  const [isShowModalNotification, setIsShowModalNotification] = useState(false)

  const [internalNumber, setInternalNumber] = useSessionStorage('ip-atc-connect_internalNumber', '')

  const openModalNotification = (phoneNumber: string) => {
    if (phoneNumber) {
      setPhoneForMessage(phoneNumber)
      setIsShowModalNotification(true)
    }
  }

  const closeModalNotification = () => {
    setIsShowModalNotification(false)
    setPhoneForMessage('')
  }

  const getPhones = useMemo(() => {
    if (phones) {
      const result = orderBy(
        Object.keys(phones).map((key) => phones[key]),
        'updateAt',
        'desc'
      )

      if (isFilterInternal) {
        return result.filter((item) => item.internalNumber === internalNumber)
      }

      return result
    }

    return []
  }, [internalNumber, isFilterInternal, phones])

  const _renderContent = () => {
    if (!phones) {
      return <ListSubheader>Очікується дзвінок...</ListSubheader>
    }

    return (
      <StyledList disablePadding dense>
        {isFilterInternal && !getPhones.length ? (
          <ListSubheader>Відсутні дані</ListSubheader>
        ) : (
          getPhones.map((item) => (
            <Fragment key={item.generalCallID}>
              <ListItem>
                <ListItemIcon
                  sx={
                    item.internalNumber === internalNumber
                      ? { color: theme.palette.success.light }
                      : null
                  }
                >
                  <IconStatusCall eventName={item.eventName} disposition={item.disposition} />
                </ListItemIcon>
                <ListItemText primary={item.externalNumber} secondary={item.customerName} />
                <ListItemSecondaryAction>
                  {onShowSearchCustomer && (
                    <IconButton
                      onClick={() =>
                        onShowSearchCustomer(getTrimmedPhoneNumber(item.externalNumber))
                      }
                      size="small"
                    >
                      <CustomerIcon fontSize="small" />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() =>
                      onShowSearchPassenger(getTrimmedPhoneNumber(item.externalNumber))
                    }
                    size="small"
                  >
                    <AccountBoxIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => copyToClipboard(item.externalNumber, 'Номер скопійований')}
                    size="small"
                  >
                    <SvgIcon fontSize="small">
                      <ClipboardIcon />
                    </SvgIcon>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      openModalNotification(getTrimmedPhoneNumber(item.externalNumber))
                    }
                    size="small"
                  >
                    <SmsIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ModalSendNotifications
                handleCloseModal={closeModalNotification}
                isShowModal={isShowModalNotification}
                titleModal="Відправка повідомлення"
                phoneNumber={phoneForMessage}
                onMessageInfo={onMessageInfo}
              />
            </Fragment>
          ))
        )}
      </StyledList>
    )
  }

  return (
    <StyledRoot>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h4" color="textPrimary">
            IP АТС
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <IconButton
            disabled={!internalNumber}
            color={isFilterInternal ? 'primary' : 'default'}
            onClick={() => setFilterInternal((prevState) => !prevState)}
            size="large"
          >
            <Tooltip title="Фільтрувати">
              <SvgIcon fontSize="small">
                <FilterIcon />
              </SvgIcon>
            </Tooltip>
          </IconButton>
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={internalNumber}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setInternalNumber(event.target.value)
            }
            label="Канал"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {_renderContent()}
    </StyledRoot>
  )
}
