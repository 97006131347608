import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { apiTicketsDelete, ordersQueryKeys, TicketReqDelete } from '@klr/api-connectors'

import { RqKeysEnum, RqKeysEnumAction } from '../../../constants/rq-keys.enum'
import { sheetsActions } from '../../sheets'
import { PayloadDelete } from '../types'

type Props = PayloadDelete & {
  orderId: number
}

export const useDeleteTicket = ({ ticketId, ticketNumber, orderId, cause, isSheet }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const request: TicketReqDelete = {
    ticketId,
    params: {},
  }

  if (cause) {
    request.params.information = cause
  }

  const { mutate, isLoading } = useMutation(() => apiTicketsDelete(request), {
    onSuccess: (response) => {
      if (response.status === 204) {
        queryClient.invalidateQueries(ordersQueryKeys.detail(orderId)).then(() => {
          if (isSheet) {
            dispatch(sheetsActions.deleteTicket(ticketNumber))
          }

          toast.success(`${t('Orders.ticket_deleted')} 🗑`)
        })
      }
    },
    onError: () => {
      toast.error(t('Messages.failed_to_delete_ticket'))
    },
    mutationKey: [RqKeysEnum.TICKET_DETAIL, RqKeysEnumAction.DELETE],
  })

  return {
    mutate,
    isLoading,
  }
}
