import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface Features {
  show_route_analytics: boolean
  show_traffic_workload: boolean
}

interface FeaturesStore {
  features: Features | undefined
}

export const useFeaturesStore = create<FeaturesStore>()(
  devtools(
    persist(
      (_) => ({
        features: {
          show_route_analytics: false,
          show_traffic_workload: false,
        },
      }),
      { name: 'FeaturesStore', version: 1 }
    )
  )
)
