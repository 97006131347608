import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CircularProgress, Grid } from '@mui/material'

import { SeatCategory } from '@klr/api-connectors'
import { useGetPlaceCategoryPrices } from '@klr/system-entities'

import { useFetchPrice } from '../../../../../../tickets/hooks'
import { useSchemePassengersStore } from '../../../../../hooks/store'

import { PriceEditForm } from './components/PriceEditForm'
import { PriceView } from './components/PriceView'

type Props = {
  category: SeatCategory | null
  stationFromId: number
  stationToId: number
  currencyId: number
  promotionValue: string
  index: number
  mainTripId: number | null
  promotionId: number
}

export const PriceTicket = forwardRef(
  (
    {
      category,
      stationFromId,
      stationToId,
      promotionId,
      promotionValue,
      currencyId,
      mainTripId,
      index,
    }: Props,
    ref: Ref<{ parentSubmit: (value: string) => void }>
  ) => {
    const [editMode, setEditMode] = useState(false)

    const { register, setValue, clearErrors } = useFormContext()

    const hasPriceFieldName = `tickets[${index}].hasPrice`
    const customPriceFieldName = `tickets[${index}].price`

    const { price, setPrice, fetchPrice, isLoading } = useFetchPrice({
      stationFromId,
      stationToId,
      promotionId,
      currencyId,
      mainTripId,
    })

    const schemeId = useSchemePassengersStore((state) => state.schemeId)

    const { data, isFetching: loadingSurcharge } = useGetPlaceCategoryPrices(
      {
        transport_scheme_id: schemeId,
        currency_id: currencyId,
        trip_id: mainTripId ?? undefined,
      },
      {
        enabled: !!schemeId && !!currencyId && !!category,
      }
    )

    const surChargePrice = useMemo(() => {
      if (data) {
        return data.find((item) => item.category === category)?.price ?? 0
      }

      return 0
    }, [data, category])

    useEffect(() => {
      fetchPrice()
    }, [fetchPrice])

    useEffect(() => {
      if (price?.price_full) {
        setValue(hasPriceFieldName, true)
        setValue(customPriceFieldName, price.price_full)
        clearErrors(hasPriceFieldName)
      } else {
        setValue(hasPriceFieldName, false)
        setValue(customPriceFieldName, 0)
      }
    }, [clearErrors, customPriceFieldName, hasPriceFieldName, price?.price_full, setValue])

    useImperativeHandle(ref, () => ({
      parentSubmit() {
        if (promotionValue && promotionId === 24) {
          fetchPrice(promotionValue)
        }
      },
    }))

    if (isLoading || loadingSurcharge) {
      return <CircularProgress />
    }

    return (
      <Grid item xs={surChargePrice ? 8 : 7}>
        <input hidden type="checkbox" {...register(hasPriceFieldName)} />

        {editMode ? (
          <PriceEditForm
            price={price}
            setPrice={setPrice}
            setEditMode={setEditMode}
            surchargePrice={surChargePrice}
          />
        ) : (
          <PriceView price={price} setEditMode={setEditMode} surchargePrice={surChargePrice} />
        )}
      </Grid>
    )
  }
)
