import { RouteReqFetchListRequest } from '@klr/api-connectors'
import { defaultCurrencyId } from '@klr/system-entities'

import { SheetTicketFormFields } from '../validations'

export enum MetaCreateTicketEnum {
  COPY = 'COPY',
  EMPTY = 'EMPTY',
  QUICK = 'QUICK',
}

export const DEFAULT_TICKET: SheetTicketFormFields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  information: '',
  stationFrom: null,
  stationTo: null,
  paidForm: null,
  place: null,
  ticketNumber: '',
  promotion: null,
  price: 0,
  currencyId: defaultCurrencyId,
  hasPrice: true,
  promotionValue: '',
}

export const DEFAULT_ROUTE_PARAMS: RouteReqFetchListRequest = {
  is_active: 1,
  sort_field: 'carrier_name',
  sort: 'asc',
}
