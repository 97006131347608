export enum SeatCategory {
  PREMIUM = 'premium_seat',
  PREMIUM_COMFORT = 'premium_comfort',
  PREMIUM_PANORAMA = 'premium_panorama',
  PREMIUM_TABLE = 'premium_table',
  PREMIUM_EXTRA_DISTANCE = 'premium_extra_distance',
  STANDARD = 'standard_seat',
  TABLE = 'table_seat',
  TABLE_REVERSE = 'table_seat_reverse',
}
