import { X as CloseIcon } from 'react-feather'
import { useDispatch } from 'react-redux'
import { DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { uiActions } from '../../_modules_/ui'

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}))

type Props = {
  title: string
  closeDrawer?: VoidFunction
}

export const HeaderModal = ({ closeDrawer, title }: Props) => {
  const dispatch = useDispatch()

  const _handleClose = () => {
    if (closeDrawer) {
      closeDrawer()
    } else {
      dispatch(uiActions.closeDetailModal())
    }
  }

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <Typography variant="h4" component="span">
        {title}
      </Typography>
      <Tooltip title="Закрити">
        <StyledCloseButton aria-label="close" onClick={_handleClose} size="large">
          <CloseIcon fontSize="large" />
        </StyledCloseButton>
      </Tooltip>
    </DialogTitle>
  )
}
