import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  apiPlaceCategoryPricesFetchList,
  PlaceCategoryPriceDTO,
  PlaceCategoryPriceReqFetchList,
  PlaceCategoryPricesQueryKeys,
  placeCategoryPricesQueryKeys,
} from '@klr/api-connectors'

export const useGetPlaceCategoryPrices = (
  params: PlaceCategoryPriceReqFetchList,
  options?: Omit<
    UseQueryOptions<
      PlaceCategoryPriceDTO[],
      AxiosError,
      PlaceCategoryPriceDTO[],
      PlaceCategoryPricesQueryKeys['list']
    >,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: placeCategoryPricesQueryKeys.list(params),
    queryFn: async () => {
      const {
        data: { data },
      } = await apiPlaceCategoryPricesFetchList(params)
      return data
    },
    enabled: !!params.transport_scheme_id && !!params.currency_id,
    ...options,
  })
}
