import { toast } from 'react-hot-toast'
import { AxiosResponse } from 'axios'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import { apiTicketsDelete, TicketReqDelete } from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { DeleteAsync } from '../../types'

export function* deleteWorker({ payload }: DeleteAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    const request: TicketReqDelete = {
      ticketId: payload.ticketId,
      params: {},
    }

    if (payload.cause) {
      request.params.information = payload.cause
    }

    const { status }: AxiosResponse = yield call(apiTicketsDelete, request)

    if (status === 204) {
      if (payload.isSheet) {
        yield put(sheetsActions.deleteTicket(payload.ticketNumber))
      }

      toast.success('Квиток видалено 🗑')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
