import { isValid } from 'date-fns'

import { CustomerDetailDTO, CustomerSocialNetwork } from '@klr/api-connectors'
import { formatToFullDateString, getEmptyMessage, parseDateString } from '@klr/shared'

import { socialData, SocialDataType } from './customer.constants'

export function getCustomerSocialListSnippet(initialData: CustomerSocialNetwork[]) {
  return initialData.reduce<SocialDataType[]>(
    (acc, item) => {
      const socialIndex = acc.findIndex((accItem) => accItem.type === item.type)

      if (socialIndex !== -1) {
        acc[socialIndex] = {
          ...acc[socialIndex],
          id: item.id,
          link: item.link,
        }
      }

      return acc
    },
    [...socialData]
  )
}

const getBirthday = (birthday: string | null) => {
  if (!birthday) return getEmptyMessage(null)

  let birthDate = parseDateString(birthday)

  if (!isValid(birthDate)) {
    birthDate = new Date(birthday)
  }

  return formatToFullDateString(birthDate)
}

export function getCustomerMainInfo(customerDetail: CustomerDetailDTO | null) {
  const customerName =
    customerDetail?.first_name || customerDetail?.last_name
      ? `${customerDetail?.first_name ?? ''} ${customerDetail?.last_name ?? ''}`
      : getEmptyMessage(null)

  const customerGender = getEmptyMessage(customerDetail?.gender)

  return [
    {
      label: 'full_name',
      value: customerName,
    },
    {
      label: 'email',
      value: getEmptyMessage(customerDetail?.email),
    },
    {
      label: 'gender',
      value: customerGender,
    },
    {
      label: 'birthdate',
      value: getBirthday(customerDetail?.birthday ?? null),
    },
    {
      label: 'phone',
      value: getEmptyMessage(customerDetail?.phone),
    },
    {
      label: 'additional_phone',
      value: getEmptyMessage(customerDetail?.add_phone),
    },
    {
      label: 'description',
      value: getEmptyMessage(customerDetail?.description),
    },
  ]
}
