import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import * as Sentry from '@sentry/react'

import { CustomerDrawer } from '@klr/customers'
import { OrderDetailProvider } from '@klr/order-ticket-toolkit'
import { AlertError, Main, useBoolean } from '@klr/shared'

import { DrawerCreateTickets } from '../../_modules_/sheets/layout-components/DrawerCreateTickets'
import { DrawerSearchPassenger } from '../../_modules_/sheets/layout-components/DrawerSeacrhPassenger'
import { DrawerDetail, ModalDetail } from '../../components'
import { environments } from '../../environments'
import { NavVertical } from '../components/NavVertical'

import { Header } from './components/Header'
import { useInitRoutes } from './useInitRoutes'

export const SheetLayout = () => {
  const [nav, toggleNav] = useBoolean()
  useInitRoutes()

  return (
    <>
      <Header onOpenNav={toggleNav.on} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <NavVertical openNav={nav} onCloseNav={toggleNav.off} />

        <Main>
          <Sentry.ErrorBoundary fallback={(errorData) => <AlertError {...errorData} />}>
            <OrderDetailProvider marketplaceURL={environments.marketplaceURL}>
              <Outlet />
              {/*Display DrawerDetail after click on order in a list in CustomerDrawer */}
              <CustomerDrawer />
            </OrderDetailProvider>
          </Sentry.ErrorBoundary>

          <DrawerCreateTickets />
          <DrawerSearchPassenger />
          <DrawerDetail />
          <ModalDetail />
        </Main>
      </Box>
    </>
  )
}
