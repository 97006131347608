import { ArrayFields } from '@klr/shared'

export const formFields: ArrayFields[] = [
  {
    name: 'firstName',
    label: "Ім'я пасажира",
    type: 'text',
    required: false,
    placeholder: 'Іван',
  },
  {
    name: 'lastName',
    label: 'Прізвище',
    type: 'text',
    required: false,
    placeholder: 'Іванов',
  },
  {
    name: 'email',
    label: 'Електронна пошта',
    type: 'email',
    required: false,
    placeholder: 'ivanov.ivan@example.com',
  },
  {
    name: 'ticketNumber',
    label: 'Номер квитка',
    type: 'text',
    required: false,
    placeholder: '___________',
  },
]
