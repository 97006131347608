import { isValid } from 'date-fns'

import { STATUSES, StatusGroupEnum, StatusTicketEnum } from '@klr/api-connectors'
import { BasicOptions, formatToUkrShortDateTimeString, parseDateTimeString } from '@klr/shared'

interface AllowedActionsTicketProps {
  hasFullAccess: boolean
  isEarlyBooking: boolean
  status: StatusTicketEnum
  orderStatus?: StatusTicketEnum
}

export const getAllowedActionsTickets = (props: AllowedActionsTicketProps) => {
  const { isEarlyBooking, status, orderStatus, hasFullAccess } = props

  return {
    hasPayReserved: StatusTicketEnum.RESERVED === status, // Внести дані про оплату
    hasLiqPay: StatusTicketEnum.RETURN === status && StatusTicketEnum.PAID === orderStatus, // Повернути кошти через LiqPay
    hasDeleteTicket:
      (StatusTicketEnum.RESERVED === status ||
        StatusTicketEnum.PAID === status ||
        StatusTicketEnum.CASH === status ||
        StatusTicketEnum.CASH_DISP === status) &&
      !isEarlyBooking, // Видалити квиток
    hasReturnedTicket: StatusTicketEnum.RETURN === status, // Квиток повернутий
    hasReturnTicket:
      ((hasFullAccess &&
        [StatusTicketEnum.PAID, StatusTicketEnum.CASH, StatusTicketEnum.CASH_DISP].includes(
          status
        )) ||
        [StatusTicketEnum.PAID, StatusTicketEnum.CASH_DISP].includes(status)) &&
      !isEarlyBooking, // Повернути квиток
    hasPrintTicket:
      StatusTicketEnum.RETURN !== status &&
      StatusTicketEnum.CHANGED !== status &&
      StatusTicketEnum.DEFERRED !== status,
    isShowPaymentLink: status !== StatusTicketEnum.PAID && status !== StatusTicketEnum.CASH_DISP, // Згенерувати посилання на оплату по LiqPay
  }
}

export const getStatusesByStatusGroup = (
  group: string | typeof StatusGroupEnum,
  shouldReturnEmptyAsAll = true
): BasicOptions[] => {
  const returned = [
    StatusTicketEnum.RETURN.toString(),
    StatusTicketEnum.CANCEL.toString(),
    StatusTicketEnum.CHANGED.toString(),
  ]

  switch (group as StatusGroupEnum) {
    case StatusGroupEnum.NOT_RETURNED:
      return STATUSES.filter((status) => !returned.includes(status.value))
    case StatusGroupEnum.RETURNED:
      return STATUSES.filter((status) => returned.includes(status.value))
    default:
      return shouldReturnEmptyAsAll ? [] : STATUSES
  }
}

export const getStatusesNamesByStatusGroup = (
  group: string | typeof StatusGroupEnum,
  shouldReturnEmptyAsAll = true
): string[] => getStatusesByStatusGroup(group, shouldReturnEmptyAsAll).map((status) => status.value)

export const getStatusByPartnerAttribute = (status: StatusTicketEnum, canReserve: boolean) => {
  if (status === StatusTicketEnum.CASH && canReserve) {
    return StatusTicketEnum.CASH_RESERVED
  }

  return status
}

export const getDateInterval = (
  tripEndDateTime: string | null,
  tripStartDateTime: string | null
) => {
  const startDate = parseDateTimeString(tripStartDateTime ?? '')
  const endDate = parseDateTimeString(tripEndDateTime ?? '')

  const formattedStartDateTime = isValid(startDate) ? formatToUkrShortDateTimeString(startDate) : ''
  const formattedEndDateTime = isValid(endDate) ? formatToUkrShortDateTimeString(endDate) : ''

  return `${formattedStartDateTime} - ${formattedEndDateTime}`
}

export const checkIsTicketCancelled = (status: StatusTicketEnum) => {
  const candidates = [
    StatusTicketEnum.CANCEL,
    StatusTicketEnum.RESERVED_PENDING,
    StatusTicketEnum.CONFIRM,
    StatusTicketEnum.PENDING,
    StatusTicketEnum.PAYMENT_FAILURE,
    StatusTicketEnum.OPENDATE,
  ]

  return candidates.includes(status)
}

export const getStatusColor = (
  status: StatusTicketEnum
): { color: 'warning' | 'info' | 'error' | 'success'; title: string } => {
  switch (status) {
    case StatusTicketEnum.DEFERRED:
      return { color: 'warning', title: 'pending' }
    case StatusTicketEnum.RESERVED:
      return { color: 'info', title: 'reserved' }
    case StatusTicketEnum.CANCEL:
    case StatusTicketEnum.RETURN:
      return { color: 'error', title: 'return' }
    case StatusTicketEnum.PAID:
    case StatusTicketEnum.CASH:
    case StatusTicketEnum.CASH_DISP:
      return { color: 'success', title: 'cash_disp' }
    default:
      return { color: 'info', title: '' }
  }
}
