import { memo, useEffect } from 'react'
import { Box, Drawer, Stack } from '@mui/material'

import { Logo, NAV, NavToggleButton, Scrollbar, usePathname, useResponsive } from '@klr/shared'

import { useNavConfig } from '../config-navigation'

import { NavSectionVertical } from './components/NavSectionVertical'

type Props = {
  showToggleButton?: boolean
  openNav: boolean
  onCloseNav: VoidFunction
}

export const NavVertical = memo(({ openNav, onCloseNav, showToggleButton = false }: Props) => {
  const pathname = usePathname()

  const lgUp = useResponsive('up', 'lg')

  const navData = useNavConfig()

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

      <NavSectionVertical data={navData} />
    </Scrollbar>
  )

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: showToggleButton ? NAV.W_VERTICAL : 0 },
      }}
    >
      {showToggleButton && <NavToggleButton />}

      {lgUp && showToggleButton ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
})
