import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { apiTicketsChange, ordersQueryKeys } from '@klr/api-connectors'

import { RqKeysEnum, RqKeysEnumAction } from '../../../constants/rq-keys.enum'
import { uiActions } from '../../ui'

export const useUpdateTicket = (orderId: number) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(apiTicketsChange, {
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.detail(orderId)).then(() => {
        dispatch(uiActions.closeDetailModal())
        toast.success(t('Messages.ticket_successfully_updated'))
      })
    },
    onError: () => {
      toast.error(t('Messages.failed_to_update_ticket'))
    },
    mutationKey: [RqKeysEnum.TICKET_DETAIL, RqKeysEnumAction.UPDATE],
  })

  return {
    mutate,
    isLoading,
  }
}
