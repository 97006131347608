import { PayloadAction } from '@reduxjs/toolkit'

import { SheetPlace, SheetPlaceEmpty, SheetTransport, StatusPlaceEnum } from '@klr/api-connectors'

import { isPlaceNotEmpty } from '../helpers'
import { SheetsState } from '../sheetState'

type Options = 'places' | 'sub_sheet'
const options: Options[] = ['places', 'sub_sheet']

// Ticket Number - потрібно для знаходження усіх квитків в відомостях
export const deleteTicket = (state: SheetsState, { payload }: PayloadAction<string>) => {
  state.transports.forEach((transport: SheetTransport) => {
    options.forEach((option) => {
      if (transport[option]) {
        if (option === 'sub_sheet') {
          transport[option] =
            transport[option as 'sub_sheet']?.filter((place) => place.ticket_number !== payload) ||
            []
        } else {
          const idx = transport[option].findIndex(
            (place: SheetPlace | SheetPlaceEmpty) =>
              isPlaceNotEmpty(place) && place.ticket_number === payload
          )

          if (transport[option][idx]) {
            transport[option][idx] = {
              place: transport[option][idx].place,
              status: StatusPlaceEnum.AVAILABLE,
            }
          }
        }
      }
    })
  })
}
