import { create } from 'zustand'

interface SearchPassengerUIStore {
  showDrawer: boolean
  phoneNumber: string
}

export const useSearchPassengerUIStore = create<SearchPassengerUIStore>()((_) => ({
  showDrawer: false,
  phoneNumber: '',
}))

export const toggleSearchPassengerDrawer = (payload: boolean) =>
  useSearchPassengerUIStore.setState(() => ({ showDrawer: payload }))

export const setPassengerPhoneNumber = (payload: string) =>
  useSearchPassengerUIStore.setState(() => ({ phoneNumber: payload }))

export const clearAllSearchPassenger = () =>
  useSearchPassengerUIStore.setState(() => ({ showDrawer: false, phoneNumber: '' }))
