import {
  SheetPlace,
  SheetPlaceTransfer,
  TicketDetail,
  TicketReqChange,
  TicketReqCreate,
  TicketReqCustomReturnPercent,
  TicketReqFetchSeatsForTransfer,
  TicketReqReturn,
  TicketReqTransfer,
  TicketReturnCondition,
} from '@klr/api-connectors'

export enum types {
  // Async
  FETCH_DETAIL = 'TICKETS/FETCH_DETAIL',
  CREATE_ITEM = 'TICKETS/CREATE_ITEM',
  CHANGE_ITEM = 'TICKETS/CHANGE_ITEM',
  CUSTOM_RETURN_PERCENT = 'TICKETS/CUSTOM_RETURN_PERCENT',
  TOGGLE_SUB_LIST = 'TICKETS/TOGGLE_SUB_LIST',
  DELETE_ITEM = 'TICKETS/DELETE_ITEM',
  PRINT_PDF_TICKET = 'TICKETS/PRINT_PDF_TICKET',
  FETCH_SEATS_FOR_TRANSFER = 'TICKET/FETCH_SEATS_FOR_TRANSFER',
  FETCH_AVAILABLE_SEATS = 'TICKET/FETCH_AVAILABLE_SEATS',
  TRANSFER = 'TICKET/TRANSFER',
  RETURN = 'TICKET/RETURN',
  FETCH_RETURN_CONDITIONS = 'TICKET/FETCH_RETURN_CONDITIONS',
}

// STATE

export type TicketsState = {
  detail: TicketDetail | null
  loading: boolean
  availablePlaces: number[]
  returnCondition: TicketReturnCondition | null
  loadingCondition: boolean
}

// ASYNC
export type FetchDetailAsync = {
  type: typeof types.FETCH_DETAIL
  payload: number | string
}

export type CreateAsync = {
  type: typeof types.CREATE_ITEM
  payload: {
    isSheet: boolean
    requests: TicketReqCreate[]
  }
}

export type ChangeAsync = {
  type: typeof types.CHANGE_ITEM
  payload: {
    isSheet: boolean
    request: TicketReqChange
  }
}

export type PayloadActionToggleSubList = {
  isSheet?: boolean
  isConflicts?: boolean
  request: {
    place: SheetPlace
    isMoveSubList: boolean
  }
}

export type ToggleSubListAsync = {
  type: typeof types.TOGGLE_SUB_LIST
  payload: PayloadActionToggleSubList
}

export type PayloadDelete = {
  isSheet: boolean
  ticketId: number
  ticketNumber: string
  cause?: string
}

export type DeleteAsync = {
  type: typeof types.DELETE_ITEM
  payload: PayloadDelete
}

export type PrintPdfTicketAsync = {
  type: typeof types.PRINT_PDF_TICKET
  payload: {
    ticketId: number
    isMainPdf?: boolean
    isSheet?: boolean
  }
}

export type FetchSeatsForTransfer = {
  type: typeof types.FETCH_SEATS_FOR_TRANSFER
  payload: {
    isSheet?: boolean
    request: TicketReqFetchSeatsForTransfer
  }
}

export type PayloadTransferAsync = {
  isSheet?: boolean
  oldTransportId: number
  oldTripId: number
  place: SheetPlaceTransfer
  request: TicketReqTransfer
}
export type TransferAsync = {
  type: typeof types.TRANSFER
  payload: PayloadTransferAsync
}

export type FetchAvailableSeatsAsync = {
  type: typeof types.FETCH_AVAILABLE_SEATS
  payload: number
}

export type ReturnAsync = {
  type: typeof types.RETURN
  payload: TicketReqReturn
}

export type FetchReturnConditionsAsync = {
  type: typeof types.FETCH_RETURN_CONDITIONS
  payload: number
}

export type CustomReturnPercentAsync = {
  type: typeof types.CUSTOM_RETURN_PERCENT
  payload: TicketReqCustomReturnPercent
}

export type TicketsActionTypes =
  | FetchDetailAsync
  | CreateAsync
  | ChangeAsync
  | DeleteAsync
  | ToggleSubListAsync
  | PrintPdfTicketAsync
  | FetchSeatsForTransfer
  | TransferAsync
  | FetchAvailableSeatsAsync
  | ReturnAsync
  | FetchReturnConditionsAsync
  | CustomReturnPercentAsync
