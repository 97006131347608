import {
  apiCustomerSocialsCreate,
  apiCustomerSocialsUpdate,
  customerQueryKeys,
  CustomerReqSocialsCreate,
  CustomerReqSocialsUpdate,
  CustomerSocialEnum,
  CustomerSocialNetwork,
} from '@klr/api-connectors'
import { useMutateCreate, useMutateUpdate } from '@klr/shared'

import { useCustomerUIStore } from '../../../../store'

import { SocialNetworksFormFields } from './customer-socials.validations'

interface MutateCustomerSocialsProps {
  socialId: number | null
  socialLink: string | null
  socialType: CustomerSocialEnum
  successCallback(): void
}

export const useMutateCustomerSocials = ({
  socialId,
  socialLink,
  socialType,
  successCallback,
}: MutateCustomerSocialsProps) => {
  const customerId = useCustomerUIStore((state) => state.customerId)

  const { mutateAsync: mutateCreateAsync } = useMutateCreate<
    CustomerReqSocialsCreate,
    CustomerSocialNetwork
  >({
    queryKey: customerQueryKeys.socials(customerId),
    createFn: apiCustomerSocialsCreate,
    toastSuccessMessages: 'Посилання успішно додано',
  })

  const { mutateAsync: mutateUpdateAsync } = useMutateUpdate<
    CustomerReqSocialsUpdate['data'],
    CustomerSocialNetwork
  >({
    id: customerId!,
    queryKey: customerQueryKeys.socials(customerId),
    updateFn: apiCustomerSocialsUpdate,
    toastSuccessMessages: 'Посилання успішно оновлено',
  })

  return async (formFields: SocialNetworksFormFields) => {
    if (socialLink && socialId) {
      await mutateUpdateAsync({
        social_id: socialId,
        type: socialType,
        link: formFields.link ?? '',
      })
    } else {
      await mutateCreateAsync({
        id: customerId!,
        data: {
          type: socialType,
          link: formFields.link ?? '',
        },
      })
    }

    successCallback()
  }
}
