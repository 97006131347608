import {
  Calendar as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Clock as ClockIcon,
  CreditCard as CreditCardIcon,
  DollarSign as DollarSignIcon,
  Headphones as HeadphonesIcon,
  Loader as LoaderIcon,
  Repeat as RepeatIcon,
  RotateCcw as RotateCcwIcon,
  UserCheck as UserCheckIcon,
  X as XIcon,
} from 'react-feather'
import { Chip, chipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

import { StatusTicketEnum } from '@klr/api-connectors'

type StyledChipProps = {
  status: 'waiting' | 'info' | 'reject' | 'success'
}

const StyledChip = styled(Chip)<StyledChipProps>(({ theme, status }) => ({
  padding: '3px 6px',

  ...(status === 'success' && {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`,
  }),

  ...(status === 'waiting' && {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`,
  }),

  ...(status === 'reject' && {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.main}`,
  }),

  ...(status === 'info' && {
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  }),

  [`& .${chipClasses.iconSmall}`]: {
    width: 12,
    height: 12,
    color: 'inherit',
  },
}))

const statusesConfig = {
  deferred: {
    label: 'резервація',
    icon: ClockIcon,
    status: 'waiting',
  },
  reserved: {
    label: 'ЗБ',
    icon: UserCheckIcon,
    status: 'info',
  },
  reserved_pending: {
    label: 'ЗБ (підтвердити)',
    icon: UserCheckIcon,
    status: 'waiting',
  },
  cancel: {
    label: 'відмінений',
    icon: RotateCcwIcon,
    status: 'reject',
  },
  changed: {
    label: 'зміна статусу',
    icon: RepeatIcon,
    status: 'waiting',
  },
  return: {
    label: 'повернутий',
    icon: RotateCcwIcon,
    status: 'reject',
  },
  paid: {
    label: 'E-еквайринг',
    icon: CreditCardIcon,
    status: 'success',
  },
  confirm: {
    label: 'підтверджений',
    icon: CheckCircleIcon,
    status: 'success',
  },
  cash: {
    label: 'Сплачено',
    icon: DollarSignIcon,
    status: 'success',
  },
  cash_reserved: {
    label: 'Оплата у водія',
    icon: DollarSignIcon,
    status: 'success',
  },
  cash_disp: {
    label: 'Оплачено',
    icon: HeadphonesIcon,
    status: 'success',
  },
  pending: {
    label: 'очікує на оплату',
    icon: LoaderIcon,
    status: 'waiting',
  },
  payment_failure: {
    label: 'оплата відхилена',
    icon: XIcon,
    status: 'reject',
  },
  opendate: {
    label: 'Відкрита дата',
    icon: CalendarIcon,
    status: 'waiting',
  },
}

interface ChipStatusProps {
  status: StatusTicketEnum
}

export const ChipStatus = ({ status }: ChipStatusProps) => {
  const value = statusesConfig[status as keyof typeof statusesConfig]

  if (!value) {
    return null
  }

  const Icon = value.icon

  return (
    <StyledChip
      size="small"
      variant="outlined"
      icon={<Icon />}
      label={value.label}
      status={value.status as StyledChipProps['status']}
    />
  )
}
