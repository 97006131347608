import {
  AirlineSeatFlatAngled,
  AirlineSeatReclineExtra,
  AllInclusive,
  Panorama,
  TableBar,
  Weekend,
} from '@mui/icons-material'

import { SeatCategory } from '@klr/api-connectors'
import { MaterialIcon } from '@klr/shared'

type PremiumPlaceList = {
  title: string
  category: SeatCategory
  icon: MaterialIcon
}

export const premiumModesData: PremiumPlaceList[] = [
  {
    title: 'standard_place',
    category: SeatCategory.STANDARD,
    icon: AirlineSeatReclineExtra,
  },
  {
    title: 'premium_seat',
    category: SeatCategory.PREMIUM,
    icon: Weekend,
  },
  {
    title: 'premium_comfort',
    category: SeatCategory.PREMIUM_COMFORT,
    icon: AirlineSeatFlatAngled,
  },
  {
    title: 'premium_panorama',
    category: SeatCategory.PREMIUM_PANORAMA,
    icon: Panorama,
  },
  {
    title: 'premium_table',
    category: SeatCategory.PREMIUM_TABLE,
    icon: TableBar,
  },
  {
    title: 'premium_extra_distance',
    category: SeatCategory.PREMIUM_EXTRA_DISTANCE,
    icon: AllInclusive,
  },
]
