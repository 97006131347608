import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  apiRoutesFetchList,
  ListType,
  RouteItem,
  RouteQueryKeys,
  routeQueryKeys,
  RouteReqFetchListRequest,
} from '@klr/api-connectors'

export const useFetchRouteList = (
  params: RouteReqFetchListRequest,
  options: Omit<
    UseQueryOptions<ListType<RouteItem>, AxiosError, ListType<RouteItem>, RouteQueryKeys['list']>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const { data, ...query } = useQuery({
    ...options,
    queryKey: routeQueryKeys.list(params),
    queryFn: async () => {
      const response = await apiRoutesFetchList({ request: params })

      return response.data
    },
  })

  return {
    ...query,
    data: data?.items ?? [],
    count: data?.count ?? 0,
  }
}
