import { toast } from 'react-hot-toast'
import { push } from '@lagunovsky/redux-react-router'
import { SagaIterator } from '@redux-saga/core'
import { call, put } from 'redux-saga/effects'
import { validate as uuidValidate } from 'uuid'

import { apiTicketsCreate, TicketDetail, TicketReqCreate } from '@klr/api-connectors'
import { bookingPaths } from '@klr/booking'
import {
  AppModules,
  ERROR_MAIN,
  handleBackendError,
  transformUkrToIntDateString,
  wait,
} from '@klr/shared'

import { paths } from '../../../../router/paths'
import { sheetsActions } from '../../../sheets'
import { uiActions } from '../../../ui'
import { ticketsActions } from '../../slice'
import { CreateAsync } from '../../types'

function* createSingleTicket(newTicket: TicketReqCreate, isSheet: boolean): SagaIterator {
  try {
    const { data } = yield call(apiTicketsCreate, newTicket)

    if (isSheet) {
      yield put(sheetsActions.createTicket(data.item))
    }

    return data.item
  } catch (error) {
    handleBackendError(error, AppModules.Trip)
  }
}

export function* createWorker({ payload }: CreateAsync) {
  try {
    yield put(uiActions.toggleLoadingHandlers(true))

    const newTickets: TicketDetail[] = []
    // maybe we can use yield.all() here to simulate Promise.all()
    for (let i = 0; i < payload.requests.length; i++) {
      const newTicket: TicketDetail = yield call(
        createSingleTicket,
        payload.requests[i],
        payload.isSheet
      )
      yield call(wait, 500)
      newTickets.push(newTicket)
    }

    if (payload.isSheet) {
      yield put(sheetsActions.closeCreateDrawer())
      yield put(ticketsActions.clearDetail())
    } else if (newTickets.length) {
      // очищуємо пошук квитків нашої системи
      // ця перевірка потрібна для перевірки квитка чи він з агрегатора чи з нашої системи
      const isUuid = uuidValidate(newTickets[0].order_number)

      if (isUuid) {
        // якщо квиток з агрегатора, то редіректимо на сторінку з пошуком
        yield put(push(bookingPaths.bookingSearch))
      } else if (newTickets[0].trip) {
        const date = transformUkrToIntDateString(newTickets[0].trip.date_start)

        yield put(push(`${paths.homeSheet}${newTickets[0].trip.route.id}?date=${date}`))
      } else {
        toast.error(ERROR_MAIN)
      }
    } else {
      toast.error(ERROR_MAIN)
    }

    if (newTickets.length) {
      toast.success('Операція успішна 🎉')
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(uiActions.toggleLoadingHandlers(false))
  }
}
