import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@klr/auth'
import { bookingPaths } from '@klr/booking'
import { customerPaths } from '@klr/customers'
import { schemesPaths } from '@klr/schemes'
import { SvgColor } from '@klr/shared'

import { paths } from '../../router/paths'
import { useFeaturesStore } from '../../useFeaturesStore'

import { NavItemBaseProps, NavProps } from './shared/types'

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
)

const ICONS = {
  analytics: icon('ic_analytics'),
  banking: icon('ic_banking'),
  bus: icon('ic_bus'),
  dashboard: icon('ic_dashboard'),
  logs: icon('ic_logs'),
  menuItem: icon('ic_menu_item'),
  messages: icon('ic_messages'),
  network: icon('ic_network'),
  order: icon('ic_order'),
  roadmap: icon('ic_roadmap'),
  routeDirection: icon('ic_route_direction'),
  settings: icon('ic_settings'),
  star: icon('ic_star'),
  tour: icon('ic_tour'),
  traffic: icon('ic_traffic'),
  user: icon('ic_user'),
  currencies: icon('ic_currencies'),
}

type Section = NavProps['data']

export const useNavConfig = () => {
  const { t } = useTranslation()
  const { isAdmin, isDispatcher, isCarrier, isAccountant } = useAuth()

  const { features } = useFeaturesStore()

  return useMemo(() => {
    let navConfig: Section = []

    const adminNavConfig: Section = [
      {
        subheader: 'Адміністрування',
        items: [
          {
            title: 'Користувачі системи',
            icon: ICONS.user,
            path: paths.users,
            children: [
              {
                title: 'Агенти',
                path: paths.agents,
              },
              {
                title: 'Користувачі',
                path: paths.users,
              },
              {
                title: 'Перевізники',
                path: paths.carriers,
              },

              {
                title: 'Диспетчера',
                path: paths.dispatchers,
              },
              {
                title: "Панель керування зв'язками груп",
                path: paths.dispatcherRoutesGroups,
              },
            ],
          },

          {
            title: 'Фінанси',
            icon: ICONS.banking,
            path: paths.financePromoCodes,
            children: [
              {
                title: 'Промокоди',
                path: paths.financePromoCodes,
              },
              {
                title: 'Способи оплати',
                path: paths.financePaymentMethod,
              },

              {
                title: 'Правила динамічного тарифування',
                path: paths.financeDynamicFareRules,
              },
              {
                title: 'Знижки на маршрути',
                path: paths.financePromotions,
              },
              {
                title: 'Управління способами оплати користувачів',
                path: paths.financePaidForms,
              },
            ],
          },
          {
            title: 'Налаштування',
            icon: ICONS.settings,
            path: schemesPaths.schemes,
            children: [
              {
                title: 'Правила партнерської програми',
                path: paths.settingRuleAffiliateProgram,
              },
              {
                title: 'Станції',
                path: paths.settingsSystemLocation,
              },
              {
                title: 'Власники автобусів',
                path: paths.settingsTransportOwners,
              },
              {
                title: 'Шаблони',
                path: paths.settingsTemplates,
              },
              {
                title: 'Теги',
                path: paths.settingsTags,
              },
              {
                title: 'Схеми автобусів',
                path: schemesPaths.schemes,
              },
              {
                title: 'Інформування про зміну статусу квитка',
                path: paths.settingsNotificationClients,
              },
              {
                title: 'Telegram ID',
                path: paths.settingsTelegramData,
              },
              {
                title: 'Валюти',
                path: paths.settingsCurrencies,
              },
              {
                title: 'Філіали',
                path: paths.settingsBranches,
              },
            ],
          },
          {
            icon: ICONS.routeDirection,
            title: 'Напрямки маршрутів',
            path: paths.routeDirectionList,
          },
          {
            icon: ICONS.star,
            title: t('Customers.dashboard'),
            path: customerPaths.customerDashboard,
          },
          {
            icon: ICONS.messages,
            title: 'Повідомлення',
            path: paths.notifications,
          },
          {
            icon: ICONS.logs,
            title: 'Логування дій',
            path: paths.actionsLoggingList,
            caption: 'beta',
          },
        ],
      },
    ]

    const dispatcherNavConfig: Section = [
      {
        subheader: 'Замовлення',
        items: [
          {
            title: t('Common.booking'),
            icon: ICONS.tour,
            path: bookingPaths.bookingSearch,
          },
          {
            title: t('Orders.order'),
            icon: ICONS.order,
            path: paths.orderList,
          },
        ],
      },
      {
        subheader: 'Рейси',
        items: [
          {
            title: 'Відомості диспетчера',
            icon: ICONS.roadmap,
            path: paths.homeSheet,
          },
          {
            icon: ICONS.dashboard,
            title: 'Аналіз рейсів',
            path: paths.reportsRouteAnalytics,
          },
          features?.show_traffic_workload
            ? {
                icon: ICONS.traffic,
                title: 'Завантаженість транспорту',
                path: paths.dashboardTrafficWorkload,
              }
            : null,
        ],
      },
    ]

    const partnerNavConfig: Section = [
      {
        subheader: t('Pages.customer_referrals'),
        items: [
          {
            title: t('Pages.customer_referrals'),
            icon: ICONS.network,
            path: customerPaths.customerReferrals,
          },
          {
            title: 'Звіт по партнерській програмі',
            icon: ICONS.analytics,
            path: paths.reportsPartnerProgram,
          },
        ],
      },
    ]

    const carrierNavConfig: Section = [
      {
        subheader: 'Маршрути',
        items: [
          {
            icon: ICONS.menuItem,
            title: 'Список Маршрутів',
            path: paths.routesList,
          },
        ],
      },
    ]

    const reportsNavConfig: Section = [
      {
        subheader: 'Бухгалтерія',
        items: [
          {
            icon: ICONS.analytics,
            title: 'Звіти',
            path: paths.reportsCarriers,
            children: [
              {
                title: 'Звіти по перевізниках',
                path: paths.reportsCarriers,
              },
              {
                title: 'Звіти по агентам',
                path: paths.reportsAgents,
              },
            ],
          },
        ],
      },
    ]

    if (isAdmin) {
      navConfig = [
        ...navConfig,
        ...dispatcherNavConfig,
        ...carrierNavConfig,
        ...adminNavConfig,
        ...reportsNavConfig,
        ...partnerNavConfig,
      ]
    }

    const currencyNavItem: NavItemBaseProps = {
      icon: ICONS.currencies,
      title: 'Валюти',
      path: paths.settingsCurrencies,
    }

    if (isDispatcher) {
      navConfig = [
        ...dispatcherNavConfig,
        {
          subheader: 'Налаштування',
          items: [currencyNavItem],
        },
      ]
    }

    if (isCarrier) {
      navConfig = [
        ...carrierNavConfig,
        {
          subheader: 'Налаштування',
          items: [
            {
              icon: ICONS.bus,
              title: 'Автобуси',
              path: paths.carrierBuses,
            },
            currencyNavItem,
          ],
        },
      ]
    }

    if (isAccountant) {
      navConfig = [
        ...reportsNavConfig,
        ...dispatcherNavConfig,
        {
          subheader: 'Налаштування',
          items: [currencyNavItem],
        },
      ]
    }

    return navConfig
  }, [isAccountant, isAdmin, isCarrier, isDispatcher, features?.show_traffic_workload, t])
}
