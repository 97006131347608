export const randomString = (): string => '$' + Math.random().toString(36).slice(2, 10)

export const getPhonesFromString = (tel = ''): string[] => {
  return Array.from(tel.split(';'))
}

export const getInitials = (name = ''): string =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('')

export const priceFormatter = (value: string) => value.replace(/\d(?=(\d{3})+\.)/g, '$&,')

// remove spaces from phoneNumber if it contains them
export const getTrimmedPhoneNumber = (phoneNumber: string) => phoneNumber.replaceAll(' ', '')
