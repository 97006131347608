import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { isValid } from 'date-fns'

import { TicketDetail } from '@klr/api-connectors'
import { useActionPaidReserve } from '@klr/order-ticket-toolkit'
import { UKR_DATE_TIME_FORMAT } from '@klr/shared'

import { sheetsActions } from '../../../../sheets'

type Props = {
  onClose: VoidFunction
  ticketId: TicketDetail['id']
}

export const DialogPaidReserveInternal = ({ onClose, ticketId }: Props) => {
  const dispatch = useDispatch()

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())

  const { mutateAsync, isLoading } = useActionPaidReserve({
    ticketId,
  })

  const handlePaidReserve = () => {
    if (!(selectedDate && isValid(selectedDate))) {
      toast.error('Введіть коректну дату оплати.')
      return
    }

    mutateAsync({
      paidAt: selectedDate,
    }).then((data) => {
      dispatch(sheetsActions.changeTicket(data))
      onClose()
    })
  }

  return (
    <>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <DateTimePicker
              value={selectedDate}
              onChange={setSelectedDate}
              label="Оберіть дату та час оплати"
              format={UKR_DATE_TIME_FORMAT}
              maxDate={new Date()}
              sx={{ mt: 1 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Відмінити</Button>
        <LoadingButton loading={isLoading} onClick={handlePaidReserve} variant="contained">
          Застосувати
        </LoadingButton>
      </DialogActions>
    </>
  )
}
