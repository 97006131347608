import { SyntheticEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete, Stack, SvgIcon, TextField, TextFieldProps, Typography } from '@mui/material'

import { isCategoryPremium, PremiumIcon } from '@klr/schemes'

import { AvailablePlace } from '../../validations'

type Props = {
  name: string
  options: AvailablePlace[]
  label?: string
  defaultValue?: AvailablePlace | null
  onChange?: (val: AvailablePlace) => void
  textFieldProps?: Omit<TextFieldProps, 'name' | 'value' | 'onChange' | 'label' | 'defaultValue'>
}

export const RHFPlaceAutocomplete = ({
  name,
  options,
  label = 'Місце',
  defaultValue,
  onChange,
  textFieldProps,
}: Props) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <Autocomplete<AvailablePlace, false, true>
          {...field}
          autoHighlight
          openOnFocus
          disableClearable
          noOptionsText="Не знайдено вільного місця"
          options={options}
          getOptionLabel={(option) => option.place.toString()}
          isOptionEqualToValue={(option, value) => option.place === value.place}
          getOptionDisabled={(option) => option.disabled}
          onChange={(_: SyntheticEvent, data) => {
            field.onChange(data)

            if (onChange && data) {
              onChange(data)
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              size="small"
              error={!!error}
              helperText={error?.message}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: isCategoryPremium(field.value?.category ?? null) && (
                  <>
                    <SvgIcon color="warning" fontSize="small">
                      <PremiumIcon category={field.value.category} />
                    </SvgIcon>
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              {...textFieldProps}
            />
          )}
          renderOption={(props, option) => (
            <Stack component="li" direction="row" spacing={1} key={option.place} {...props}>
              <Typography>{option.place.toString()}</Typography>
              {isCategoryPremium(option.category) && (
                <SvgIcon color="warning" fontSize="small">
                  <PremiumIcon category={option.category} />
                </SvgIcon>
              )}
            </Stack>
          )}
          // TODO: move to global styles
          sx={{
            '& .MuiAutocomplete-inputRoot': {
              paddingRight: '30px !important',
            },
          }}
        />
      )}
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={{ required: true }}
    />
  )
}
