import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { StatusPlaceEnum } from '@klr/api-connectors'

import { tripsActionsAsync } from '../../trips'

type Props = {
  transportTripId: number
  transportId: number
}

export const useMutateToggleBlockPlace = ({ transportTripId, transportId }: Props) => {
  const dispatch = useDispatch()

  const handleToggleBlockPlace = useCallback(
    (place: number, status: StatusPlaceEnum.BLOCKED | StatusPlaceEnum.AVAILABLE): void => {
      dispatch(
        tripsActionsAsync.changeStatusPlacesAsync({
          isSheet: true,
          request: {
            // ID частинки
            trip_id: transportTripId,
            transport_id: transportId,
            data: {
              places: [{ place, status }],
            },
          },
        })
      )
    },
    [dispatch, transportId, transportTripId]
  )

  return { handleToggleBlockPlace }
}
