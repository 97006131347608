import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@mui/material'

import { CustomerSocialEnum } from '@klr/api-connectors'
import { copyToClipboard, CustomSvgIcon } from '@klr/shared'

import { useDeleteSocialNetwork } from '../../../../../../useDeleteSocialNetwork'
import { useMutateCustomerSocials } from '../../../../../../useMutateCustomerSocials'

import { CustomerShortSocialContextMenu } from './CustomerShortSocialContextMenu'
import { CustomerShortSocialModalForm } from './CustomerShortSocialModalForm'

interface CustomerSocialItemProps {
  socialIcon: CustomSvgIcon
  socialId: number | null
  socialLink: string | null
  socialType: CustomerSocialEnum
}

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const CustomerShortSocialItem = ({
  socialIcon: Icon,
  socialId,
  socialLink,
  socialType,
}: CustomerSocialItemProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [openModalForm, setOpenModalForm] = useState(false)

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setOpenModalForm(false)

  const handleCopy = () => {
    if (socialLink) {
      copyToClipboard(socialLink, t('Messages.link_copied'))
    }
  }

  const handleSave = useMutateCustomerSocials({
    socialId,
    socialType,
    socialLink,
    successCallback: handleClose,
  })

  const handleDelete = useDeleteSocialNetwork()

  return (
    <>
      <Tooltip title={socialLink}>
        <IconButton color={socialLink ? 'secondary' : 'inherit'} onContextMenu={handleOpen}>
          <Icon />
        </IconButton>
      </Tooltip>
      <CustomerShortSocialContextMenu
        anchorEl={anchorEl}
        hasLink={!!socialLink && !!socialId}
        onClose={() => setAnchorEl(null)}
        onCopy={handleCopy}
        onDelete={() => handleDelete(socialId)}
        onEdit={() => setOpenModalForm(true)}
      />
      <CustomerShortSocialModalForm
        initialLink={socialLink}
        label={capitalizeFirstLetter(socialType)}
        open={openModalForm}
        onClose={handleClose}
        onSave={handleSave}
      />
    </>
  )
}
