import type { AxiosResponse } from 'axios'
import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import {
  apiTripsFetchList,
  apiTripsFetchTripStations,
  TripResFetchItems,
  TripResTripStations,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { tripsActions } from '../../index'
import type { FetchItemsAsync, FetchStationsAsync } from '../../types'

export function* fetchStations(action: FetchStationsAsync): SagaIterator {
  try {
    yield put(tripsActions.toggleLoadingTabContent(true))

    // Api call or some async actions
    const response: AxiosResponse<TripResTripStations> = yield call(
      apiTripsFetchTripStations,
      action.payload
    )

    if (response.data) {
      yield put(tripsActions.saveStations(response.data.items))
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(tripsActions.toggleLoadingTabContent(false))
  }
}

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    yield put(tripsActions.toggleLoadingTabContent(true))

    // Api call or some async actions
    const { data }: AxiosResponse<TripResFetchItems> = yield call(apiTripsFetchList, action.payload)

    if (data) {
      yield put(tripsActions.saveItems(data))
    }
  } catch (error) {
    handleBackendError(error)
  } finally {
    yield put(tripsActions.toggleLoadingTabContent(false))
  }
}
