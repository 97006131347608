import { AxiosPromise } from 'axios'

import { axios } from '../_axios'
import { LoyaltyLevelDTO } from '../_shared'
import { DetailType, ListDataType, ListType } from '../_types'
import { BookingItem } from '../booking'

import {
  TripItem,
  TripReqChange,
  TripReqChangeAdditionalOptions,
  TripReqChangeEnabledPremiumSeats,
  TripReqChangeLoyaltyLevels,
  TripReqChangeRouteTariffs,
  TripReqChangeStation,
  TripReqCreate,
  TripReqDeleteTariffs,
  TripReqFetchItems,
  TripReqFetchRouteTariffs,
  TripResFetchDetail,
  TripResFetchItems,
  TripResTripStations,
  TripTariff,
  TripTransportReqBookingInfo,
  TripTransportReqChangeInfo,
  TripTransportReqChangeStatusPlaces,
  TripTransportReqCreate,
  TripTransportReqDetail,
  TripTransportReqItems,
  TripTransportReqReplace,
  TripTransportResFetchDetail,
} from './trip.models'

export const apiTripsFetchTariffs = ({
  tripId,
  currencyId,
}: TripReqFetchRouteTariffs): AxiosPromise<ListType<TripTariff>> => {
  return axios({
    url: `trips/${tripId}/tariffs`,
    method: 'get',
    params: {
      currency_id: currencyId,
    },
  })
}

export const apiTripsChangeTariffs = ({
  tripId,
  data,
}: TripReqChangeRouteTariffs): AxiosPromise<ListType<TripTariff>> => {
  return axios({
    url: `trips/${tripId}/tariffs`,
    method: 'put',
    data,
  })
}

export const apiTripsFetchList = (params: TripReqFetchItems): AxiosPromise<TripResFetchItems> => {
  return axios({
    url: `/trips`,
    method: 'get',
    params,
  })
}

export const apiTripsFetchDetail = (tripId: number): AxiosPromise<DetailType<TripItem>> => {
  return axios({
    url: `/trips/${tripId}`,
    method: 'get',
  })
}

export const apiTripsCreateItem = (data: TripReqCreate): AxiosPromise<TripResFetchDetail> => {
  return axios({
    url: `/trips/`,
    method: 'post',
    data,
  })
}

export const apiTripsChange = ({
  tripId,
  data,
}: TripReqChange): AxiosPromise<DetailType<TripItem>> => {
  return axios({
    url: `/trips/${tripId}`,
    method: 'put',
    data,
  })
}

export const apiTripsDeleteTrip = (tripId: TripItem['id']): AxiosPromise => {
  return axios({
    url: `/trips/${tripId}`,
    method: 'delete',
  })
}

export const apiTripsDeleteTariff = ({
  trip_id,
  tariff_id,
}: TripReqDeleteTariffs): AxiosPromise => {
  return axios.delete(`/trips/${trip_id}/tariffs/${tariff_id}`)
}

/**
 * Отримати AdditionalOptions для рейсу
 *
 * @param id - trip_id
 */
export const apiTripsFetchTripStations = (
  id: TripItem['id']
): AxiosPromise<TripResTripStations> => {
  return axios({
    url: `/trips/${id}/stations`,
    method: 'get',
  })
}

export const apiTripsChangeAdditionalOptions = ({
  id,
  data,
}: TripReqChangeAdditionalOptions): AxiosPromise<DetailType<TripItem>> => {
  return axios.put(`/trips/${id}/additional-options`, data)
}

/**
 * Змінити станцію для рейсу
 *
 * @param id - trip_id
 */
export const apiTripsChangeStations = ({
  tripId,
  data,
}: TripReqChangeStation): AxiosPromise<TripResTripStations> => {
  return axios({
    url: `/trips/${tripId}/stations`,
    method: 'put',
    data,
  })
}

/**
 * Отримати транспорт
 */
export const apiTripTransportFetchList = ({ trip_id }: TripTransportReqItems): AxiosPromise => {
  return axios({
    url: `/trips/${trip_id}/transports`,
    method: 'get',
  })
}

/**
 * Оновити статуси місць транспорту в рейсі
 */
export const apiTripTransportChangeStatusPlaces = ({
  trip_id,
  transport_id,
  data,
}: TripTransportReqChangeStatusPlaces): AxiosPromise<TripTransportResFetchDetail> => {
  return axios({
    url: `/trips/${trip_id}/transports/${transport_id}/places`,
    method: 'put',
    data,
  })
}

/**
 * Додати транспорт до рейсу
 */
export const apiTripTransportCreate = ({
  trip_id,
  data,
}: TripTransportReqCreate): AxiosPromise<TripTransportResFetchDetail> => {
  return axios({
    url: `/trips/${trip_id}/transports`,
    method: 'post',
    data,
  })
}

/**
 * Оновити інформацію по транспорту для рейсу
 */
export const apiTripTransportsChangeInfo = ({
  trip_id,
  transport_id,
  data,
}: TripTransportReqChangeInfo): AxiosPromise<TripTransportResFetchDetail> => {
  return axios({
    url: `/trips/${trip_id}/transports/${transport_id}`,
    method: 'put',
    data,
  })
}

/**
 * Видалити додатковий транспорт на рейсі
 */
export const apiTripTransportsDelete = ({
  trip_id,
  transport_id,
}: TripTransportReqDetail): AxiosPromise => {
  return axios({
    url: `/trips/${trip_id}/transports/${transport_id}`,
    method: 'delete',
  })
}

/**
 * Замінити транспорт в поїздці
 */
export const apiTripTransportsReplace = ({
  transport_id,
  trip_id,
  data,
}: TripTransportReqReplace): AxiosPromise<TripTransportResFetchDetail> => {
  return axios({
    url: `/trips/${trip_id}/transports/${transport_id}/replace`,
    method: 'post',
    data,
  })
}

export const apiTripPremiumSeatUpdate = ({
  id,
  data,
}: TripReqChangeEnabledPremiumSeats): AxiosPromise<DetailType<TripItem>> => {
  return axios.put(`/trips/${id}/premium`, data)
}

export const apiTripFetchBookingInfo = ({
  trip_id,
  station_from_id,
  station_to_id,
}: TripTransportReqBookingInfo): AxiosPromise<DetailType<BookingItem | null>> => {
  return axios.get(`/trips/${trip_id}/booking`, {
    params: {
      station_from_id,
      station_to_id,
    },
  })
}

// Loyalty levels
export const apiTripFetchLoyaltyLevels = (
  trip_id: TripItem['id']
): AxiosPromise<ListDataType<LoyaltyLevelDTO>> => {
  return axios.get(`/trips/${trip_id}/loyalty-levels`)
}

export const apiTripChangeLoyaltyLevels = ({
  trip_id,
  data,
}: TripReqChangeLoyaltyLevels): AxiosPromise<ListDataType<LoyaltyLevelDTO>> => {
  return axios.put(`/trips/${trip_id}/loyalty-levels`, data)
}
