import { TFunction } from 'react-i18next'
import * as Yup from 'yup'

import { validateRequiredStringField } from '@klr/shared'

export const socialNetworksValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    link: validateRequiredStringField(t),
  })

export type SocialNetworksFormFields = Yup.InferType<
  ReturnType<typeof socialNetworksValidationSchema>
>
