import { lazy, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Fallback, TransitionGrow } from '@klr/shared'

import { uiActions, uiSelectors } from '../../_modules_/ui'
import { DetailModalEnum } from '../../constants/detail-view.enum'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .MuiDialog-paper`]: {
    maxWidth: '100%',
    width: 650,
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.08)',
    borderRadius: theme.spacing(1),
    height: 'calc(100% - 20px)',
    overflowY: 'auto',
    margin: '10px 10px 10px 0',
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      margin: 0,
      borderRadius: 0,
    },
  },
}))

const components = {
  // Sheets
  [DetailModalEnum.DETAIL_SHEET_TICKET]: lazy(
    () => import('../../_modules_/sheets/components/ModalDetailTicket')
  ),
  [DetailModalEnum.MANAGE_SHEET_BUS]: lazy(
    () => import('../../_modules_/sheets/components/ModalControlBus')
  ),
  [DetailModalEnum.MANAGE_SHEET_TRIP]: lazy(
    () => import('../../_modules_/sheets/components/ModalDetailTrip')
  ),
  [DetailModalEnum.DETAIL_ORDER_REPORT]: lazy(
    () => import('../../_modules_/orders/components/DrawerDetailOrder')
  ),
  [DetailModalEnum.SHEET_TRANSFER_TO_ROUTE]: lazy(
    () => import('../../_modules_/sheets/components/ModalTransferRoute')
  ),
  // Settings
  [DetailModalEnum.MANAGE_TEMPLATES]: lazy(
    () => import('../../_modules_/settings/components/Templates/ModalTemplateForm')
  ),
}

export const ModalDetail = () => {
  const dispatch = useDispatch()

  const detailModal = useSelector(uiSelectors.getDetailModal)
  const isShowModal = useSelector(uiSelectors.isShowModal)
  const Component = components[detailModal.meta as keyof typeof components] || 'div'

  const _close = () => {
    if (detailModal.cancelActions) {
      detailModal.cancelActions.forEach((action) => dispatch(action))
    }

    dispatch(uiActions.closeDetailModal())
  }

  return (
    <StyledDialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          _close()
        }
      }}
      TransitionComponent={TransitionGrow}
      open={isShowModal}
    >
      <Suspense fallback={<Fallback />}>{isShowModal && <Component />}</Suspense>
    </StyledDialog>
  )
}
