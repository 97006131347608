import { useQueries, useQueryClient } from '@tanstack/react-query'

import {
  apiCustomerFetchDetail,
  apiCustomerFetchOrders,
  apiCustomerSocialsFetchList,
  CustomerDetailDTO,
  customerQueryKeys,
  CustomerSocialNetwork,
  OrderDetail,
} from '@klr/api-connectors'

import { setMainInfo, setOrders, setSocials, useCustomerUIStore } from '../../../../store'

export const useInitializeCustomerData = () => {
  const queryClient = useQueryClient()
  const customerId = useCustomerUIStore((state) => state.customerId)
  //TODO: use useSuspenseQuery hook after the version of React Query lib is updated
  useQueries({
    queries: [
      {
        queryKey: customerQueryKeys.detail(customerId),
        queryFn: async () => {
          const {
            data: { item },
          } = await apiCustomerFetchDetail(customerId!)

          setMainInfo(item)

          return item
        },
        enabled: !!customerId,
        initialData: () => {
          return queryClient.getQueryData<CustomerDetailDTO>(customerQueryKeys.detail(customerId))
        },
      },
      {
        queryKey: customerQueryKeys.orders(customerId),
        queryFn: async () => {
          const {
            data: { items },
          } = await apiCustomerFetchOrders(customerId!)

          setOrders(items)

          return items
        },
        enabled: !!customerId,
        initialData: () => {
          return queryClient.getQueryData<OrderDetail[]>(customerQueryKeys.orders(customerId))
        },
      },
      {
        queryKey: customerQueryKeys.socials(customerId),
        queryFn: async () => {
          const {
            data: { items },
          } = await apiCustomerSocialsFetchList(customerId!)

          setSocials(items)

          return items
        },
        enabled: !!customerId,
        initialData: () => {
          return queryClient.getQueryData<CustomerSocialNetwork[]>(
            customerQueryKeys.socials(customerId)
          )
        },
      },
    ],
  })
}
