import { useMemo, useState } from 'react'
import { Box, Grid, Skeleton, styled, Typography } from '@mui/material'

import { TripStation } from '@klr/api-connectors'

import { StationAutocomplete } from './StationAutocomplete'

const StyledTypography = styled(Typography)({
  width: '100%',
})

interface SectionStationsProps {
  index: number
  defaultStationFrom: TripStation | null
  defaultStationTo: TripStation | null
  stationsTrip: TripStation[]
  isLoading: boolean
}

export const SectionStations = ({
  index,
  defaultStationFrom,
  defaultStationTo,
  stationsTrip,
  isLoading,
}: SectionStationsProps) => {
  const [cityFromIdx, setCityFromIdx] = useState<number | null>(null)

  const _handleCityFrom = (_data: TripStation | null) => {
    if (_data) {
      setCityFromIdx(stationsTrip.findIndex((station) => station.id === _data.id))
    } else {
      setCityFromIdx(null)
    }
  }

  const stationsForTo: TripStation[] = useMemo(() => {
    if (cityFromIdx !== null) {
      return stationsTrip.slice(cityFromIdx + 1)
    }

    if (defaultStationFrom) {
      const defaultStationFromIdx = stationsTrip.findIndex(
        (station) => station.id === defaultStationFrom.id
      )

      return stationsTrip.slice(defaultStationFromIdx + 1)
    }
    return stationsTrip
  }, [cityFromIdx, defaultStationFrom, stationsTrip])

  return (
    <>
      <Grid item xs={12} sm={6}>
        {isLoading ? (
          <Skeleton height={56} width="100%" />
        ) : (
          <StationAutocomplete
            name={`tickets[${index}].stationFrom`}
            label="Станція відправлення"
            defaultStation={defaultStationFrom}
            stationsTrip={stationsTrip}
            onChangeProps={_handleCityFrom}
            renderOption={(props, option: TripStation) => (
              <Box key={option.id} {...props} component="li" sx={{ flexDirection: 'column' }}>
                <StyledTypography>
                  {option.city} [{option.departure}]
                </StyledTypography>
                <StyledTypography variant="caption">{option.station}</StyledTypography>
              </Box>
            )}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {isLoading ? (
          <Skeleton height={56} width="100%" />
        ) : (
          <StationAutocomplete
            name={`tickets[${index}].stationTo`}
            label="Станція прибуття"
            defaultStation={defaultStationTo}
            stationsTrip={stationsForTo}
            renderOption={(props, option: TripStation) => (
              <Box key={option.id} {...props} component="li" sx={{ flexDirection: 'column' }}>
                <StyledTypography variant="body1">
                  {option.city} [{option.departure}]
                </StyledTypography>
                <StyledTypography variant="caption">{option.station}</StyledTypography>
              </Box>
            )}
          />
        )}
      </Grid>
    </>
  )
}
