import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  DoNotDisturbAltOutlined as CancelIcon,
  SaveOutlined as SaveIcon,
} from '@mui/icons-material'
import { CircularProgress, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import { FormProvider, RHFPhoneInputMask, RHFTextField } from '@klr/shared'

import { useCustomerStore } from '../../../../../../store'
import { StyledCenteredContainer, StyledCustomerCard } from '../../customer-card.styles'

import { CustomerDetailFormFields, CustomerDetailSchema } from './customer.validations'
import { useCustomerForms } from './useCustomerForms'

interface CustomerFormProps {
  onCloseForm(): void
}

export const CustomerForm = ({ onCloseForm }: CustomerFormProps) => {
  const { t } = useTranslation()
  const customer = useCustomerStore((state) => state.mainInfo)

  const methods = useForm<CustomerDetailFormFields>({
    defaultValues: {
      phone: customer?.phone ?? '',
      add_phone: customer?.add_phone ?? '',
      description: customer?.description ?? '',
    },
    resolver: yupResolver(CustomerDetailSchema(t)),
  })

  const { isLoading, onSubmit } = useCustomerForms({
    customerId: customer?.id ?? null,
    successCallback: onCloseForm,
  })

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <StyledCustomerCard>
        {isLoading ? (
          <StyledCenteredContainer>
            <CircularProgress />
          </StyledCenteredContainer>
        ) : (
          <>
            <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
              <Typography variant="h6">
                {customer ? t('Customers.client_editing') : t('Customers.enter_client_data')}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Tooltip title={t('Common.cancel')}>
                  <IconButton aria-label="cancel" onClick={onCloseForm}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Common.save')}>
                  <IconButton aria-label="save" type="submit">
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>

            <Stack spacing={2} sx={{ p: 2 }}>
              {!customer && <RHFPhoneInputMask name="phone" label={t('Customers.phone')} />}
              <RHFPhoneInputMask name="add_phone" label={t('Customers.additional_phone')} />
              <RHFTextField
                name="description"
                label={t('Customers.description')}
                multiline
                rows={2}
              />
            </Stack>
          </>
        )}
      </StyledCustomerCard>
    </FormProvider>
  )
}
