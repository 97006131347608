import { PayloadAction } from '@reduxjs/toolkit'

import { SheetPlace, SheetTransport, StatusPlaceEnum, TicketDetail } from '@klr/api-connectors'

import { generateSheetPlace } from '../helpers'
import { SheetsState } from '../sheetState'

export const resolveLostTickets = (state: SheetsState, action: PayloadAction<TicketDetail[]>) => {
  // Пробігаємось по всіх квитках
  action.payload.forEach((ticket: TicketDetail) => {
    let idx = 0
    // Визначаємо index транспорту для кожного квитка (потрібно переробити коли буде нормалізація даних)
    if (state.transports.length > 1) {
      idx = state.transports.findIndex(
        (transport: SheetTransport) =>
          transport.id === ticket.transport_id && ticket.trip?.id === transport.trip.id
      )
    }

    // страховка якщо транспорт такий є
    if (idx !== -1) {
      // Генеруємо нове місце
      const newPlace = generateSheetPlace(ticket)

      // Перевіряємо чи місце доступне
      const candidateIdx = state.transports[idx].places.findIndex(
        (place) => place.status === StatusPlaceEnum.AVAILABLE && place.place === ticket.place
      )

      if (candidateIdx !== -1) {
        state.transports[idx].places[candidateIdx] = newPlace
      } else {
        // Якщо місце не доступне потрібно записати в конфлікти
        // Перевіряємо чи conflicted не рівняється null
        if (!state.transports[idx].conflicted) {
          state.transports[idx].conflicted = []
        }

        state.transports[idx].conflicted?.push(newPlace)
      }

      state.lostTickets =
        state.lostTickets?.filter((lostPlace: SheetPlace) => lostPlace.ticket_id !== ticket.id) ||
        null
    }
  })

  if (!state.lostTickets?.length) {
    state.lostTickets = null
  }
}
