import { RootState } from '../../core/store/rootReducer'

// Selector
export const getDetail = (state: RootState) => state.entities.tickets.detail
export const isLoading = (state: RootState) => state.entities.tickets.loading

export const getReturnCondition = (state: RootState) => state.entities.tickets.returnCondition
export const isLoadingCondition = (state: RootState) => state.entities.tickets.loadingCondition

export const getAvailablePlaces = (state: RootState) => state.entities.tickets.availablePlaces

// Memoization
