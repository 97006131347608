import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'

import {
  apiNotificationsSendPassengerInfoToDriver,
  notificationQueryKeys,
  NPassengerInfoMessage,
  SheetPlace,
  StatusTicketEnum,
} from '@klr/api-connectors'
import { handleBackendError } from '@klr/shared'

import { generateMessageSendTicket } from '../helpers/generateMessage'
import { sheetsActions } from '../slice'

type Props = {
  transportTripId: number
  transportId: number
}

export const useMutateDispatchToDriver = ({ transportTripId, transportId }: Props) => {
  const dispatch = useDispatch()

  const { mutate } = useMutation(
    [...notificationQueryKeys.all, 'sendMessageInfoToDriver'],
    apiNotificationsSendPassengerInfoToDriver,
    {
      onSuccess: ({ data }, variables) => {
        const { informed_ticket_ids, not_informed_ticket_ids } = data
        const isSet = variables.type_of_information !== 'unset'

        const list =
          isSet && informed_ticket_ids.length > 0
            ? informed_ticket_ids
            : variables.messages.map((item: NPassengerInfoMessage) => ({
                id: item.ticket_id,
                driver_informed_at: null,
              }))

        dispatch(sheetsActions.informingDriver(list))

        toast.success(isSet ? `Пасажири передані ✉️` : 'Відміна інформування водія')

        if (not_informed_ticket_ids.length > 0) {
          toast.error('Деякі квитки не передані')
        }
      },
      onError: (error) => handleBackendError(error),
    }
  )

  const handleDispatchToDriver = useCallback(
    (value: SheetPlace | SheetPlace[], isSet: boolean): void => {
      let messages: NPassengerInfoMessage[] = []

      if (Array.isArray(value)) {
        messages = value
          .filter((p) => p.status !== StatusTicketEnum.DEFERRED)
          .map((p) => ({
            ticket_id: p.ticket_id,
            message: isSet ? generateMessageSendTicket(p) + '\n____________________\n' : '',
          }))
      } else {
        if (value.status !== StatusTicketEnum.DEFERRED) {
          messages = [
            {
              ticket_id: (value as SheetPlace).ticket_id,
              message: generateMessageSendTicket(value as SheetPlace),
            },
          ]
        }
      }

      if (messages.length) {
        mutate({
          trip_id: transportTripId,
          transport_id: transportId,
          type_of_information: isSet ? 'set' : 'unset',
          messages,
        })
      }
    },
    [mutate, transportId, transportTripId]
  )

  return { handleDispatchToDriver }
}
