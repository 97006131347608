import { getQueryPagination } from '../_byQuery'
import { ConnectedQueryFunctionContext } from '../_shared'
import { ListType, ReqFetchList } from '../_types'

import { apiDispatcherFetchList, apiDispatchersGroupsFetchList } from './dispatchers.api'
import { DispatcherItem, DispatcherReqFetchList, DispatchersGroupDTO } from './dispatchers.models'

export const dispatcherQueryKeys = {
  all: ['dispatchers'] as const,
  list: (params: Omit<DispatcherReqFetchList, 'page' | 'limit'> = {}) =>
    [...dispatcherQueryKeys.all, params] as const,
  detail: (id: DispatcherItem['id']) => [...dispatcherQueryKeys.all, id] as const,
  relatedRoutes: (id: DispatcherItem['id']) =>
    [...dispatcherQueryKeys.detail(id), 'related-routes'] as const,
  orderStatuses: (id: DispatcherItem['id']) =>
    [...dispatcherQueryKeys.detail(id), 'order-statuses'] as const,
  groups: ['dispatchers-groups'] as const,
  groupDetail: (id: DispatchersGroupDTO['id']) => [...dispatcherQueryKeys.groups, id] as const,
  groupsList: (params: Omit<ReqFetchList, 'page' | 'limit'> = {}) =>
    [...dispatcherQueryKeys.groups, params] as const,
}

export type DispatcherQueryKeys = {
  all: readonly ['dispatchers']
  list: readonly [
    ...DispatcherQueryKeys['all'],
    Partial<Omit<DispatcherReqFetchList, 'page' | 'limit'>>,
  ]
  detail: readonly [...DispatcherQueryKeys['all'], DispatcherItem['id']]
  relatedRoutes: readonly [...DispatcherQueryKeys['detail'], 'related-routes']
  orderStatuses: readonly [...DispatcherQueryKeys['detail'], 'order-statuses']
  groups: readonly ['dispatchers-groups']
  groupDetail: readonly [...DispatcherQueryKeys['groups'], DispatchersGroupDTO['id']]
  groupsList: readonly [
    ...DispatcherQueryKeys['groups'],
    Partial<Omit<ReqFetchList, 'page' | 'limit'>>,
  ]
}

export const dispatcherQueryFns = {
  getDispatcherList: async (
    context: ConnectedQueryFunctionContext<DispatcherQueryKeys['list']>
  ): Promise<ListType<DispatcherItem>> => {
    const {
      pageParam = 1,
      queryKey: [, filters, { search }],
    } = context

    const payload: DispatcherReqFetchList = {
      ...filters,
      ...getQueryPagination({ pageParam }),
    }

    if (search) payload.query = search.trim()

    const { data } = await apiDispatcherFetchList(payload)
    return data
  },
  getDispatchersGroupsList: async (
    context: ConnectedQueryFunctionContext<DispatcherQueryKeys['groupsList']>
  ): Promise<ListType<DispatchersGroupDTO>> => {
    const {
      pageParam = 1,
      queryKey: [, filters],
    } = context

    const payload: DispatcherReqFetchList = {
      ...filters,
      ...getQueryPagination({ pageParam }),
    }

    const { data } = await apiDispatchersGroupsFetchList(payload)
    return data
  },
}
