import { PayloadAction } from '@reduxjs/toolkit'

import { NInformedTicketIds, SheetPlace, SheetPlaceEmpty } from '@klr/api-connectors'

import { isPlaceNotEmpty } from '../helpers'
import { SheetsState } from '../sheetState'

type Options = 'places' | 'sub_sheet'
const options: Options[] = ['places', 'sub_sheet']

export const informingDriver = (
  state: SheetsState,
  action: PayloadAction<NInformedTicketIds[]>
) => {
  if (state.transports?.length) {
    const idx = state.transports.findIndex(
      (t) => t.id === state.selectTransport?.id && t.trip.id === state.selectTransport.tripId
    )

    options.forEach((option) => {
      action.payload.forEach((item) => {
        if (state.transports[idx][option]) {
          state.transports[idx][option] = state.transports[idx][option]?.map(
            (p: SheetPlace | SheetPlaceEmpty) => {
              if (isPlaceNotEmpty(p) && p.ticket_id === item.id) {
                return {
                  ...p,
                  driver_informed_at: item.driver_informed_at,
                }
              }

              return p
            }
          ) as (SheetPlace | SheetPlaceEmpty)[] & SheetPlace[]
        }
      })
    })
  }
}
