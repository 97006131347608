import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@mui/material'

export const AlertErrorFetch = () => {
  const { t } = useTranslation()

  return (
    <Alert severity="error">
      <AlertTitle>
        {t('404.loading_error')}{' '}
        <span role="img" aria-label="Oh">
          🤦
        </span>
      </AlertTitle>
      {t('404.try_reloading_the_page')}
    </Alert>
  )
}

AlertErrorFetch.displayName = 'AlertErrorFetch'
