import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OpenWithOutlined as ExpandIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'

import { Drawer } from '@klr/shared'

import { toggleCustomerCardDrawer, useCustomerUIStore } from '../../store'

import { CustomerCardContent } from './components/CustomerCardContent'

export const CustomerDrawer = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  const showDrawer = useCustomerUIStore((state) => state.showDrawer)

  return (
    <Drawer
      anchor="left"
      width={expanded ? 1600 : 500}
      transitionDuration={300}
      open={showDrawer}
      onClose={() => toggleCustomerCardDrawer(false)}
    >
      <Box sx={{ overflowY: 'hidden' }}>
        <Grid sx={{ mb: 2 }} container justifyContent="space-between" alignItems="center">
          <Grid item flexGrow={1}>
            <Typography variant="h4">{t('Customers.customer_card')}</Typography>
          </Grid>
          <Grid item>
            <Tooltip title={expanded ? t('Common.collapse') : t('Common.expand')}>
              <IconButton
                aria-label="expand"
                onClick={() => setExpanded((prev) => !prev)}
                size="large"
              >
                <ExpandIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <CustomerCardContent expanded={expanded} />
      </Box>
    </Drawer>
  )
}
