import { PayloadAction } from '@reduxjs/toolkit'

import { SheetTransport, TripDetail, TripTransportDetail } from '@klr/api-connectors'

import { SheetsState } from '../sheetState'

export const tripReducers = {
  changeTrips: (state: SheetsState, action: PayloadAction<TripDetail>) => {
    if (action.payload) {
      const idx = (state.transports as SheetTransport[]).findIndex((transport) => {
        return (
          transport.trip.id === action.payload?.id && transport.id === state.selectTransport?.id
        )
      })

      state.transports[idx].trip = {
        ...state.transports[idx].trip,
        note: action.payload.note,
        information: action.payload.information,
        is_record_issued: action.payload.is_record_issued,
      }
    }
  },
  // Transports
  changeTransportInfo: (state: SheetsState, action: PayloadAction<TripTransportDetail>) => {
    const idx = state.transports.findIndex(
      (t) => t.id === action.payload.id && t.trip.id === action.payload.trip_id
    )

    state.transports[idx] = {
      ...state.transports[idx],
      drivers_info: action.payload.drivers,
      telegram_chat_id: action.payload.telegram_id,
      transport_confirmed: action.payload.transport_confirmed,
    }
  },
}
