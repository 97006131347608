import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { QueryKey } from '@tanstack/query-core'

import { SearchTextField } from '../../MuiDataEntry/SearchTextField'
import { ConnectedDataGridProps } from '../ConnectedDataGrid.types'

interface HeaderConnectedDataGridProps<
  Option extends GridValidRowModel,
  TQueryKey extends QueryKey = QueryKey,
> {
  actionButtons: ConnectedDataGridProps<Option, TQueryKey>['actionButtons']
  hasHeader: boolean
  hasSearch: boolean

  onChange(val: string | undefined): void

  querySearch: string
  searchTextFieldProps: ConnectedDataGridProps<Option, TQueryKey>['searchTextFieldProps']
  title: string | undefined
}

export const HeaderConnectedDataGrid = <
  Option extends GridValidRowModel,
  TQueryKey extends QueryKey = QueryKey,
>(
  props: HeaderConnectedDataGridProps<Option, TQueryKey>
) => {
  const {
    hasHeader,
    title,
    hasSearch,
    actionButtons,
    searchTextFieldProps,
    querySearch,
    onChange,
  } = props

  if (!hasHeader) return null

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>{!!title && <Typography variant="h3">{title}</Typography>}</Grid>

      {(hasSearch || actionButtons) && (
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            {hasSearch && (
              <SearchTextField
                label="Пошук"
                {...searchTextFieldProps}
                value={querySearch ?? ''}
                onChange={(event) => onChange(event.target.value ?? undefined)}
              />
            )}

            {actionButtons}
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}
