import { createSelector } from 'reselect'

import {
  PromotionItem,
  SheetPlace,
  SheetPlaceEmpty,
  SheetTransport,
  StatusPlaceEnum,
  TripSegmentPlace,
} from '@klr/api-connectors'

import { RootState } from '../../core/store/rootReducer'

// Selector
export const getTransports = createSelector(
  (state: RootState) => state.entities.sheets.transports,
  (transports: SheetTransport[]) => transports
)

export const selectTransport = (state: RootState) => state.entities.sheets.selectTransport

export const getMainInfo = (state: RootState) => state.entities.sheets.mainTrip

export const getLostTicket = (state: RootState) => state.entities.sheets.lostTickets

export const getPromotion = createSelector(
  (state: RootState) => state.entities.sheets.promotions,
  (promotions: PromotionItem[]) => (promotions?.length ? promotions : [])
)

export const getAvailablePlaces = createSelector(getTransports, (transports: SheetTransport[]) => {
  const map = new Map()

  transports.forEach((transport: SheetTransport) => {
    const places: number[] = []

    transport.places.forEach((item: SheetPlace | SheetPlaceEmpty) => {
      if (item.status === StatusPlaceEnum.AVAILABLE) {
        places.push(item.place)
      }
    })

    // можливо що транспорт буде однаковий на двох тріпах
    map.set(`${transport.id}_${transport.trip.id}`, places)
  })

  return map
})

export const segmentPlaces = (state: RootState) => state.entities.sheets.availableSegmentPlaces

export const getSegmentPlaces = createSelector(segmentPlaces, (placeItems: TripSegmentPlace[]) => {
  const map = new Map()

  placeItems.forEach((item) => {
    const { trip_id, from_station_id, to_station_id, places } = item
    const placeNumbers = places.map((item) => item.place)

    map.set(`${trip_id}_${from_station_id}_${to_station_id}`, placeNumbers)
  })

  return map
})

export const getTransferPlaces = (state: RootState) => state.entities.sheets.transferPlaces

export const getTransferTransportPlace = (state: RootState) =>
  state.entities.sheets.transferTransportPlace

export const getTransferTransportPlaces = (state: RootState) =>
  state.entities.sheets.transferTransportPlaces

export const getTransferTransport = (state: RootState) => state.entities.sheets.transferTransport

export const getPlaceForMessages = createSelector(
  (state: RootState) => state.entities.sheets.placeForMessages,
  (places: SheetPlace[]) => places.filter((p) => !!p.phone)
)

export const isAllowedSync = (state: RootState) => state.entities.sheets.isAllowedSync

export const isShowCreateDrawer = (state: RootState) => state.entities.sheets.isShowCreateDrawer

export const drawerCreatePayload = (state: RootState) => state.entities.sheets.drawerCreatePayload
