import { Dialog, DialogTitle } from '@mui/material'

import { DialogPaidReserveInternal } from './components/DialogPaidReserveInternal'

type Props = {
  onClose: VoidFunction
  ticketId: number | null
}

export const DialogPaidReserve = ({ onClose, ticketId }: Props) => (
  <Dialog open={!!ticketId} onClose={onClose} fullWidth>
    <DialogTitle>Введіть дані про оплату.</DialogTitle>

    {ticketId ? <DialogPaidReserveInternal onClose={onClose} ticketId={ticketId} /> : null}
  </Dialog>
)
