import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosPromise } from 'axios'

import { DetailType, KlrRecord } from '@klr/api-connectors'

import { AppModules } from '../constants'
import { handleBackendError } from '../utils'

type UpdateFnParams<Values> = {
  id: KlrRecord['id']
  data: Values
}

interface UseMutateUpdateParams<Values, Option extends KlrRecord> {
  id: KlrRecord['id']
  queryKey: QueryKey
  updateFn: (values: UpdateFnParams<Values>) => AxiosPromise<DetailType<Option>>
  toastSuccessMessages?: string
  backendErrorModule?: AppModules
}

export function useMutateUpdate<Values, Option extends KlrRecord>({
  id,
  queryKey,
  updateFn,
  toastSuccessMessages,
  backendErrorModule,
}: UseMutateUpdateParams<Values, Option>) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const loadingToastId = useRef<string>()

  return useMutation(
    (variables: Values) => {
      return updateFn({
        id,
        data: variables,
      })
    },
    {
      mutationKey: [...queryKey, 'update', id],
      onMutate: () => {
        loadingToastId.current = toast.loading(t('Common.loading'))
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey).then(() => {
          toast.success(toastSuccessMessages ?? t('Messages.record_successfully_updated'))
        })
      },
      onError: (error) => {
        handleBackendError(error, backendErrorModule)
      },
      onSettled: () => {
        if (loadingToastId.current) {
          toast.dismiss(loadingToastId.current)
          loadingToastId.current = undefined
        }
      },
    }
  )
}
