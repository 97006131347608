import { PayloadAction } from '@reduxjs/toolkit'

import { NSuccessMessage, SheetPlace, SheetPlaceEmpty } from '@klr/api-connectors'

import { isPlaceNotEmpty } from '../helpers'
import { SheetsState } from '../sheetState'

type Options = 'places' | 'conflicted' | 'sub_sheet'

export const addMessageInfo = (
  state: SheetsState,
  action: PayloadAction<{
    notifications: NSuccessMessage[]
  }>
) => {
  if (state.transports?.length) {
    const idx = state.transports.findIndex(
      (t) => t.id === state.selectTransport?.id && t.trip.id === state.selectTransport.tripId
    )

    const options: Options[] = ['places', 'conflicted', 'sub_sheet']

    options.forEach((option) => {
      action.payload.notifications.forEach((item) => {
        item.ticket_ids.forEach((ticketId) => {
          if (state.transports[idx][option as Options]) {
            state.transports[idx][option as Options] = state.transports[idx][
              option as Options
            ]?.map((p: SheetPlace | SheetPlaceEmpty) => {
              if (isPlaceNotEmpty(p) && p.ticket_id === ticketId) {
                return {
                  ...p,
                  last_notified_at: item.notified_at,
                }
              }

              return p
            }) as (SheetPlace | SheetPlaceEmpty)[] & SheetPlace[]
          }
        })
      })
    })
  }
}
