export function handleOpenFeaturebaseWidget() {
  window.postMessage({
    target: 'FeaturebaseWidget',
    data: {
      action: 'openFeedbackWidget',
    },
  })
}

export function handleOpenFeaturebaseChangelog() {
  // eslint-disable-next-line
  const win = window as any

  win.Featurebase('manually_open_changelog_popup')
}
