import { GetNextPageParamFunction } from '@tanstack/query-core'

import { ListDataPaginationType, ListType } from '../../_types'
import { DEFAULT_LIMIT } from '../constants'

export const getNextPageQueryParam: GetNextPageParamFunction<ListType<unknown>> = (
  lastPage,
  pages
) => {
  const perPageLimit = pages[0]?.items.length
  const totalAmountOfRecordsInDataBase = lastPage?.count
  const amountOfFetchedPages = pages.length

  // There are pages to fetch
  if (amountOfFetchedPages * perPageLimit < totalAmountOfRecordsInDataBase) {
    return amountOfFetchedPages + 1
  }

  return false
}

export const getNextPagePaginatedQueryParam: GetNextPageParamFunction<
  ListDataPaginationType<unknown>
> = (lastPage, pages) => {
  const perPageLimit = pages[0]?.data.length ?? DEFAULT_LIMIT
  const totalAmountOfRecordsInDataBase = lastPage.meta.total
  const amountOfFetchedPages = pages.length

  // There are pages to fetch
  if (amountOfFetchedPages * perPageLimit < totalAmountOfRecordsInDataBase) {
    return amountOfFetchedPages + 1
  }

  return false
}
