import { ClipboardEvent, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Clear as ClearIcon, Search as SearchIcon, Send as SendIcon } from '@mui/icons-material'
import { Box, IconButton, Stack } from '@mui/material'
import * as Yup from 'yup'

import { PHONE_REG_EXP_WITHOUT_PLUS } from '../../constants'
import { getTrimmedPhoneNumber } from '../../utils'
import { FormProvider, RHFTextField } from '../react-hook-form'

interface SearchFieldProps {
  defaultPhone: string | null

  setSearchPhone(val: string): void
}

const phoneSchema = (t: TFunction) =>
  Yup.object().shape({
    phone: Yup.string()
      .trim()
      .matches(PHONE_REG_EXP_WITHOUT_PLUS, t('Validations.invalid_phone'))
      .required(),
  })

type FormField = Yup.InferType<ReturnType<typeof phoneSchema>>

export const SearchPhoneField = ({ defaultPhone, setSearchPhone }: SearchFieldProps) => {
  const { t } = useTranslation()

  const methods = useForm<FormField>({
    defaultValues: {
      phone: defaultPhone?.replace(/^004/g, '4') || '',
    },
    resolver: yupResolver(phoneSchema(t)),
  })

  const { setValue, handleSubmit, watch, clearErrors } = methods

  const phoneValue = watch('phone')

  const _onSubmit = (data: FormField) => {
    setSearchPhone(data.phone)
  }

  const _clearSearch = () => {
    setValue('phone', '')
    clearErrors('phone')
    setSearchPhone('')
  }

  useEffect(() => {
    if (defaultPhone) {
      setValue('phone', defaultPhone || '')
    }
  }, [setValue, defaultPhone])

  const handlePhonePaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => {
      const textToClipboard = e.clipboardData.getData('text/plain')
      const trimmedPhone = getTrimmedPhoneNumber(textToClipboard)
      const firstChars = trimmedPhone.slice(0, 2)

      setValue('phone', firstChars === '00' ? trimmedPhone.slice(2) : trimmedPhone)
      e.preventDefault()
    },
    [setValue]
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(_onSubmit)}>
      <Stack direction="row" spacing={2} alignItems="center">
        <RHFTextField
          name="phone"
          placeholder={`${t('Common.search')}...`}
          onPaste={handlePhonePaste}
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{
                  visibility: phoneValue ? 'visible' : 'hidden',
                }}
                onClick={_clearSearch}
                color="primary"
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
        <Box>
          <IconButton type="submit" title={t('Common.search')} size="large">
            <SendIcon />
          </IconButton>
        </Box>
      </Stack>
    </FormProvider>
  )
}
