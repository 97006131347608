import { AxiosPromise } from 'axios'

import { axios } from '../../_axios'
import { DetailType, ListType } from '../../_types'
import {
  RouteDetail,
  RouteItem,
  RouteOptions,
  RouteReqChangeNotice,
  RouteReqChangeRoute,
  RouteReqChangeRouteOptions,
  RouteReqCreateRoute,
  RouteReqFetchList,
} from '../models'

export const apiRoutesFetchList = ({
  request: params,
}: RouteReqFetchList): AxiosPromise<ListType<RouteItem>> => {
  return axios.get('/routes', { params })
}

export const apiRoutesFetchDetail = (id: number): AxiosPromise<DetailType<RouteDetail>> => {
  return axios.get(`/routes/${id}`)
}

export const apiRoutesChange = ({
  route_id,
  data,
}: RouteReqChangeRoute): AxiosPromise<DetailType<RouteDetail>> => {
  return axios.put(`/routes/${route_id}`, data)
}

export const apiRoutesChangeOptions = ({
  route_id,
  data,
}: RouteReqChangeRouteOptions): AxiosPromise<RouteOptions> => {
  return axios.put(`/routes/${route_id}/options`, data)
}

export const apiRoutesCreate = (
  data: RouteReqCreateRoute
): AxiosPromise<DetailType<RouteDetail>> => {
  return axios.post('/routes', data)
}

export const apiRoutesDeleteRoute = (routeId: number): AxiosPromise => {
  return axios.delete(`/routes/${routeId}`)
}

export const apiRoutesChangeNotice = ({
  route_id,
  route_notice,
}: RouteReqChangeNotice): AxiosPromise => {
  return axios.post(`/routes/${route_id}/route-notice`, { route_notice })
}
