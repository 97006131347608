import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { SheetPlacesVoidFunction } from '@klr/api-connectors'

import { sheetsActions } from '../slice'

export const useMutateDispatchToMessage = () => {
  const dispatch = useDispatch()

  const handleDispatchToMessage: SheetPlacesVoidFunction = useCallback(
    (places) => {
      dispatch(sheetsActions.savePlaceForMessages(places))
    },
    [dispatch]
  )

  return {
    handleDispatchToMessage,
  }
}
